import { LegacyRef } from 'react'
import { getAllFavoritePurchasesApi } from './../api/purchases'
import { call, takeEvery, put } from 'redux-saga/effects'
import {
	appendPurchaseInfo,
	refreshPurchasesState,
	setFilterCountResult,
	setPurchaseItems,
	setPurchases,
	setSelectedPurchase,
	setPurchasesPaginationCounter,
	refreshList,
	SetPurchasesFavorite,
} from '@root-gipro/modules/purchases/store/actions'
import { IPurchasesList } from '@root-gipro/modules/purchases/models/interfaces/purchases-list-request'
import {
	getPurchaseDetailApi,
	// getPurchasesList,
	getPurchasesListByParams,
} from '@root-gipro/modules/purchases/api/purchases'
import store from '@root-gipro/store'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { PurchasesType } from '../models/interfaces/purchases.action'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import { IPurchaseFilterState } from '../models/interfaces/purchase-filter-state.model'
import { PurchasesTypeEnum } from '../models/enums/purchases-type.enum'
import { actionFavoritesProjectsApi } from '@root-gipro/modules/favorites/api/favorites'
import { showFilterResult } from '@root-gipro/modules/filter/store/actions'
import { setLoadingFavorites } from '@root-gipro/modules/favorites/store/actions'
import { ca } from 'date-fns/locale'
import { boolean } from 'yup'

// function* getPurchaseList() {
// 	yield put(refreshPurchasesState('isLoadingInitList', true))
// 	const state = store.getState()
// 	const res: IPurchasesList = yield call(getPurchasesList)

// 	yield put(
// 		setPurchases({
// 			list: res.purchases,
// 			count: Number(res.resultsCnt),
// 			purchasesType: state.purchases.purchasesType,
// 			uncInfo: res.uncInfo,
// 			state: {
// 				allowMoreItems: (res?.purchases?.length || 0) < Number(res?.resultsCnt || 0),
// 				isLoadingInitList: false,
// 			},
// 			iSum: Number(res.initialSumSum) / 10 ** 6,
// 			mSum: Number(res.maximumPriceSum) / 10 ** 6,
// 			sortParams: {
// 				limit: 5,
// 				with: ['resultsCnt', 'initialSumSum', 'maximumPriceSum', 'purchases', 'uncInfo', 'projects'],
// 			},
// 		})
// 	)
// }

function* sortPurchaseList({
	name,
	sortName,
	order,
}: {
	type: PurchasesType.SORT_PURCHASES
	name: string
	sortName: string
	order?: boolean
}) {
	const state = store.getState()

	let params = {
		limit: 5,
		order: (order ? '' : '-') + sortName,
		with: ['resultsCnt', 'initialSumSum', 'maximumPriceSum', 'purchases', 'uncInfo', 'projects', 'linkedProjectIDs'],
	}

	const { purchases } = store.getState().filter
	const object = excludeData(purchases.showFilterState)

	let intermediateValue = {
		...object,
	}

	if (purchases.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}

	yield put(refreshPurchasesState('isLoadingInitList', true))
	const res: IPurchasesList = yield call(getPurchasesListByParams, params)

	yield put(
		setPurchases(
			{
				list: res.purchases,
				count: Number(res.resultsCnt),
				purchasesType: state.purchases.purchasesType,
				uncInfo: res.uncInfo,
				state: {
					allowMoreItems: (res?.purchases?.length || 0) < Number(res?.resultsCnt || 0),
					isLoadingInitList: false,
				},
				iSum: Number(res.initialSumSum) / 10 ** 6,
				mSum: Number(res.maximumPriceSum) / 10 ** 6,
				sortParams: params,
				paginationCounter: 5,
				IDs: null,
				isFavorite: false,
				linkedProjects: [],
			},
			false
		)
	)
	yield put(refreshPurchasesState('isLoadingInitList', false))
}

function* refreshPurchaseFilterState({
	fieldName,
	value,
}: {
	type: FilterActions.REFRESH_FILTER_STATE
	fieldName: string
	value: string
}) {
	const state = store.getState()
	const filterParams = {
		...state.filter.purchases.showFilterState,
		[fieldName]: value,
	}

	let params = {
		...state.purchases.sortParams,
		...filterParams,
	}

	const res: IPurchasesList = yield call(getPurchasesListByParams, params)

	yield put(setFilterCountResult(Number(res.resultsCnt), fieldName, value))
}

function* showFilterResultPurchases({
	filterParams,
}: {
	type: PurchasesType.SHOW_FILTER_RESULT
	filterParams: IPurchaseFilterState
}) {
	yield put(refreshPurchasesState('isLoadingInitList', true))
	const state = store.getState()

	let params = {
		...state.purchases.sortParams,
		...filterParams,
	}

	const res: IPurchasesList = yield call(getPurchasesListByParams, params)

	yield put(
		setPurchases(
			{
				list: res.purchases,
				count: Number(res.resultsCnt),
				...state.filter.purchases.showFilterState,
				purchasesType: state.purchases.purchasesType,
				uncInfo: res.uncInfo,
				state: {
					allowMoreItems: (res?.purchases?.length || 0) < Number(res?.resultsCnt || 0),
					isLoadingInitList: false,
				},
				iSum: Number(res.initialSumSum) / 10 ** 6,
				mSum: Number(res.maximumPriceSum) / 10 ** 6,
				sortParams: state.purchases.sortParams,
			},
			false
		)
	)
	yield put(refreshPurchasesState('isLoadingInitList', false))
}

function* selectPurchase({
	purchaseID,
	purchaseType,
}: {
	type: PurchasesType.SELECT_PURCHASE
	purchaseID: number | string
	purchaseType: PurchasesTypeEnum
}) {
	yield put(refreshPurchasesState('isLoadingSelectedPurchaseDetail', false))
	const details: IPurchase = yield call(getPurchaseDetailApi, purchaseType, purchaseID)
	yield put(setSelectedPurchase(details))
	yield put(refreshPurchasesState('isLoadingSelectedPurchaseDetail', false))
}

function* appendPurchases() {
	yield put(refreshPurchasesState('isMoreItemsLoading', false))
	const state = store.getState()
	if (!state.purchases.state.allowMoreItems) return

	const counter = Number(state.purchases.paginationCounter) + 5

	let params = {
		...state.purchases.sortParams,
		...state.filter.purchases.showFilterState,
		offset: counter,
	}

	const res: IPurchasesList = yield call(getPurchasesListByParams, params)

	if (res && res.status === 'success') {
		const items = [...state.purchases.list, ...res.purchases]
		yield put(setPurchaseItems(items))
		yield put(setPurchasesPaginationCounter(counter))

		if (!(res.purchases.length || res.purchases.length > 5)) {
			yield put(refreshPurchasesState('allowMoreItems', false))
			yield put(setPurchasesPaginationCounter(0))
		}
	}
}

function* purchaseCollapseInfo({ purchase }: { type: PurchasesType.EXPAND_PURCHASE_CARD_LIST; purchase: IPurchase }) {
	const purchaseDetails: IPurchase = yield call(getPurchaseDetailApi, purchase.model, purchase.id)
	yield put(appendPurchaseInfo(purchaseDetails.guid, 'projects', purchaseDetails.projects))
}

function* actionFavorite({
	projectId,
	action,
}: {
	type: typeof PurchasesType.ACTIONS_FAVORITE_PURCHASES
	projectId: string
	action: 'DELETE' | 'POST'
}) {
	// yield put(setLoadingFavorites(true))
	const { list } = store.getState().purchases
	const IDs = store.getState().purchases.IDs
	const projectIds = projectId === 'multiple' ? IDs && IDs.join() : projectId

	const url = 'user-favorite-purchases'
	const result = yield call(actionFavoritesProjectsApi, url, { purchaseId: projectIds }, action)

	if (result.status === 'success') {
		let tempList = []

		if (result?.result[projectIds || 0] === true) {
			tempList = list.map(purchases => (purchases.id === projectIds ? { ...purchases, isFavorite: false } : purchases))
		} else {
			tempList = list.map(purchases => (purchases.id === projectIds ? { ...purchases, isFavorite: '1' } : purchases))
		}

		yield put(SetPurchasesFavorite(tempList))
	}
}

function* getAllFavoritePurchases() {
	const response = yield call(getAllFavoritePurchasesApi)
	if (response.purchases.length > 0) {
		yield put(refreshList(response, true))
	}
}

export default function* watchPurchases() {
	// yield takeEvery(PurchasesActions.GET_LIST, getPurchaseList)
	yield takeEvery(PurchasesType.GET_ALL_FAVORITE_PURCHASES, getAllFavoritePurchases)
	yield takeEvery(PurchasesType.SORT_PURCHASES, sortPurchaseList)
	yield takeEvery(PurchasesType.REFRESH_FILTER_STATE, refreshPurchaseFilterState)
	yield takeEvery(PurchasesType.SHOW_FILTER_RESULT, showFilterResultPurchases)
	yield takeEvery(PurchasesType.SELECT_PURCHASE, selectPurchase)
	yield takeEvery(PurchasesType.APPEND_PURCHASES, appendPurchases)
	yield takeEvery(PurchasesType.EXPAND_PURCHASE_CARD_LIST, purchaseCollapseInfo)
	yield takeEvery(PurchasesType.ACTIONS_FAVORITE_PURCHASES, actionFavorite)
}
