import React from 'react'
import Card from '@root-gipro/modules/developmentSchemesAndPrograms/components/partials/Card'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

const List = () => {
    const programs = useSelector((state:IState) => state.developmentSchemesAndPrograms.programs)

    return (
        <>
            {programs.length > 0 &&
                <ul className="programs__list">
                    {programs.map(({
                            id,
                            quantity,
                            since,
                            title,
                            startedAt,
                            finishedAt,
                            publishedAt,
                            resource,
                            mediaId
                        }) =>
                            <Card
                                key={`sipr${id}`}
                                quantity={ quantity }
                                since={ since }
                                title={ title }
                                startedAt={ startedAt }
                                finishedAt={ finishedAt }
                                publishedAt={ publishedAt }
                                resource={ resource }
                                mediaId={ mediaId }
                            />
                    )}
                </ul>
            }

            {!programs.length &&
                <p>Нет данных</p>
            }
        </>
    )
}

export default React.memo(List)
