import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { Routes } from '@root-gipro/router'
import style from '@root-gipro/modules/header/styles/HeaderNav.module.scss'
import '@root-gipro/modules/header/styles/HeaderNavSubmenu.scss'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

const HeaderNav: React.FC = memo(() => {
	// const role = useSelector((state: IState) => state.auth.user.roleId)
	const role = Number(window.localStorage.getItem('roleId'))
	// console.log(role)
	const [adminMenuIsVisible, setAdminMenuIsVisible] = useState(false)
	const [adminSubMenuClass, setAdminSubMenuClass] = useState('nav-submenu')
	const { toggleType } = useSelector((state: IState) => state.favorites)
	useEffect(() => {
		if (adminMenuIsVisible) {
			setAdminSubMenuClass('nav-submenu nav-submenu--active')
		} else {
			setAdminSubMenuClass('nav-submenu')
		}
	}, [adminMenuIsVisible])

	return (
		<Grid>
			<NavLink to={Routes.TARIFFS} activeClassName={style.active} className={style.navLink}>
				Тарифы
			</NavLink>
			<NavLink to={Routes.FAVORITES_COMMON_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Избранное
			</NavLink>
			{role && (role <= 100 || role == 999) && (
				<NavLink to={Routes.USER_PROJECTS} activeClassName={style.active} className={style.navLink}>
					Расчеты
				</NavLink>
			)}
			<NavLink to={Routes.COMMON_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Проекты
			</NavLink>
			<NavLink to={Routes.PURCHASES} activeClassName={style.active} className={style.navLink}>
				Закупки
			</NavLink>
			<NavLink to={Routes.INVESTMENT_PROGRAMS} activeClassName={style.active} className={style.navLink}>
				ИПР
			</NavLink>
			<NavLink to={Routes.DEVELOPMENT_SCHEMES_AND_PROGRAMS} activeClassName={style.active} className={style.navLink}>
				СиПР
			</NavLink>

			{role && role <= 10 && (
				<div className={style.navLink} onClick={() => setAdminMenuIsVisible(!adminMenuIsVisible)}>
					Администратор <small>&#9660;</small>
					<div className={adminSubMenuClass}>
						<NavLink to={Routes.ADMIN_INVESTMENT_PROGRAMS} activeClassName={style.active} className={style.navLink}>
							ИПР
						</NavLink>
						<NavLink
							to={Routes.ADMIN_DEVELOPMENT_SCHEMES_AND_PROGRAMS}
							activeClassName={style.active}
							className={style.navLink}
						>
							СиПР
						</NavLink>
					</div>
				</div>
			)}
		</Grid>
	)
})

export default HeaderNav
