import React, { FC } from 'react'
import { ClickAwayListener, Popper, ListItem, ListItemText, List, Divider } from '@material-ui/core'
import { ArrowDownSVG } from '@root-gipro/shared/SVGComponents/ArrowDownSVG'
import styles from '@root-gipro/modules/commonProjects/styles/Reports.module.scss'
import cn from 'classnames'
import { DownloadData } from '../Reports'
import { FormReports } from './FormReports'
import { UserInfoFormReports } from './UserInfoFormReports'

interface ReportsDropDownProps {
	anchorEl: (EventTarget & HTMLElement) | null
	open: boolean
	handler(event: React.MouseEvent<HTMLDivElement>, newPlacement: string | DownloadData): void
	subList: SubList
	list: list[]
	elem: (EventTarget & HTMLElement) | null | undefined
	handleClickAway(): void
	roleId: number | null
}
type list = {
	name: string
	trigger: string
	downloadData?: {
		name: string
		order: boolean
		sortName: string
	}
}
interface SubList {
	[key: string]: subItem[]
}
type subItem = {
	name: string
	link: string
}
const ReportsDropDown: FC<ReportsDropDownProps> = ({
	handleClickAway,
	elem,
	open,
	subList,
	list,
	anchorEl,
	handler,
	roleId,
}) => {
	let arrItem: subItem[] = []
	Object.keys(subList).map((item: string) => {
		if (elem && elem.dataset.popper === item) {
			return Object.assign(subList[item]).filter((subItem: subItem) => {
				arrItem.push(subItem)
			})
		}
	})

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div>
				<Popper className={styles.list} open={!!anchorEl} anchorEl={anchorEl} transition placement='bottom'>
					<List className='drop-down__list' component='nav' aria-label='main mailbox folders'>
						{list.map((item: list) => (
							<ListItem
								key={item.name}
								onClick={(event: React.MouseEvent<HTMLDivElement>) =>
									handler(event, item.downloadData ? item.downloadData : item.trigger)
								}
								data-popper={item.trigger}
								button
								className={cn(styles.rotate, styles.item, {
									[styles.itemActive]: elem && item.trigger === elem.dataset.popper,
								})}
							>
								<ListItemText primary={item.name} />
								{!item.downloadData && <ArrowDownSVG />}
							</ListItem>
						))}

						{
							// 50 - роль Техподдержки
							(roleId == 50 || roleId == 1) && (
								<Divider />
							)
						}

						{
							// 50 - роль Техподдержки
							(roleId == 50 || roleId == 1) && (
								<ListItem
									key={'Пользователь'}
									onClick={(event: React.MouseEvent<HTMLDivElement>) => handler(event, 'user_info')}
									data-popper={'user_info'}
									button
									className={cn(styles.rotate, styles.item, {
										[styles.itemActive]: elem && 'user_info' === elem.dataset.popper,
									})}
								>
									<ListItemText primary={'Пользователь'} />
									<ArrowDownSVG />
								</ListItem>
							)
						}

						{
							// 50 - роль Техподдержки
							(roleId == 50 || roleId == 1) && (
								<ListItem
									key={'Действия'}
									onClick={(event: React.MouseEvent<HTMLDivElement>) => handler(event, 'actions')}
									data-popper={'actions'}
									button
									className={cn(styles.rotate, styles.item, {
										[styles.itemActive]: elem && 'actions' === elem.dataset.popper,
									})}
								>
									<ListItemText primary={'Действия'} />
									<ArrowDownSVG />
								</ListItem>
							)
						}
					</List>
				</Popper>
				<Popper open={open} anchorEl={elem} placement='right-start'>
					<div className={styles.subList}>
						{elem && elem.dataset.popper === 'user_info' ? (
							(roleId == 50 || roleId == 1) && <UserInfoFormReports />
						)
						: elem && elem.dataset.popper === 'actions' ? (
							(roleId == 50 || roleId == 1) && <FormReports />
						)
						: (
							<>
								{arrItem.map((item: subItem) => (
									<ListItem key={item.name} button className='drop-down-item ' onClick={() => window.open(item.link)}>
										<div>{item.name}</div>
									</ListItem>
								))}
							</>
						)}
					</div>
				</Popper>
			</div>
		</ClickAwayListener>
	)
}
export { ReportsDropDown }
