import React, { ReactText, MouseEvent } from 'react'
import { IDepGroup, IRegion } from '@root-gipro/store/interfaces'
import { capitalizeFirstLetter, formatDate } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import PurchaseInvestCount from '@root-gipro/modules/purchases/components/PurchaseInvestCount'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { isHidden } from '@root-gipro/modules/purchases/utils/isHidden'
import { gridItemMd } from '@root-gipro/modules/purchases/utils/gridItemMd'
import { gridItemMdLg } from '@root-gipro/modules/purchases/utils/gridItemMdLg'
import { ToggleInvestDetailTypes } from '../../containers/PurchaseDetail'

interface ISelectedPurchaseCollapseDetail {
	title: string
	extraClass: string
	value: (item: IPurchase) => string | JSX.Element
	clickLabel?: (event: MouseEvent<HTMLDivElement>) => void | undefined
	clickValue?: (purchase_id: ReactText) => void
	projects?: (item: number | undefined) => number | undefined
}

export const SelectedPurchaseCollapseDetail = (
	depGroups: IDepGroup[],
	regions: IRegion[],
	toggleInvestProjectDetails: ToggleInvestDetailTypes | undefined,
	model: PurchasesTypeEnum
): ISelectedPurchaseCollapseDetail[] => [

	{
		title: 'Способ закупки ',
		extraClass: 'grid-item',
		value: (item: IPurchase) => capitalizeFirstLetter(item.method),
	},
	{
		title: 'Дата размещения плана',
		extraClass: 'grid-item',
		value: (item: IPurchase) => formatDate(item.publicationDate),
	},
	{
		title: 'Начало действия плана',
		extraClass: 'grid-item',
		value: (item: IPurchase) => formatDate(item.startDate),
	},
	{
		title: 'Окончание действия плана',
		extraClass: 'grid-item',
		value: (item: IPurchase) => formatDate(item.endDate),
	},
	{
		title: 'Регион поставки',
		extraClass: 'grid-item-md',
		value: (item: IPurchase): string | JSX.Element => regions.find(region => region.id === item.regionId)?.name || '',
	},
	{
		title: 'Закупка в электронной форме',
		extraClass: 'grid-item',
		value: (item: IPurchase): string => (Boolean(item.isElectronic) ? 'Да' : 'Нет'),
	},
	{
		title: 'Инновационная продукция',
		extraClass: 'grid-item',
		value: (item: IPurchase): string => (Boolean(item.planType === 'I') ? 'Да' : 'Нет'),
	},
	{
		title: 'Закупка у СМП',
		extraClass: 'grid-item',
		value: (item: IPurchase): string => (Boolean(item.isSMB) ? 'Да' : 'Нет'),
	},
	{
		title: 'Минимальные предъявляемые требования',
		extraClass: 'grid-item',
		value: (item: IPurchase) => capitalizeFirstLetter(item.minimumRequirements),
	},
	{
		title: 'Дополнительная информация',
		extraClass: gridItemMd(model),
		value: (item: IPurchase) => item.additionalInfo,
	}

]
