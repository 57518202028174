import React, { FC, MouseEvent, ChangeEvent, useState } from 'react'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import { Switch } from './Switch'
import styles from '../style/Card.module.scss'
import { nanoid } from 'nanoid'
import { useDispatch } from 'react-redux'
import { selectTarif } from '../store/actions'

interface CardProps {
	showModal(event: MouseEvent<HTMLElement>): void
	handleChange(event: ChangeEvent<HTMLInputElement>): void
	item: any // todo any
}

const Card: FC<CardProps> = ({ handleChange, item, showModal }) => {
	const [check, setCheck] = useState(false)
	const dispatch = useDispatch()
	return (
		<div className={styles.card}>
			<div className='icon'>
				<img src={`/assets/images/${item.img}`} alt={item.name} />
			</div>
			<div className={styles.title}>Gipro</div>
			<div style={{ color: item.color }} className={styles.subTitle}>
				{item.name}
			</div>

			{check ? (
				<React.Fragment>
				<div className={styles.description}>{item.description}</div>
				<div className={styles.price}>{item.tarifYear_unlimit} ₽</div>
				<div className={styles.shrtText}>за 1 пользователя в месяц при годовой оплате</div>
				</React.Fragment>
			) : (
				<React.Fragment>
				<div className={styles.description}>{item.description}</div>
				<div className={styles.price}>{item.tarifYear} ₽</div>
				<div className={styles.shrtText}>за 1 пользователя в месяц при годовой оплате</div>
				</React.Fragment>
			)}

			{item.id === 3 ? (
				<div className={styles.switch}></div>
			) : (
				<div className={styles.switch}>
					<Switch
						onChange={change => setCheck(change.target.checked)}
						checked={check}
						color='default'
						inputProps={{ 'aria-label': 'checkbox with default color' }}
					/>
					<div className={styles.txt}>Без ограничений / Плюс</div>
				</div>
			)}
			<div className={styles.wrapButton}>
				<PrimaryBtn
					onClick={e => {
						dispatch(selectTarif(`${item.id}`, check))
						showModal(e)
					}}
					fullWidth
					style={{ backgroundColor: `${item.color}`, border: `1px solid ${item.color}` }}
				>
					Получить доступ
				</PrimaryBtn>
			</div>

			<div className={styles.shrtText}>Включает в себя</div>
			{check ? (

				<div className={styles.list}>
					{item.data_unlimit.map((text: string) => {
						return (
							<div className={styles.item} key={nanoid()}>
								<div>✔</div>
								<div className={styles.text}>{text}</div>
							</div>
						)
					})}
				</div>

			) : (
				<div className={styles.list}>
					{item.data.map((text: string) => {
						return (
							<div className={styles.item} key={nanoid()}>
								<div>✔</div>
								<div className={styles.text}>{text}</div>
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

export { Card }
