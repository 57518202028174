import React, { useMemo, useContext, useRef, ReactText } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputRightTable } from './InputRightTable'
import AddUncVoltage from '@root-gipro/modules/userProjects/components/Project/AddUncVoltage'
import { IRightTableUncProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { tableNames } from '@root-gipro/modules/userProjects/models/consts/unc-add-right-table.const'
import { ModalContext } from '@root-gipro/context/modal-context'
import { changeVoltage } from '@root-gipro/modules/userProjects/store/actions'
import { Voltage } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { getTargetVoltageValues } from '@root-gipro/modules/userProjects/utils/helpers'
import { IconButton } from '@material-ui/core'
import { IState } from '@root-gipro/store/interfaces/state'
import { IUnc } from '@root-gipro/store/interfaces'

export const RightTableUnc: React.FC<IRightTableUncProps> = ({ uncAddBuffer, deleteUnc }) => {
	const { setAnchorEl, anchorEl, accessoryRef } = useContext(ModalContext)
	const { loading } = useSelector((state: IState) => state.userProjects)
	const voltageRef = useRef<string>()
	const dispatch = useDispatch()
	const addBufferObject = useMemo(() => {
		let obj: { [key: string]: any } = {}
		uncAddBuffer.forEach((elem: IUnc) => {
			let tableNum = elem.tableNum
			if (!obj[tableNum]) {
				obj[tableNum] = []
			}
			obj[tableNum].push(elem)
		})
		return obj
	}, [uncAddBuffer])

	return (
		<div className='table_wrapper'>
			<div className='table-right'>
				<div className='table-right__head'>
					<div className='head__item'>Расценка</div>
					<div className='head__item'>Кол-во</div>
					<div className='head__item'>Напр.</div>
					<div className='head__item'>
						<div className='del-icon '></div>
					</div>
				</div>
				<div className='table-right__body'>
					{addBufferObject &&
						Object.keys(addBufferObject).map((num, index) => (
							<div key={num} className='body__row-wrapper'>
								<div className='body__row__title'>
									<span className='table_num'>{`Таблица ${num == '8' ? 'C' : num}. `}</span>
									{tableNames[num]}
								</div>
								{addBufferObject[num].map((unc: IUnc, index: ReactText) => (
									<div className='body__row-cell' key={unc.uncCell.code + unc.id + String(index)}>
										<div className='row-cell'>{unc.uncCell.code}</div>
										<div className='row-cell'>
											<InputRightTable key={unc.id} uncId={unc.id} uncCount={unc.count} />
										</div>
										<div className='row-cell'>
											<AddUncVoltage key={unc.uncCellId} voltageRef={voltageRef} dataAttr='volt-addBuff' unc={unc} />
										</div>
										<div className='row-cell'>
											<IconButton disabled={loading} onClick={() => deleteUnc(unc.id)}>
												<div className='del-icon'></div>
											</IconButton>
										</div>
									</div>
								))}
							</div>
						))}
				</div>
			</div>
			{anchorEl?.dataset.popup === 'volt-addBuff' && (
				<Voltage
					voltageRef={voltageRef}
					uncVoltage={voltageRef.current}
					targetVoltageValues={getTargetVoltageValues(uncAddBuffer, anchorEl?.dataset.uncid)}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl!}
					handler={(voltage: number) => {
						dispatch(changeVoltage(accessoryRef?.current as string, voltage, true))
						setAnchorEl!(null!)
					}}
				/>
			)}
		</div>
	)
}
