import React, { useContext, useState, FC } from 'react'
import { formatDate, prettyCost } from '@root-gipro/utils/helpers/common'
import { IParametersProps } from '@root-gipro/store/interfaces'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import { Detailed } from '@root-gipro/modules/commonProjects/components/SelectProjects/Parameters/Detailed'
import { Justification, ParamsOptions } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { CardFull, CardLeft, CardRight, CardItem } from '@root-gipro/shared/Card'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { ModalContext } from '@root-gipro/context/modal-context'
import '@root-gipro/modules/commonProjects/styles/Parameters.scss'

const Parameters: FC<IParametersProps> = ({
	editToggleType,
	group,
	depGroup,
	project,
	toggleType,
	region,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
	policesRGCO,
	policesGipro,
	rgStatuses,
}) => {
	const [toggler, setToggler] = useState(false)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	const getRgStatusId = () => {
		requestRgStatuses(project.id)
	}
	return (
		<>
			<div className='container-full'>
				<CardFull classes='detail-common-projects'>
					<div className='card-full__item'>
						<CardLeft>
							<div className='card-full__header'>
								<div className='card-full__code link-blue'>
									<span className='common-project-icon' />
									<span className='code'>{project.code}</span>
								</div>
								<div className='card-full__actions'>
									<div
										className='link-blue link card-full__actions-item'
										data-popup='justification'
										onClick={e => setAnchorEl!(e.currentTarget)}
									>
										Обоснование
									</div>
									<span
										data-popup='options'
										onClick={e => setAnchorEl!(e.currentTarget)}
										className='popup-icon card-full__actions-item'
									/>
								</div>
							</div>
							<CardDate>Изменено {formatDate(project.publicationDate)}</CardDate>
							{group && <CardTitle>{group.name}</CardTitle>}
							<CardDescription>{project.name}</CardDescription>
						</CardLeft>
						<CardRight>
							{depGroup && <CardItem title={'Филиал ДЗО'} value={depGroup.name} />}
							{region && <CardItem title={'Регион'} value={region} />}
							{(project.valuationOfferForecast || project.valuationPlanForecast) && (
								<CardItem
									title={'Оценка полной стоимости в прогнозных ценах'}
									value={
										toggleType === 'offer'
											? addHTML(prettyCost(project.valuationOfferForecast / 10 ** 6))
											: addHTML(prettyCost(project.valuationPlanForecast / 10 ** 6))
									}
								/>
							)}
						</CardRight>
					</div>
				</CardFull>
			</div>
			<Detailed project={project} toggleType={toggleType} editToggleType={editToggleType} />

			{anchorEl?.dataset.popup === 'justification' && (
				<Justification anchorEl={anchorEl!} setAnchorEl={setAnchorEl!} offerRationale={project.offerRationale} />
			)}
			{anchorEl?.dataset.popup === 'options' && (
				<ParamsOptions
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					policesGipro={policesGipro}
					policesRGCO={policesRGCO}
					copyHrefToClipboard={copyHrefToClipboard}
					downloadCommonProject={downloadCommonProject}
					project={project}
					addToUserProject={addToUserProject}
					getRgStatusId={getRgStatusId}
					rgStatuses={rgStatuses}
					rgStatusId={project.rgStatusId}
					toggler={toggler}
					togglerSubmenu={() => setToggler(!toggler)}
				/>
			)}
		</>
	)
}

export default Parameters
