import React from 'react'

export const DateFromTimestamp = (param: any) => {

	const dateObj = new Date(param.timestamp * 1000);

  	var year = dateObj.getFullYear();
  	var month = dateObj.getMonth();
  	var date = dateObj.getDate();
	var hours = dateObj.getHours();
	var minutes = "0" + dateObj.getMinutes();
	var seconds = "0" + dateObj.getSeconds();

	// const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
	const formattedDate = date + '.' + month + '.' + year;

	return <>{formattedDate}</>
}

