import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import './Comment.scss'

export interface CommentProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	setComment(comment: string): void
	comment: string | undefined
	handler(): void
}

const CommentDropDown: React.FC<CommentProps> = ({ anchorEl, setAnchorEl, setComment, comment, handler }) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ borderRadius: 9, width: 240 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div className='comment'>
				<div className='title mb-16'>Комментарий</div>
				<textarea
					placeholder='Введите комментарий'
					value={comment ? comment : ''}
					onChange={e => setComment(e.currentTarget.value)}
				/>
				<div className='action mt-16'>
					<PrimaryBtn onClick={handler}>Готово</PrimaryBtn>
					<SecondaryBtn onClick={() => setAnchorEl!(null!)}>Отменить</SecondaryBtn>
				</div>
			</div>
		</DropDown>
	)
}
export default CommentDropDown
