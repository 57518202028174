import { IDepGroup, IGroup, IRegion, IRgStatuses, IUncMain, IUncThDescriptions, IUncThs } from '../interfaces'

enum PublicType {
	FETCH_PUBLIC_INFO = 'FETCH_PUBLIC_INFO',
	SET_PUBLIC_INFO = 'SET_PUBLIC_INFO',
	SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS = 'SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS',
	GET_PURCHASES_METHODS = 'GET_PURCHASES_METHODS',
	SET_PURCHASES_METHODS = 'SET_PURCHASES_METHODS',

	GET_PURCHASES_STAGES = 'GET_PURCHASES_STAGES',
	SET_PURCHASES_STAGES = 'SET_PURCHASES_STAGES',
}

interface FetchPublicInfo {
	type: PublicType.FETCH_PUBLIC_INFO
}
interface GetPurchasesMethods {
	type: PublicType.GET_PURCHASES_METHODS
}
interface SetPurchasesMethods {
	type: PublicType.SET_PURCHASES_METHODS
	purchasesMethods: string[]
}
interface GetPurchasesStages {
	type: PublicType.GET_PURCHASES_STAGES
}
interface SetPurchasesStages {
	type: PublicType.SET_PURCHASES_STAGES
	purchasesStages: string[]
}
interface SetPublicInfo {
	type: PublicType.SET_PUBLIC_INFO
	policesGipro: boolean
	policesRGCO: boolean
	groups: IGroup[]
	regions: IRegion[]
	uncMains: IUncMain[]
	depGroups: IDepGroup[]
	publicationDates: string[]
	publicationDatesPlans: string[]
	yearBegin: string
	yearEndOffer: string
	uncSections: any[]
	uncThs: IUncThs[]
	uncThDescriptions: IUncThDescriptions[]
	rgStatuses: IRgStatuses[]
}

interface SetPublicUncTechnicalSpecifications {
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS
	uncTechnicalSpecifications:  {
		name:{},
		paramName:{},
		param:{}
	},
}

const fetchPublicInfo = () => ({
	type: PublicType.FETCH_PUBLIC_INFO,
})

const setPublicInfo = () => ({
	type: PublicType.SET_PUBLIC_INFO,
})

const setPublicUncTechnicalSpecifications = (uncTechnicalSpecifications: {
	name:{},
	paramName:{},
	param:{}
}) => ({
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS,
	uncTechnicalSpecifications,
})

const getPurchasesMethods = () => ({
	type: PublicType.GET_PURCHASES_METHODS,
})
const setPurchasesMethods = (purchasesMethods: string[]) => ({
	type: PublicType.SET_PURCHASES_METHODS,
	purchasesMethods,
})

const getPurchasesStages = () => ({
	type: PublicType.GET_PURCHASES_STAGES,
})
const setPurchasesStages = (purchasesStages: string[]) => ({
	type: PublicType.SET_PURCHASES_STAGES,
	purchasesStages,
})

export type PublicTypes =
	| FetchPublicInfo
	| SetPublicInfo
	| SetPublicUncTechnicalSpecifications
	| SetPurchasesMethods
	| GetPurchasesMethods
	| SetPurchasesStages
	| GetPurchasesStages

export {
	PublicType,
	fetchPublicInfo,
	setPublicInfo,
	setPublicUncTechnicalSpecifications,
	getPurchasesMethods,
	setPurchasesMethods,
	getPurchasesStages,
	setPurchasesStages,
}
