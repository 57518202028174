import React from 'react'
import { useSelector } from 'react-redux'
import { Item } from '@root-gipro/modules/userProjects/components/UserProjects/Item'
import { IUserProject } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'

const List: React.FC = () => {
	const { projectList, projectFilter } = useSelector((state: IState) => state.userProjects)
	return (
		<div className='user-projects__list'>
			<ScrollHandler />

			{projectList
				.filter((project: IUserProject) => project.code.match(projectFilter) || project.name.match(projectFilter))
				.map(item => (
					<Item key={item.id} data={item} />
				))}
		</div>
	)
}

export default List
