import React, { useState, useContext, useCallback, MouseEvent, ChangeEvent, useEffect } from 'react'
import { Card } from '@root-gipro/modules/tariffs/components/Card'
import styles from './style/Tariffs.module.scss'
import { objectStylesForCard } from '@root-gipro/modules/tariffs/models/consts/objectStylesForCard'
import { ExtensionAccess } from '@root-gipro/modules/tariffs/components/Modal/ExtensionAccess/ExtensionAccess'
import { ModalContext } from '@root-gipro/context/modal-context'
import { useDispatch } from 'react-redux'
import { getTariffPlans } from '@root-gipro/modules/tariffs/store/actions'

const TariffsContainer = () => {
	const [state, setState] = useState(false)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setState(true)
	}
	const hideModal = useCallback(() => {
		setAnchorEl && setAnchorEl(null)
	}, [anchorEl])

	const showModal = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			setAnchorEl && setAnchorEl(event.currentTarget)
		},
		[setAnchorEl]
	)

	useEffect(() => {
		dispatch(getTariffPlans())
	}, [])

	return (
		<div className='wrapper'>
			<div className={`personal-area__container ${styles.tariffs}`}>
				<h2 className={styles.title}>Тарифные планы</h2>
				<div className={styles.list}>
					{objectStylesForCard.map(item => (
						<Card key={item.id} showModal={showModal} handleChange={handleChange} item={item} />
					))}
				</div>
			</div>
			<ExtensionAccess anchorEl={anchorEl} hideModal={hideModal} setAnchorEl={setAnchorEl} />
		</div>
	)
}

export { TariffsContainer }
