import React, { useState } from 'react'
import useContext from 'react'
import { IDevelopmentSchemesAndProgramsProps } from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs'
import UsersIcon from '@root-gipro/components/Icons/UsersIcon'
import { fetchMedia } from '@root-gipro/store/api'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Link } from 'react-router-dom'

const Card: React.FC<IDevelopmentSchemesAndProgramsProps> = ({
    quantity,
    since,
    title,
    startedAt,
    finishedAt,
    publishedAt,
    resource,
    mediaId
}) => {
    const [mediaLink, setMediaLink] = useState('')
    const [mediaLinkDownloading, setMediaLinkDownloading] = useState(false)
    const role = Number(window.localStorage.getItem('roleId'))
    const [showAlert, setShowAlert] = useState(false)

    const fetchMediaUrl = () => {
        if (role && role==999) {
            setShowAlert(true)
        }
        else if (mediaId) {
            setMediaLinkDownloading(true)
            fetchMedia(mediaId)
                .then(({ path }) => {
                    setMediaLink(`${process.env.REACT_APP_TRANSFER_PROTOCOL}://${process.env.REACT_APP_ENV}${path}`)
                    setMediaLinkDownloading(false)
                })
        }
    }

    return (
        <li className="investment-program">
            <ul className="investment-program__list">
                <li className="investment-program__list-header">
                    <UsersIcon />
                    <p>Схемы и программы развития</p>
                </li>

                <li>
                    <h2 className="investment-program__list-title">{ title }</h2>
                </li>

                <li className="investment-program__list-since">
                    Год утверждения схемы и программы развития: { since }
                </li>

                <li className="investment-program__list-period">
                    Период: с { startedAt } по { finishedAt }
                </li>

                <li className="investment-program__list-quantity">
                    Количество проектов: { quantity }
                </li>

                {showAlert===true && <Alert severity="warning" action={<Link to='/tariffs'>Подключить</Link>}>
                                        Возможно только при подключении тарифа
                                     </Alert>}

                {showAlert===false && mediaId &&
                    <li>
                        {mediaLink &&
                            <a
                                className="investment-program__list-link"
                                href={mediaLink}
                                /* eslint-disable-next-line react/jsx-no-target-blank */
                                target="_blank"
                                download
                            >
                                Скачать
                            </a>
                        }
                        {!mediaLink &&
                            <a
                                className="investment-program__list-link"
                                onClick={fetchMediaUrl}
                            >
                                { mediaLinkDownloading ? 'Загрузка...' : ' Загрузить' }
                            </a>
                        }
                    </li>
                }
            </ul>

            <div className="investment-program__fieldset">
                <fieldset>
                    <label>
                        <p>Публикация в открытых источниках</p>
                        <input type="date" readOnly defaultValue={ publishedAt } />
                    </label>
                </fieldset>
                <fieldset>
                    <label>
                        <p>Открытый источник информации</p>
                        <input type="text" readOnly defaultValue={ resource } />
                    </label>
                </fieldset>
            </div>
        </li>
    )
}

export default React.memo(Card)
