import styled from 'styled-components'

export const ButtonFavoritesDelete = styled.div`
	display: flex;
	cursor: pointer;
	height: 32px;
	padding: 0 8px;
	align-items: center;
	border-radius: 4px;
	color: #1baaf0 !important;
	border: 1px solid #1baaf0 !important;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`
