import { ReactText } from 'react'

import { formatDate, formatDateLong, prettyCost } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'

interface ICardRightSidePrices {
	title: string
	value: (item: IPurchase) => ReactText | JSX.Element | null | undefined
}

export const CardRightSidePrices: ICardRightSidePrices[] = [
	{
		title: 'Начальная максимальная цена контракта, млн руб. с НДС',
		value: (item: IPurchase) => item.maximumPrice / 10 ** 6
	},
	{
		title: 'Планируемая дата размещения извещения',
		value: (item: IPurchase) => formatDateLong(item.plannedDate, true),
	},
	{
		title: 'Дата окончания приёма заявок',
		value: (item: IPurchase) => {
			return (item.notice_submissionCloseDate) ? formatDate(item.notice_submissionCloseDate) : 'Не указано'
		},
	},
	{
		title: 'Срок исполнения договора',
		value: (item: IPurchase) => {
			return (item.endDate) ? formatDate(item.endDate) : 'Не указано'
		},
	},
	{
		title: 'Связь с инвестиционным проектом',
		value: (item: IPurchase) => item?.linkedProjects?.length > 0 ? item.linkedProjects[0].code : 'Нет'
	},

]
