import { ModalContext } from '@root-gipro/context/modal-context'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import React, { useCallback, useContext, MouseEvent } from 'react'
import { ButtonFavoritesDelete } from '../styles'
type Props = {
	onClick: (event: MouseEvent<HTMLElement>) => void
}
const ButtonDelete: React.FC<Props> = ({ onClick }) => {
	return <ButtonFavoritesDelete onClick={onClick}>удалить все</ButtonFavoritesDelete>
}

export default ButtonDelete
