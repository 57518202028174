import { call, put, takeLatest } from 'redux-saga/effects'
import { authorizeApi, modeURL, logoutApi, giproApp } from '@root-gipro/store/api'
import { AuthType, setAuth } from '@root-gipro/store/actions'

function* authorize() {
	try {
		const authorize = yield call(authorizeApi)

		if (authorize && authorize.status === 'success') {
			yield put(setAuth(authorize.access_token, authorize.expires_in))
			yield localStorage.setItem('access_token', authorize.access_token)
			yield localStorage.setItem('roleId', authorize.user.roleId)
			yield (giproApp.defaults.headers['Authorization'] = `Bearer ${authorize.access_token}`)
		} else {
			yield localStorage.removeItem('access_token')
			yield (window.location.href = modeURL)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield (window.location.href = modeURL)
	}
}

function* logout() {
	try {
		const res = yield call(logoutApi)

		if (res && res.status === 'success') {
			yield localStorage.removeItem('access_token')
			yield (window.location.href = modeURL)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield (window.location.href = modeURL)
	}
}

export default function* watchAuthorize() {
	yield takeLatest(AuthType.AUTHORIZE, authorize)
	yield takeLatest(AuthType.LOGOUT, logout)
}
