import React, { FC } from 'react'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { ISelectTableTbodyProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { BodyTableData } from '@root-gipro/store/interfaces'

export const SelectTableTbody: FC<ISelectTableTbodyProps> = ({ uncTableData, handlerAddUnc }) => {
	return (
		<tbody>
			{uncTableData &&
				uncTableData.body &&
				uncTableData.body?.map((elem: BodyTableData[], ind: number) => {
					return (
						<tr key={ind}>
							{elem.map(td => {
								return (
									<td
										className={
											td.uncCellId && !uncTableData.interpolation && uncTableData.active ? 'active_unc_td' : ''
										}
										key={`${td.x}_${td.y}`}
										onClick={() => {
											td.uncCellId && handlerAddUnc(td.uncCellId, uncTableData.interpolation, uncTableData.active)
										}}
									>
										{td.uncCellId && !uncTableData.interpolation && uncTableData.active
											? prettyCost(parseFloat(td.name))
											: td.name}
									</td>
								)
							})}
						</tr>
					)
				})}
		</tbody>
	)
}
