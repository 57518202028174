import { ReactText } from 'react'
import { IDepGroup, IRegion } from '@root-gipro/store/interfaces'
import { capitalizeFirstLetter } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'

interface IPurchaseCollapseDetail {
	title: string
	icon: string
	value: (item: IPurchase) => ReactText | JSX.Element
	widthContent?: string | number
}

export const PurchaseCollapseDetail = (depGroups: IDepGroup[], regions: IRegion[]): IPurchaseCollapseDetail[] => [
	{
		title: 'Инвестиционных проектов',
		icon: 'graphics-icon',
		value: (item: IPurchase): number => item?.projects?.length || 0,
	},
	{
		title: 'Регион поставки',
		icon: 'mark-icon',
		value: (item: IPurchase): string | JSX.Element => regions.find(region => region.id === item.regionId)?.name || '',
	},
	{
		title: 'Способ закупки',
		icon: 'document-icon',
		value: (item: IPurchase): string => capitalizeFirstLetter(item?.method?.split(/[(,]/)[0]),
	},
	{
		title: 'Закупка в электронной форме',
		icon: 'complete-icon',
		value: (item: IPurchase): string => (Boolean(item.isElectronic) ? 'Да' : 'Нет'),
	},
]
