import { MaketType, MaketTypes } from '../actions/maket'
import { IMaketState } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

const initialState: IMaketState = {
	activePannel: 'projects',
	isFilterOpen: false,
}

const maket = (draft: Draft<IMaketState>, action: MaketTypes) => {
	switch (action.type) {
		case MaketType.SET_FILTER_OPEN:
			draft.isFilterOpen = action.isFilterOpen
			break

		default:
			return draft
	}
}
export const maketreducer = produce(maket, initialState)

// case 'SET_ACTIVE_PANNEL':
// 	return {
// 		...state,
// 		activePannel: action.activePannel, // пока не трогать. Где изменяется не нашел...
// 	}
// case 'SET_IN_LOADING': // пока не трогать. Функционал закомментрован  в Сагах загрузки расценок в личных
// 	return {
// 		...state,
// 		inLoading: action.inLoading,
// 	}
