import { TariffsType } from '@root-gipro/modules/tariffs/models/interfaces/tariffs.actions'
import produce, { Draft } from 'immer'
import { TariffsTypes } from '@root-gipro/modules/tariffs/models/interfaces/tariffs.actions'
import { ITariffsState } from '@root-gipro/modules/tariffs/models/interfaces/tariffs-state.model'
import { tariffsState } from '@root-gipro/modules/tariffs/models/consts/tariffs-state.const'

const tariffs = (draft: Draft<ITariffsState>, action: TariffsTypes) => {
	switch (action.type) {
		case TariffsType.SELECT_TARIF:
			draft.selectedSettings = {
				...draft.selectedSettings,
				'tarif_price_id':action.id,
				unlimit:action.unlimit
			}
			break
		case TariffsType.SET_TARIFFS_PLANS:
			draft.tariffPlans = action.tariffPlans
			break
		default:
			return draft
	}
}
export const tariffsReducer = produce(tariffs, tariffsState)
