import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { CarouselElem } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/CarouselElem'
import { ICarouselProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import SwiperCore, { Navigation, Mousewheel, Keyboard } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
SwiperCore.use([Navigation, Mousewheel, Keyboard])

export const Carousel: React.FC<ICarouselProps> = ({
	totalInvestmentUncOffer,
	title,
	numberTransformation = false,
	dataArr,
	code,
}) => {
	const button = uniqueId('button')

	return (
		<div className='carousel_container'>
			<div className='project_carousel_wrapper'>
				<div
					className={`swiper-button-prev ${button}-prev ${dataArr?.length === 1 ? 'swiper-button-disabled' : ''}`}
				></div>
				<div
					className={`swiper-button-next ${button}-next ${dataArr?.length === 1 ? 'swiper-button-disabled' : ''}`}
				></div>
				<div className='project_carousel '>
					<Swiper
						spaceBetween={0}
						slidesPerView={1}
						navigation={{
							nextEl: `.${button}-next`,
							prevEl: `.${button}-prev`,
						}}
						grabCursor={true}
						mousewheel={true}
						freeMode={true}
					>
						{dataArr?.map((elem, ind: number) => (
							<SwiperSlide key={uniqueId('SwiperSlide')}>
								<CarouselElem
									code={code}
									key={uniqueId('CarouselElem')}
									label={elem[0] as string}
									value={elem[1] as number}
									numberTransformation={numberTransformation}
									totalInvestmentUncOffer={totalInvestmentUncOffer}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
				<div className='currency'>{code === 'deflator' ? '%' : 'млн.р'}</div>
			</div>
		</div>
	)
}
