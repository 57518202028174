import { Switch as SwitchComponent } from '@material-ui/core'
import { withStyles } from '@material-ui/core'

export const Switch = withStyles({
	root: {
		width: 56,
	},
	switchBase: {
		top: '9px',
		left: 5,
		color: '#7b7b7b',
		'&$checked': {
			transform: 'translateX(23px)',
			'& + $track': {
				backgroundColor: 'rgba(0, 0, 0, 0.45)',
			},
		},
	},
	thumb: {
		width: 16,
		height: 16,
	},
	checked: {},
	track: {
		width: '100px',
		backgroundColor: '#e0e0e0',
	},
})(SwitchComponent)
