import { IInvestmentProgramProps } from "@root-gipro/modules/investmentPrograms/models/interfaces/investment-program"
import { InvestmentProgramsType } from "@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.actions"
import store from '@root-gipro/store'

const getInvestmentPrograms = () => ({
    type: InvestmentProgramsType.GET_INVESTMENT_PROGRAMS,
    query: {
        ...store.getState().investmentPrograms.filter,
        ...store.getState().investmentPrograms.order
    },
})

const setInvestmentPrograms = (programs: IInvestmentProgramProps[]) => ({
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS,
    programs,
})

const setInvestmentProgramsOrder = (order: { by: 'title' | 'since' | 'startedAt'  | 'finishedAt'  | 'cost'  | 'quantity', direction: 'ASC' | 'DESC'}) => ({
    type: InvestmentProgramsType.SET_ORDER,
    order,
})

const setInvestmentProgramsTotalCost = (cost: number) => ({
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_COST,
    cost,
})

const setInvestmentProgramsIndustries = (industries: string[]) => ({
    type: InvestmentProgramsType.SET_FILTER_INDUSTRIES,
    industries,
})

const setInvestmentProgramsTotalPrograms = (total: number) => ({
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_PROGRAMS,
    total,
})

const setFilterShortTittles = (titles: string[]) => ({
    type: InvestmentProgramsType.SET_FILTER_SHORT_TITLES,
    titles,
})

const setFilterSinceDates = (sinceDates: string[]) => ({
    type: InvestmentProgramsType.SET_FILTER_SINCE_DATES,
    sinceDates,
})

const setFilterPublicationDates = (publicationDates: string[]) => ({
    type: InvestmentProgramsType.SET_FILTER_PUBLICATION_DATES,
    publicationDates,
})

const setFilterValue = (
    value: string,
    key: 'shortTitle' | 'publishedAt' | 'since' | 'startedAt' | 'finishedAt' | 'minCost' | 'maxCost' | 'industry'
) => ({
    type: InvestmentProgramsType.SET_FILTER_VALUE,
    value,
    key
})

const resetFilter = () => ({
    type: InvestmentProgramsType.RESET_FILTER
})

export {
    setInvestmentPrograms,
    getInvestmentPrograms,
    setFilterShortTittles,
    setFilterPublicationDates,
    setFilterSinceDates,
    setFilterValue,
    resetFilter,
    setInvestmentProgramsTotalCost,
    setInvestmentProgramsTotalPrograms,
    setInvestmentProgramsOrder,
    setInvestmentProgramsIndustries
}
