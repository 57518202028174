import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setYears } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { ICarouselElemProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { intFormatPercent } from '@root-gipro/modules/userProjects/utils/intFormatPercent'
import { formatValue } from '@root-gipro/modules/userProjects/utils/formatValue'
import produce, { Draft } from 'immer'
import { IUserProjectState } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'

const CarouselElem: React.FC<ICarouselElemProps> = ({
	totalInvestmentUncOffer,
	label,
	value,
	numberTransformation,
	code,
}) => {
	const [result, setResult] = useState(value ? (numberTransformation ? value / 10 ** 6 : value) : '')
	const { userProjects } = useSelector((state: IState) => state)
	const dispatch = useDispatch()

	const handleBlur = () => {
		const clone = produce(userProjects, (draft: Draft<IUserProjectState>) => {
			if (result && String(result).match(/\%/) && totalInvestmentUncOffer) {
				const notPercent = String(result).replace('%', '')
				draft.projectObj.years[label][code] = numberTransformation
					? (+totalInvestmentUncOffer * +notPercent) / 100
					: (+notPercent as number)
			} else {
				draft.projectObj.years[label][code] = numberTransformation ? +result * 10 ** 6 : (+result as number)
			}
		})

		dispatch(setYears(clone.projectObj.years))
	}

	return (
		<div className='carousel_elem'>
			<div className='label'>{label}</div>
			<div className='value'>
				<input
					onChange={e => {
						let val: string | null = e.currentTarget.value
						val = intFormatPercent(e.currentTarget.value, code)
						setResult(val)
					}}
					onBlur={handleBlur}
					value={formatValue(result)}
				/>
			</div>
		</div>
	)
}

export { CarouselElem }
