import React, { ReactText } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { IRegion } from '@root-gipro/store/interfaces'

interface SitePreparationWindow {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	handler(): void
	regionList: IRegion[]
	checkedTable: string
	setCheckedTable(id: string): void
	activeTotal: number
	checkedRegionId: ReactText | undefined
	setCheckedRegion(id: string): void
	tableNums: string[]
}

const SitePreparationWindow: React.FC<SitePreparationWindow> = ({
	checkedTable,
	handler,
	setCheckedTable,
	anchorEl,
	setAnchorEl,
	activeTotal,
	regionList,
	checkedRegionId,
	setCheckedRegion,
	tableNums,
}) => {
	const buttons = {
		PrimaryBtn: {
			handler: handler,
			value: 'Готово',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl(null!)
			},
			value: 'Отмена',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Добавить Б1',
			action: undefined,
		},
	]

	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<div className='activeTotal'>
				<label>
					<span className='activeTotal__label'>Суммарная площадь:</span>
					<input
						className='activeTotal__input'
						type='text'
						disabled
						readOnly
						value={
							(activeTotal || activeTotal === 0)! ? prettyCost(activeTotal! / 1000) : 'Не выбрано ни одной расценки С1'
						}
					/>
				</label>

				{regionList && (
					<>
						<div className='activeTotal__label'>Выберите регион:</div>
						{regionList.map((elem: IRegion) => (
							<div key={elem.id}>
								<input type='radio' hidden value={elem.id} checked={checkedRegionId === elem.id} readOnly />
								<label onClick={() => setCheckedRegion(elem.id)} className='popup_label'>
									{elem.name}
								</label>
							</div>
						))}
					</>
				)}

				<div className='activeTotal__label'>Поместить в таблицу:</div>
				<div className='activeTotal__tables'>
					{tableNums.map((num: string) => (
						<div className='table_label' key={num}>
							<input
								type='radio'
								value={num}
								checked={checkedTable === num}
								onChange={() => setCheckedTable(num)}
								name='table_num'
								id={num}
							/>
							<label htmlFor={num}>{num}</label>
						</div>
					))}
				</div>
			</div>
		</Modal>
	)
}
export default SitePreparationWindow
