import React from 'react'
import { Checkbox, ListItemText } from '@material-ui/core'
import { FilterItemCheckboxProps } from '@root-gipro/shared/Filter/models/filter-item-checkbox.props'

const FilterItemCheckbox: React.FC<FilterItemCheckboxProps> = ({ value, setValue, name, isDisable, fieldName }) => (
	<div className='aside-filter__checkbox'>
		<Checkbox disabled={isDisable} checked={Boolean(value)} onChange={() => setValue(fieldName, !Boolean(value))} />
		<ListItemText primary={name} />
	</div>
)

export default FilterItemCheckbox
