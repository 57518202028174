import React, { useCallback, useContext, useEffect } from 'react'
// import { InterPol } from '@root-gipro/modules/userProjects/components/Project/UncAdd/InterPol'
import InterPol from '@root-gipro/components/DeprecatedComponents/InterPol/InterPol'
import { ISelectTableHeader } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { UncAddR1Window } from '@root-gipro/modules/userProjects/components/Project/Modal'

import { ModalContext } from '@root-gipro/context/modal-context'
import { ToggleTabs } from '@root-gipro/modules/userProjects/components/Project/UncAdd/ToggleTabs'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { IUncTableData } from '@root-gipro/store/interfaces'

export const SelectTableHeader: React.FC<ISelectTableHeader> = ({
	uncTableData,
	setChecked,
	selectedElem,
	tableNums,
	checkedTable,
	setShowInter,
	showInter,
	createSpecialUnc,
}) => {
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	const items = tableNums.map(item => {
		return {
			name: `Таблица ${item}`,
			value: item,
		}
	})
	const itemC1 = [{ name: `Таблица С1`, value: '1' }]
	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			setAnchorEl!(e.currentTarget)
		},
		[setAnchorEl]
	)
	return (
		<div className='select-table__header'>
			<ToggleTabs
				items={selectedElem && selectedElem?.code === 'С1' ? itemC1 : items}
				clickHandler={item => setChecked(item.value)}
			/>

			{uncTableData && uncTableData.interpolation > 0 && selectedElem?.code !== 'Б1' && (
				<div className='interpol_wrapper ml-8'>
					<SecondaryBtn data-popup='unc-add-r1' onClick={openWindow}>
						Задать
					</SecondaryBtn>
					{anchorEl?.dataset.popup === 'unc-add-r1' && (
						<UncAddR1Window
							handler={createSpecialUnc}
							data={uncTableData.selects}
							anchorEl={anchorEl!}
							setAnchorEl={setAnchorEl!}
						/>
					)}
				</div>
			)}
		</div>
	)
}
