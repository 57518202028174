import React from 'react'
export const CirclePlusSVG = () => {
	return (
		<svg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M11 11H7M11 7V11V7ZM11 11V15V11ZM11 11H15H11Z' stroke-width='1.6' stroke-linecap='round' />
			<path
				d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z'
				stroke-width='1.6'
			/>
		</svg>
	)
}
