import React, { ReactText } from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { IRegion } from '@root-gipro/store/interfaces'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'

interface RegionProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	handler(): void
	regionList: IRegion[]
	checkedRegionId: ReactText
	setCheckedRegion: (id: ReactText) => void
}

const Region: React.FC<RegionProps> = ({
	anchorEl,
	setAnchorEl,
	handler,
	regionList,
	setCheckedRegion,
	checkedRegionId,
}) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ borderRadius: 9, width: 240 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<>
				<div className='title'>Укажите регион</div>
				{regionList.map(elem => (
					<div key={elem.id}>
						<input
							type='radio'
							hidden
							value={elem.id}
							id={String(checkedRegionId) + '  ' + String(elem.id)}
							checked={checkedRegionId === elem.id}
							readOnly
						/>
						<label onClick={() => setCheckedRegion(elem.id)} className='drop-down_label'>
							{elem.name}
						</label>
					</div>
				))}
				<div className='action mt-16'>
					<PrimaryBtn onClick={handler}>Выбрать</PrimaryBtn>
					<SecondaryBtn onClick={() => setAnchorEl!(null!)}>Отменить</SecondaryBtn>
				</div>
			</>
		</DropDown>
	)
}
export default Region
