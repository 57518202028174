import React, { FC } from 'react'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { objectStylesForCard } from '@root-gipro/modules/tariffs/models/consts/objectStylesForCard'

interface OrderProps {
	currenDiscount: string
}

const Order: FC<OrderProps> = ({ currenDiscount }) => {
	const { tarif_price_id, unlimit } = useSelector((state: IState) => state.tariffs.selectedSettings)
	const tarif = objectStylesForCard.find(tarif => tarif.id === +tarif_price_id)

	return (
		<div className={styles.order}>
			<div className={styles.heading}>Заказ</div>
			<div className={styles.orderList}>
				<div className={styles.orderItem}>
					<div>Срок</div>
					<div>{currenDiscount}</div>
				</div>

				<div>
					{!unlimit && (
						<div className={styles.orderItem}>
							<div>GIPRO {tarif?.name}</div>
							<div>{tarif?.tarifMonth}₽</div>
						</div>
					)}
					{unlimit && (
						<div className={styles.orderItem}>
							<div>Без ограничений</div>
							<div>{tarif!.tarifMonth_unlimit}₽</div>
						</div>
					)}
				</div>

				{
				currenDiscount.split(' ')[0] === '12' ? (
					<>
						{
							<div className={styles.orderItem}>
								<div>Скидка</div>
								<div>{!unlimit ? +tarif!.tarifYear * 12 - +(tarif!.tarifMonth * 12) : +tarif!.tarifYear_unlimit * 12 - +(tarif!.tarifMonth_unlimit * 12)}₽</div>
							</div>
						}
						<div className={styles.total}>
							<div className={styles.totalItem}>
								<div>Итого</div>
								<div>{unlimit ? tarif!.tarifYear_unlimit * 12 : +tarif!.tarifYear * 12}₽</div>
							</div>
						</div>
					</>
				) :
				currenDiscount.split(' ')[0] === '6' ? (
					<>
						{
							<div className={styles.orderItem}>
								<div>Скидка</div>
								<div>{!unlimit ? +tarif!.tarif6Month * 6 - +(tarif!.tarifMonth * 6) : +tarif!.tarif6Month_unlimit * 6 - +(tarif!.tarifMonth_unlimit * 6)}₽</div>
							</div>
						}
						<div className={styles.total}>
							<div className={styles.totalItem}>
								<div>Итого</div>
								<div>{unlimit ? tarif!.tarif6Month_unlimit * 6 : +tarif!.tarif6Month * 6}₽</div>
							</div>
						</div>
					</>
				) : (
					<div className={styles.total}>
						<div className={styles.totalItem}>
							<div>Итого</div>
							<div>{unlimit ? tarif!.tarifMonth_unlimit * +currenDiscount.split(' ')[0] : +tarif!.tarifMonth * +currenDiscount.split(' ')[0]}₽</div>
						</div>
					</div>
				)
				}
			</div>
		</div>
	)
}

export { Order }
