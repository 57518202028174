import React, { useState, useMemo, MouseEvent } from 'react'
import { Collapse, Tooltip } from '@material-ui/core'
import { IUnc } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import cn from 'classnames'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import { Cell } from './Tbody'

export interface RowProps {
	getNumberUnc?: ((id: string) => string | number | false) | undefined
	title: string | undefined
	uncs: IUnc[]
	tableNum: number
	handleShowComment?(event: MouseEvent<HTMLDivElement>, comment: string): void
	cellData: (
		getNumberUnc?: ((id: string) => string | number | false) | undefined,
		handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
	) => Cell[]
	gridTemplateColumns: string
}

const Row: React.FC<RowProps> = ({
	gridTemplateColumns,
	handleShowComment,
	title,
	uncs,
	tableNum,
	getNumberUnc,
	cellData,
}) => {
	const [open, setOpen] = useState<boolean>(!!uncs.length)

	const handleShow = () => {
		setOpen(!open)
	}

	const total: number = useMemo(() => {
		if (uncs.length > 0) {
			return uncs.reduce((accum, curr) => (accum += curr.totalCost), 0)
		} else {
			return 0
		}
	}, [uncs])

	return (
		<>
			{title && (
				<div className={styles.row}>
					<div className={styles.rowTitle} onClick={handleShow}>
						<span
							className={cn('dropdown-icon', styles.arrow, {
								[styles.open]: open,
							})}
						/>
						{`${tableNum}. ${title}`}
					</div>
					<div className={styles.total}>{prettyCost(total / 1000)}</div>
				</div>
			)}

			<Collapse in={title ? open : true} unmountOnExit>
				{!!uncs.length ? (
					uncs.map((unc, i) => (
						<div
							style={{ gridTemplateColumns: gridTemplateColumns }}
							className={cn(styles.rowItem, styles.grid)}
							key={i}
						>
							{cellData(getNumberUnc, handleShowComment).map((cell: any, index: number) => (
								<Tooltip
									key={index}
									title={cell.tooltip ? cell.tooltip : cell.value(unc)}
									enterDelay={500}
									enterNextDelay={500}
									placement='top'
								>
									<span className={cn(styles.tableCell, styles[cell.textAlign])}>{cell.value(unc, i)}</span>
								</Tooltip>
							))}
						</div>
					))
				) : (
					<div className={styles.nothing}>Нет данных</div>
				)}
			</Collapse>
		</>
	)
}

export { Row }
