import React, { useEffect, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import '@root-gipro/shared/Filter/Filter/Filter.scss'
import { FilterProps } from '@root-gipro/shared/Filter/Filter/filter.props'
import { getNoun, lightPretty } from '@root-gipro/utils/helpers/common'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import '@root-gipro/shared/Filter/Filter/Filter.scss'

const Filter: React.FC<FilterProps> = ({ resetFilterHandler, showFilterResult, resultCnt, children }) => {
	const routeMatch = useRouteMatch()
	const url = routeMatch.path.slice(1)
	let resultName = ''

	const handleFilterResult = useCallback(() => {
		showFilterResult()
	}, [resultCnt])
	resultName = Number(resultCnt)
		? `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, ['рeзультат', 'результата', 'результатов'])}`
		: 'Нет результата'

	useEffect(() => {
		resultName = Number(resultCnt)
			? `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, ['рeзультат', 'результата', 'результатов'])}`
			: 'Нет результата'
	}, [resultCnt])

	return (
		<aside data-filter-animate className='aside-filter'>
			<form className='aside-filter__form'>
				<div className='aside-filter__form-head'>
					<h2 className='aside-filter__title'>Фильтр</h2>
					<button type='reset' className='aside-filter__reset link link-blue' onClick={resetFilterHandler}>
						Сбросить
					</button>
				</div>

				{Object.values(children!).filter(item => {
					const { tagsFilter } = item.props
					return tagsFilter.find((tag: string) => {
						if (tag === url) return item
					})
				})}
			</form>
			<div className='aside-filter__result'>
				<PrimaryBtn fullWidth type='submit' onClick={handleFilterResult}>
					{resultName}
				</PrimaryBtn>
			</div>
		</aside>
	)
}

export default Filter
