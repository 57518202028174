export const list = [
	{ name: 'Проектирование', trigger: 'design' },
	{ name: 'Общие', trigger: 'general' },
	{ name: 'Оборудование', trigger: 'equipment' },
	{ name: 'Проверка', trigger: 'check' },
	{
		name: 'Сводный отчет',
		trigger: 'consolidatedReport',
		downloadData: { name: 'Строительство', order: false, sortName: 'valuationOfferForecast' },
	},
]

export const subList = {
	check: [
		{
			name: 'МРСК Центра и Приволжья',
			link: 'http://portal.gi-pro.ru/files/04_check/01_mrsk_center_and_privolgya.pdf',
		},
		{
			name: 'МРСК Центра',
			link: 'http://portal.gi-pro.ru/files/04_check/02_mrsk_center.pdf',
		},
		{
			name: 'Россети Московский регион',
			link: 'http://portal.gi-pro.ru/files/04_check/03_rosseti.pdf',
		},
	],
	equipment: [
		{
			name: 'Дизель-генераторы',
			link: 'http://portal.gi-pro.ru/files/03_equipment/01_disel_gen.pdf',
		},
		{
			name: 'Безопасность',
			link: 'http://portal.gi-pro.ru/files/03_equipment/02_safety.pdf',
		},
		{
			name: 'Оборудование связи',
			link: 'http://portal.gi-pro.ru/files/03_equipment/03_equpment_connect.pdf',
		},
		{
			name: 'Кабельные линии',
			link: 'http://portal.gi-pro.ru/files/03_equipment/04_kl.pdf',
		},
		{
			name: 'Линейная арматура',
			link: 'http://portal.gi-pro.ru/files/03_equipment/05_linear_fittings.pdf',
		},
		{
			name: 'Транформаторные подстанции',
			link: 'http://portal.gi-pro.ru/files/03_equipment/06_tp.pdf',
		},
	],
	design: [
		{
			name: 'Проектно-изыскательские работы',
			link: 'http://portal.gi-pro.ru/files/01_design/01_pir.pdf',
		},
	],
	general: [
		{
			name: 'Строительство и КТПиР',
			link: ' http://portal.gi-pro.ru/files/02_common/01_construction_and_ktpir.pdf',
		},
		{
			name: 'Закупки',
			link: 'http://portal.gi-pro.ru/files/02_common/02_procurement.pdf',
		},
		{
			name: 'Информационные технологии',
			link: 'http://portal.gi-pro.ru/files/02_common/03_it.pdf',
		},
		{
			name: 'Статистика',
			link: 'http://portal.gi-pro.ru/files/02_common/04_statistic.pdf',
		},
		{
			name: 'Подрядчики',
			link: 'http://portal.gi-pro.ru/files/02_common/05_contractors.pdf',
		},
		{
			name: 'Новые объекты',
			link: 'http://portal.gi-pro.ru/files/02_common/06_new_objects.pdf',
		},
	],
}
