import React, { ReactText, useContext, useCallback, MouseEvent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import CardCommonProject from '@root-gipro/modules/commonProjects/components/CardProject/CardCommonProject'
import {
	actionFavoriteCommonProjects,
	appendCommonProjects,
	getSelectCommonProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { ModalContext } from '@root-gipro/context/modal-context'
import Loader from '@root-gipro/shared/Loader/Loader'
import ReportLoader from '@root-gipro/shared/Loader/ReportLoader'
import { CostWorksModalContainer } from '@root-gipro/modules/commonProjects/components/CostWorks/CostWorksModalContainer'
import { ICommonProject, ICommonProjectsListProps, IRegion } from '@root-gipro/store/interfaces'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import { useHistory, useLocation } from 'react-router-dom'
import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

const CommonProjectsList: React.FC<ICommonProjectsListProps> = ({ projects, toggleType }) => {
	const { groups, depGroups, regions } = useSelector((state: IState) => state.public)
	const { anchorEl, setAnchorEl, setAccessory } = useContext(ModalContext)
	let { isFavorites, loading, paginationCounter, reporting } = useSelector((state: IState) => state.commonProjects)

	const dispatch = useDispatch()
	const history = useHistory()

	const popupContent = {
		shrtTxt: 'Рассчитать стоимость:',
		items: [
			{
				step: StepType.DESIGN_WORK,
				itemTxt: 'Проектных работ',
			},
			{
				step: StepType.BUILD_WORK,
				itemTxt: 'Строительных работ',
			},
		],
	}
	const handleMenuItemClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			setAnchorEl!(event.currentTarget)
			setAccessory!(event.currentTarget.dataset.step!)
		},
		[setAnchorEl]
	)
	const getDepGroup = (item: ICommonProject) => {
		const depGroupIdx = searchByKey(depGroups, 'id', item.depGroupId)
		return depGroups[depGroupIdx!]
	}
	const getRegionProject = (firstRegionId: string) => {
		let regionIdx = regions.findIndex((region: IRegion) => region.id === firstRegionId)
		return regions[regionIdx]?.name
	}

	const getProjectById = useCallback(
		(id: ReactText) => {
			dispatch(getSelectCommonProject(id))
			history.push({
				pathname: `/common-projects#${id}`,
			})
		},
		[history]
	)
	const handlerFavoritesActions = useCallback((projectId: string, action: 'DELETE' | 'POST', project: any) => {
		dispatch(actionFavoriteCommonProjects(projectId, action, project))
	}, [])

	const onScroll = (e: any) => {
		let scrollTop = e.target.scrollTop
		let scrollHeight = e.target.scrollHeight
		const height = e.target.getBoundingClientRect().height

		// if (scrollTop && scrollHeight - height <= scrollTop && !loading && !isFavorites) {
		if (!loading && !isFavorites && scrollTop && scrollHeight - scrollTop <= height+10) {

			dispatch(appendCommonProjects(++paginationCounter))
		}
	}

	return (
		<>
			<div>{reporting && <ReportLoader />}</div>
			<ScrollHandler />
			<div className='project__list' onScroll={onScroll}>
				{projects &&
					projects.map((item: ICommonProject) => (
						<CardCommonProject
							key={item.id}
							toggleType={toggleType}
							project={item}
							groups={groups}
							depGroup={getDepGroup(item)}
							region={getRegionProject(item.firstRegionId)}
							classIcon='common-project-icon'
							getProjectById={getProjectById}
							handlerFavoritesActions={handlerFavoritesActions}
						/>
					))}
				<div>{loading && <Loader />}</div>
			</div>
			{(anchorEl?.dataset.step === StepType.DESIGN_WORK || anchorEl?.dataset.step === StepType.BUILD_WORK) && (
				<CostWorksModalContainer />
			)}
			{anchorEl?.dataset.popup === 'costWorks' && (
				<DropDown
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					classes='cost-works'
					style={{ width: 180 }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<>
						<p className='drop-down-shrt-txt'>{popupContent.shrtTxt}</p>
						{popupContent.items.map(item => (
							<div key={item.step} className='drop-down-item' data-step={item.step} onClick={handleMenuItemClick}>
								{item.itemTxt}
							</div>
						))}
					</>
				</DropDown>
			)}
		</>
	)
}

export default React.memo(CommonProjectsList)
