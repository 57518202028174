import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Avatar, MenuItem } from '@material-ui/core'
import { IHeaderAvatarProps } from '@root-gipro/store/interfaces/header'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import styles from '@root-gipro/modules/header/styles/HeaderAvatar.module.scss'
import { Routes } from '@root-gipro/router'

const HeaderAvatar: React.FC<IHeaderAvatarProps> = ({ logout }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget)
	}

	return (
		<div className={styles.avatar}>
			<Avatar src={'/assets/images/svg/default-avatar.svg'} onClick={handleClick} />
			<DropDown
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				anchorOrigin={{
					vertical: 43,
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem>
					<NavLink className={styles.navLink} to={Routes.TARIFFS}>
						Тарифы
					</NavLink>
				</MenuItem>
				<MenuItem onClick={() => logout()}>Выйти</MenuItem>
			</DropDown>
		</div>
	)
}

export default HeaderAvatar
