import React, { useState, useEffect, MouseEvent, MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import Tooltip from '@material-ui/core/Tooltip'
import { IState } from '@root-gipro/store/interfaces/state'
import { ClickAwayListener } from '@material-ui/core'
import { IRegion } from '@root-gipro/store/interfaces'

interface RegionIds extends IRegion {
	okato: string
}
interface ISelectProps {
	defaultVal?: any
	list?: any
	keyName?: string
	placeholder?: string
	clickHandler?: (value: any) => void
	tooltip: string
	multy?: boolean
	changeHandler?: (value: any) => void
	wrapClass?: string
	getCostTable?(): void
	getTitulTable?(): void
	clearSearchVal?(): void
}

export const Select: React.FC<ISelectProps> = ({
	defaultVal,
	list = [],
	keyName = 'name',
	placeholder,
	clickHandler,
	tooltip,
	multy,
	changeHandler,
	wrapClass,
	clearSearchVal,
	getTitulTable,
	getCostTable,
}) => {
	const [opened, setOpened] = useState(false)
	const { activePannel } = useSelector((state: IState) => state.maket)
	const clickElemHandlerMulty = (value: RegionIds | string) => {
		let clone = defaultVal.slice()
		if (clone.indexOf(value) != -1) {
			clone.splice(clone.indexOf(value), 1)
		} else {
			clone.push(value)
		}
		if (changeHandler) changeHandler(clone)
		if (activePannel === 'projects' && getTitulTable) {
			getTitulTable()
		} else if (getCostTable) {
			getCostTable()
		}
	}

	const clickElemHandlerNoMulty = (value: RegionIds | string) => {
		if (changeHandler) changeHandler(value)
		if (activePannel === 'projects' && getTitulTable) {
			getTitulTable()
		} else if (getCostTable) {
			getCostTable()
		}
		setOpened(false)
	}
	const handleClickAway = () => {
		setOpened(false)
	}

	const handlerClick = (elem: RegionIds | string, event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
		if (clearSearchVal) clearSearchVal()
		if (multy) {
			clickElemHandlerMulty(elem)
		} else {
			clickElemHandlerNoMulty(elem)
		}
		if (clickHandler) clickHandler(elem)
	}

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Tooltip title={tooltip} enterDelay={1000} enterNextDelay={1000} placement='top'>
				<div
					onClick={e => setOpened(true)}
					className={`select ${multy && (defaultVal && defaultVal.length) > 0 && 'with_data'} ${wrapClass}`}
				>
					<div className='arrow'></div>
					<div className='selected_value'>
						{defaultVal || (defaultVal && defaultVal.length > 0)
							? multy
								? defaultVal.length > 1
									? `Несколько (${defaultVal.length})`
									: defaultVal.length == 1
									? defaultVal[0][keyName] || defaultVal[0]
									: placeholder
								: defaultVal[keyName] || defaultVal || placeholder
							: placeholder}
					</div>
					<div className={`select_list ${opened && 'opened_select'} ${multy && 'multy'}`}>
						<ScrollArea speed={0.8} smoothScrolling={true} style={{ height: '100%', paddingRight: '8px' }}>
							{list.map((elem: RegionIds, ind: number) => (
								<div
									className={`select_list_elem ${multy && defaultVal.includes(elem) ? 'checked' : ''}`}
									onClick={(event: MouseEvent<HTMLDivElement>) => handlerClick(elem, event)}
									key={`${elem}_${ind}`}
								>
									{elem.name || elem}
								</div>
							))}
						</ScrollArea>
					</div>
				</div>
			</Tooltip>
		</ClickAwayListener>
	)
}

// actions которые не обрабатывются.

// getCostTable: () => dispatch({ type: 'FETCH_COST_TABLE' }),
// 	getTitulTable: () => dispatch({ type: 'FETCH_TITUL_TABLE' }),
// 	clearSearchVal: () => {
// 		dispatch({ type: 'SET_TITUL_SEARCH_VAL', searchVal: '' })
// 		dispatch({ type: 'SET_COST_SEARCH_VAL', searchVal: '' })
// 	},
