import { types } from '@root-gipro/shared/FilterHeader/FilterHeader';
import { Payment, TariffPlans } from '@root-gipro/modules/tariffs/models/interfaces/tariffs-state.model'

export enum TariffsType {
	GET_TARIFFS_PLANS = 'GET_TARIFFS_PLANS',
	SET_TARIFFS_PLANS = 'SET_TARIFFS_PLANS',
	PAYMENT_CREATE = 'PAYMENT_CREATE',
	SELECT_TARIF = 'SELECT_TARIF'
}

interface paymentCreate {
	type:TariffsType.PAYMENT_CREATE
	data:Payment
}

interface getTariffPlans {
	type: TariffsType.GET_TARIFFS_PLANS
}

interface setTariffPlans {
	type: TariffsType.SET_TARIFFS_PLANS
	tariffPlans: TariffPlans
}

interface selectTarif {
	type: TariffsType.SELECT_TARIF
	id:string
	unlimit:boolean
}



export type TariffsTypes = getTariffPlans | setTariffPlans | paymentCreate |selectTarif
