import React, {
	useContext,
	useCallback,
	useMemo,
	useState,
	useRef,
	useEffect,
	MutableRefObject,
	LegacyRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import { intFormat } from '@root-gipro/utils/helpers/common'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { setActiveUncs, changeCounter, deleteUnc } from '@root-gipro/modules/userProjects/store/actions'
import Voltage from '@root-gipro/modules/userProjects/components/Project/Voltage'
import { IUnc, IRegion } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { ModalContext } from '@root-gipro/context/modal-context'
import { CellsProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { addActiveUnc } from '@root-gipro/modules/userProjects/utils/helpers'
import { Cell } from '@root-gipro/modules/userProjects/components/Project/UncTable/Сell'
import { WrapperCell } from '@root-gipro/modules/userProjects/components/Project/UncTable/WrapperCell'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { IconButton, Tooltip } from '@material-ui/core'
import { removeSpaceFromNumber } from '@root-gipro/modules/userProjects/utils/removeSpaceFromNumber'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import cn from 'classnames'
import { gridTemplateColumns } from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'

export const Cells: React.FC<CellsProps> = ({ voltageRef, unc, index, currentCellTags }) => {
	const [uncCount, setUncCount] = useState<number>(unc.count)
	const { activeUncs, loading } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)

	const { setAnchorEl, accessoryRef } = useContext(ModalContext)
	const dispatch = useDispatch()
	const rowRef = useRef<HTMLInputElement>(null)

	const trClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>, unc: IUnc) => {
			if (e.ctrlKey) {
				let { clone } = addActiveUnc(activeUncs, unc)
				dispatch(setActiveUncs(clone))
			} else {
				if (activeUncs.length === 1 && activeUncs[0].id === unc.id) {
					return
				} else {
					dispatch(setActiveUncs([unc]))
				}
			}
		},
		[activeUncs, setActiveUncs]
	)

	const regionTdClicHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation()
			if (e.ctrlKey) {
				let { clone } = addActiveUnc(activeUncs, unc)
				dispatch(setActiveUncs(clone))
			} else {
				if (!activeUncs.some(elem => elem.id === unc.id)) {
					dispatch(setActiveUncs([unc]))
				}
			}
			const { index } = addActiveUnc(activeUncs, unc)
			if (accessoryRef) accessoryRef.current = String(index)
			setAnchorEl!(e.currentTarget)
			if (rowRef.current) rowRef.current.focus()
		},
		[activeUncs, unc]
	)
	const commentTdClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation()
			if (e.ctrlKey) {
				let { clone } = addActiveUnc(activeUncs, unc)
				dispatch(setActiveUncs(clone))
			} else {
				if (!activeUncs.some(elem => elem.id === unc.id)) {
					dispatch(setActiveUncs([unc]))
				}
				const { index } = addActiveUnc(activeUncs, unc)
				if (accessoryRef) accessoryRef.current = String(index)
				setAnchorEl!(e.currentTarget)
				if (rowRef.current) rowRef.current.focus()
			}
		},
		[activeUncs, unc]
	)

	const region: IRegion | undefined = useMemo(() => {
		return regions.find(elem => +elem.id === +unc.regionId)
	}, [unc, regions, unc.regionId])

	useEffect(() => {
		setUncCount(unc.count)
	}, [unc])

	return (
		<Draggable index={index} draggableId={unc.id}>
			{(provided, snapshot) => (
				<div className={styles.rowWrapper} tabIndex={+unc.id} ref={rowRef}>
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						onClick={e => trClickHandler(e, unc)}
						key={unc.id + 1}
						className={cn(styles.rowItem, {
							[styles.activeRow]: activeUncs.some(active => active.id === unc.id),
						})}
					>
						<div
							className={cn(styles.grid, styles.alignTheElement)}
							style={{ gridTemplateColumns: gridTemplateColumns }}
						>
							<WrapperCell currentCellTags={currentCellTags}>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignRight)}>{index + 1}.</div>
								</Cell>

								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignLeft)}>{unc.uncCell.code}</div>
								</Cell>

								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<Tooltip
										key={unc.uncMain.name}
										title={unc.uncMain.name}
										enterDelay={500}
										enterNextDelay={500}
										placement='top'
									>
										<div className={cn(styles.tableCell, styles.textAlignLeft)}>{unc.uncMain.name}</div>
									</Tooltip>
								</Cell>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div
										data-popup='comment'
										onClick={commentTdClickHandler}
										style={{ cursor: 'pointer' }}
										className={cn(styles.tableCell, styles.textAlignLeft)}
									>
										<span className='comment-icon'></span>
									</div>
								</Cell>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignLeft)}>
										<input
											onChange={e => {
												setUncCount(intFormat(e.currentTarget.value))
											}}
											onBlur={e => {
												dispatch(changeCounter(unc.id, removeSpaceFromNumber(e.currentTarget.value)))
											}}
											className={styles.uncCounter}
											value={uncCount ? intFormat(uncCount) : 0}
										/>
									</div>
								</Cell>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignRight)}>{unc.uncMain.unit} </div>
								</Cell>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignLeft)}>{unc.uncCell.parameter}</div>
								</Cell>
								<Cell cellTags={[cellTags[0], cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignLeft, styles.accent)}>
										<Voltage voltageRef={voltageRef} dataAttr='volt-unc-table' unc={unc} />
									</div>
								</Cell>

								<Cell cellTags={[cellTags[0]]}>
									<div className={cn(styles.tableCell, styles.textAlignLeft)}>{prettyCost(unc.cost / 1000)}</div>
								</Cell>
								<Cell cellTags={[cellTags[0]]}>
									<div
										data-popup='region'
										data-region={unc.regionId}
										onClick={regionTdClicHandler}
										className={cn(styles.tableCell, styles.textAlignCenter, styles.regionName)}
									>
										<span>{unc.regionFactor?.value ? unc.regionFactor.value : '1.00'}</span>

										<span className={cn(styles.accent, 'ml-4')}>{region?.name}</span>
									</div>
								</Cell>
								<Cell cellTags={[cellTags[0]]}>
									<div className={cn(styles.tableCell, styles.textAlignRight)}>{prettyCost(unc.totalCost / 1000)}</div>
								</Cell>
								<Cell cellTags={[cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignRight)}>{prettyCost(unc.cost / 1000)}</div>
								</Cell>
								<Cell cellTags={[cellTags[1]]}>
									<div className={cn(styles.tableCell, styles.textAlignRight)}>{prettyCost(unc.totalCost / 1000)}</div>
								</Cell>
							</WrapperCell>
						</div>
					</div>
					<IconButton
						className={styles.delBtn}
						disabled={loading}
						onClick={e => {
							e.stopPropagation()
							dispatch(deleteUnc(unc.id))
						}}
					>
						<div className='del-icon'></div>
					</IconButton>
				</div>
			)}
		</Draggable>
	)
}
