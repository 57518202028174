import { giproApp } from '@root-gipro/store/api'
import { IPurchasesList } from '@root-gipro/modules/purchases/models/interfaces/purchases-list-request'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'
import { handleAccessError } from '@root-gipro/utils/helpers/handleAccessError'

export const getPurchasesList = (): Promise<IPurchasesList> => {
	return giproApp({
		method: 'GET',
		url: `/purchase-search`,
		params: {
			limit: 5,
			with: ['resultsCnt', 'initialSumSum', 'maximumPriceSum', 'purchases', 'uncInfo', 'projects','linkedProjectIDs'],
		},
	})
		.then((res: any) => res.data)
		.catch((error: string) => error)
}

export const getAllFavoritePurchasesApi = () => {
	return	giproApp({
		method: 'GET',
		url: `/purchase-search?favoritesOnly=1`,
		params: { 
			with: ['resultsCnt', 'initialSumSum', 'maximumPriceSum', 'purchases', 'uncInfo', 'projects','linkedProjectIDs'],
		},
	})
		.then(result => result.data)
		// .then(data => (Object.values(data)))
		.catch(error=> {
			const { data } = error.response 
			handlerErrors(data)
		})
}

export const getPurchasesListByParams = (params: IParams): Promise<IPurchasesList> => {
	return giproApp({
		method: 'GET',
		url: `/purchase-search`,
		params,
	})
		.then((res: any) => res.data)
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

export const getPurchaseDetailApi = (purchaseType: PurchasesTypeEnum, id: number | string) => {
	let url: string
	let params = {}
	switch (purchaseType) {
		case PurchasesTypeEnum.Notice:
			url = 'purchase-notices'
			params = {
				with: ['projects', 'plans'],
			}
			break
		case PurchasesTypeEnum.Plan:
			url = 'purchase-plans'
			params = {
				with: ['projects'],
			}
			break
		default:
			// todo notificate
			return
	}
	return giproApp({
		method: 'GET',
		url: `/${url}/${id}`,
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.then(res => res?.purchaseData)
		.catch((error: string) => error)
}

export const getPurchaseMethods = () => {
	return giproApp({
		method: 'GET',
		url: `/purchase-plans/values/method`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res?.data?.values
		})
		.catch((error: string) => error)
}

export const getPurchaseStages = () => {
	return giproApp({
		method: 'GET',
		url: `/purchase-plans/values/stage`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res?.data?.values
		})
		.catch((error: string) => error)
}
