import React, { useEffect, useState } from 'react'
import uniqueId from 'lodash/uniqueId'
import {
	formatDate,
	formatDateLong,
	prettyCost,
	hasUndefinedKeys,
	currentServer,
} from '@root-gipro/utils/helpers/common'
import { ICardProject, IGroup, IState } from '@root-gipro/store/interfaces'
import { Routes } from '@root-gipro/router'
import {
	Card,
	CardHeaderTitle,
	CardLeft,
	CardRight,
	CardCollapse,
	CardDetailedInfoItem,
	CardDetailedInfo,
	CardItem,
} from '@root-gipro/shared/Card'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import {
	BlockPlanWithOfferDiv,
	CardButtonPurchase,
	CardLeftStyle,
	CardRightStyle,
	Icon,
	IconArrowStyle,
	OfferDiv,
	PlanDiv,
	TopBlockPurchase,
	WrapperCardStyle,
	WrapperInfoBlock,
	WrapperPurchase,
} from './styles'
import { fetchData } from '@root-gipro/store/api'
import { nanoid } from 'nanoid'
import { LingSVG } from '@root-gipro/shared/SVGComponents/LinkSVG'
import { StarSVG } from '@root-gipro/shared/SVGComponents/StarSVG'
import { Link, useHistory } from 'react-router-dom'
import { getPurchaseDetailRoute } from '@root-gipro/modules/purchases/utils/getPurchaseDetailRoute'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { ProjectType } from '@root-gipro/modules/favorites/models/enums/projects.enum'

type Purchase = {
	id: number
	subject: string
	maximumPrice?: number
	initialSum?: number
	submissionCloseDate: string | Date
	loadedJsonId: string
	registrationNumber: number
	url: string
	url_public?: string
	model?: string
	isFavorite: boolean
}

const CardProject: React.FC<ICardProject> = ({
	project,
	classIcon,
	region,
	toggleType,
	getProjectById,
	depGroup,
	groups,
	handlerFavoritesActions,
}) => {
	const [details, setDetails] = useState<boolean>(false)
	const [listPurchases, setListPurchases] = useState<Purchase[]>([])
	const [showPurchases, setShowPurchases] = useState<boolean>(false)
	const [switchFavorite, setSwitchFavorite] = useState<boolean>(false)
	const [showAlert, setShowAlert] = useState(false)
	const history = useHistory()
	const routePath = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, project.id)
	const date = `Изменено ${formatDate(project.publicationDate)}`
	const role = Number(window.localStorage.getItem('roleId'))
	const expandCardHandler = () => {
		setDetails(!details)
	}

	const cardRightItems = [
		{
			title: 'Оценка полной стоимости в прогн. ценах (с НДС)',

			value:
				project.valuationPlanForecast || project.valuationOfferForecast
					? toggleType === 'plan'
						? addHTML(prettyCost(project.valuationPlanForecast / 10 ** 6))
						: addHTML(prettyCost(project.valuationOfferForecast / 10 ** 6))
					: '0.00',
		},
		{
			title: 'Публикация на сайте Минэнерго',
			value: `${formatDate(project.publicationDate)}`,
		},
		{
			title: 'Полная сметная стоимость согласно ПСД (с НДС)',
			value:
				project.estimatedPlanCurrent || project.estimatedOfferCurrent
					? toggleType === 'plan'
						? addHTML(prettyCost(project.estimatedPlanCurrent / 10 ** 6))
						: addHTML(prettyCost(project.estimatedOfferCurrent / 10 ** 6))
					: '0.00',
		},
	]

	const collapseItemsLeftColumn = [
		{
			type: 'icon',
			title: 'Филиал ДЗО',
			icon: 'dzo-icon',
			value: depGroup?.name,
		},
		{
			type: 'icon',
			title: 'Период реализации',
			icon: 'clock-icon',
			value: toggleType === 'plan' ? project.yearEndPlan : project.yearEndOffer,
		},
		{
			type: 'icon',
			title: 'Регион поставки',
			icon: 'mark-icon',
			value: region,
		},
		{
			type: 'icon',
			title: 'Составление сметной документации',
			icon: 'clock-icon',
			value: formatDateLong(toggleType === 'plan' ? project.estimatedPlanYM : project.estimatedOfferYM),
		},
	]

	const collapseItemsRightColumn = [
		{
			type: 'text',
			title: 'Оценка полной стоимости по УНЦ в прогнозных ценах (с НДС)',
			value:
				toggleType === 'plan'
					? addHTML(prettyCost(project.valuationUncPlanForecast / 10 ** 6))
					: addHTML(prettyCost(project.valuationUncOfferForecast / 10 ** 6)),
		},
		{
			type: 'text',
			title: 'Остаток финансирования в прогнозных ценах (с НДС)',
			value:
				toggleType === 'plan'
					? addHTML(prettyCost(project.totalInvestmentPlan / 10 ** 6))
					: addHTML(prettyCost(project.totalInvestmentOffer / 10 ** 6)),
		},
	]
	useEffect(() => {
		let isMounted = true
		fetchData(`/projects/${project.id}/linkedPurchases`, (res: unknown) => res)
			.then(purchases => {
				if (isMounted) {
					if (purchases?.notices) {
						setListPurchases(() => {
							return [...purchases?.notices]
						})
					}
					if (purchases?.plans) {
						setListPurchases(() => {
							return [...purchases.plans]
						})
					}
				}
			})
			.catch(error => console.warn(error.message))

		return () => {
			isMounted = false
		}
	}, [switchFavorite])

	return (
		<Card projectId={project.id} key={project.id}>
			<CardLeft>
				<CardHeaderTitle
					path={routePath}
					commonProjectId={project.id}
					title={project.code}
					isFavorite={!!+project.isFavorite}
					iconType={classIcon}
					isExpanded={details}
					getProjectById={getProjectById}
					expandCardHandler={expandCardHandler}
					handlerFavoritesActions={handlerFavoritesActions}
				>
					<CardDate>{date}</CardDate>
					<CardTitle>{groups.find((group: IGroup) => group.id === project.groupId)?.name || ''}</CardTitle>

					<CardDescription>{project.name}</CardDescription>

					{listPurchases.length > 0 && (
						<CardButtonPurchase>
							Закупок: {listPurchases.length}
							{listPurchases.length > 0 && (
								<IconArrowStyle showPurchases={showPurchases} onClick={() => setShowPurchases(!showPurchases)} />
							)}
						</CardButtonPurchase>
					)}
				</CardHeaderTitle>
			</CardLeft>
			<CardRight>
				{cardRightItems.map(
					item => item.value && <CardItem title={item.title} value={item.value} key={uniqueId('CardItem_')} />
				)}
			</CardRight>

			<WrapperPurchase in={showPurchases} unmountOnExit showPurchases={showPurchases}>
				{listPurchases.map((purchase, i) => {
					return (
						<WrapperCardStyle key={nanoid()}>
							<TopBlockPurchase>
								<Icon />
								<Link
									style={{ textDecoration: 'none' }}
									to={{
										pathname: getPurchaseDetailRoute(purchase.model as PurchasesTypeEnum, purchase.id),
										state: {
											from: history.location.pathname,
											fromName: 'Закупки',
										},
									}}
								>
									<span>{purchase.registrationNumber}</span>
								</Link>
								{
									<div
										onClick={() => {
											if (purchase.model === 'notice') {
												window.open(
													`https://zakupki.gov.ru/223/purchase/public/purchase/info/common-info.html?regNumber=${purchase.registrationNumber}`
												)
											} else if (purchase.model === 'plan') {
												let id = purchase.url.split('planInfoId=')[1]
												window.open(
													`https://zakupki.gov.ru/epz/orderplan/purchase-plan/card/position-info.html?id=0&infoId=${id}`
												)
											}
										}}
									>
										<LingSVG />
									</div>
								}

								{
									<div
										className={`${purchase.isFavorite ? 'icon-wrapper-favorite' : 'icon-wrapper'}`}
										data-popup='costWorks'
										onClick={() => {
											if (role && role == 999) {
												setShowAlert(true)
											} else {
												setSwitchFavorite(!switchFavorite)
												if (purchase.isFavorite) {
													handlerFavoritesActions(purchase.id + '', 'DELETE', 'PURCHASES')
												} else {
													handlerFavoritesActions(purchase.id + '', 'POST', 'PURCHASES')
												}
											}
										}}
									>
										<StarSVG classes='cost-works-icon' chosen={purchase.isFavorite} />
									</div>
								}
							</TopBlockPurchase>
							<WrapperInfoBlock>
								<CardLeftStyle>{purchase.subject}</CardLeftStyle>
								<CardRightStyle>
									{purchase?.initialSum && (
										<CardItem
											key={uniqueId('CardTextItem')}
											title='НМЦ контракта (с НДС)'
											value={addHTML(+(purchase.initialSum / 10 ** 6).toFixed(2))}
										/>
									)}
									{purchase?.maximumPrice && (
										<CardItem
											key={uniqueId('CardTextItem')}
											title='НМЦ контракта (с НДС)'
											value={addHTML(+(purchase.maximumPrice / 10 ** 6).toFixed(2))}
										/>
									)}
									{purchase.submissionCloseDate && (
										<CardItem
											key={uniqueId('CardTextItem')}
											title='Дата оконч. приема заявок'
											value={formatDate(purchase.submissionCloseDate)}
										/>
									)}
								</CardRightStyle>
							</WrapperInfoBlock>
						</WrapperCardStyle>
					)
				})}
			</WrapperPurchase>

			<CardCollapse isExpanded={details}>
				<CardDetailedInfo fullBlock={false}>
					{collapseItemsLeftColumn
						.filter(item => !hasUndefinedKeys(item) && item.value)
						.map((item, i) => (
							<CardDetailedInfoItem key={project.id + i} title={item.title} value={item.value} icon={item.icon} />
						))}
				</CardDetailedInfo>

				<div className='card-container__detailed-price'>
					{collapseItemsRightColumn
						.filter(item => !hasUndefinedKeys(item) && item.value)
						.map(({ title, value }) => (
							<CardItem key={uniqueId('CardTextItem')} title={title} value={value} />
						))}
				</div>
			</CardCollapse>
		</Card>
	)
}

export default React.memo(CardProject)
