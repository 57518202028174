import React from 'react'
import { ISelectTableTheadProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

export const SelectTableThead: React.FC<ISelectTableTheadProps> = ({ uncTableData }) => {
	return (
		<thead>
			{uncTableData &&
				uncTableData.header &&
				uncTableData.header?.map((elem: any, ind: number) => {
					return (
						<tr key={ind}>
							{Object.values(elem).map((td: any) => {
								if (td.class == 'invis') return null
								return (
									<th key={`${td.x}_${td.y}`} colSpan={td.col ? td.col : null} rowSpan={td.row ? td.row : null}>
										{td.name}
									</th>
								)
							})}
						</tr>
					)
				})}
		</thead>
	)
}
