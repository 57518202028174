export type BodyType = {
	title: string
	tooltip: string
	textAlign: string
}

export const gridTemplateColumns = '36px 64px 384px 40px 80px 96px 164px 72px 104px 112px 96px'
export const theadData = [
	{
		title: '№',
		tooltip: 'Номер',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Расценка',
		tooltip: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Техническое решение',
		tooltip: 'Техническое решение',
		textAlign: 'textAlignLeft',
	},
	{
		title: '',
		tooltip: 'Примечание',
		textAlign: 'textAlignCenter',
	},
	{
		title: 'Кол-во',
		tooltip: 'Кол-во',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Ед.изм.',
		tooltip: 'Ед.измерения',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Параметры',
		tooltip: 'Параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		tooltip: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Укр. норматив цены',
		tooltip: 'Укр. норматив цены',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Региональный коэф.',
		tooltip: 'Региональный коэф.',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Величина затрат',
		tooltip: 'Величина затрат',
		textAlign: 'textAlignLeft',
	},
]
export const gridTemplateColumnsAreaCalculation = '36px 64px 416px 40px 48px 96px 164px 72px 156px 156px'

export const theadCAreaCalculation = [
	{
		title: '№',
		tooltip: 'Номер',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Расценка',
		tooltip: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Технические решение',
		tooltip: 'Технические решение',
		textAlign: 'textAlignLeft',
	},
	{
		title: '',
		tooltip: 'Примечание',
		textAlign: 'textAlignCenter',
	},
	{
		title: 'Кол-во',
		tooltip: 'Кол-во',
		textAlign: 'textAlignLeft',
	},

	{
		title: 'Ед.изм.',
		tooltip: 'Ед.изм.',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Параметры',
		tooltip: 'Параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		tooltip: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Площадь, м²',
		tooltip: 'Площадь, м²',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Общая площадь, м²',
		tooltip: 'Общая площадь, м²',
		textAlign: 'textAlignLeft',
	},
]
