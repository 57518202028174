import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { CirclePlusSVG } from '@root-gipro/shared/SVGComponents/CirclePlusSVG'
import {
	downloadRulesCheck,
	getProjects,
	setIsNewProject,
	setProjectUncs,
	uploaderRulesCheck,
} from '@root-gipro/modules/userProjects/store/actions'
import { createProjectTemplate } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { prettyCost } from '@root-gipro/utils/helpers/common'

const UserProjectsNav: React.FC = () => {
	const { totalInvestmentUncOfferSum, userProjectsCnt, uncs } = useSelector((state: IState) => state.userProjects)
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		dispatch(getProjects())
		dispatch(setIsNewProject(false))
		if (uncs.length > 0) {
			dispatch(setProjectUncs([]))
		}
	}, [])
	return (
		<div className='user-projects__nav'>
			<SecondaryBtn
				className='add_btn'
				onClick={() => {
					dispatch(createProjectTemplate())
					history.push('user-projects/project/0/parameters')
				}}
			>
				<CirclePlusSVG />
				Добавить
			</SecondaryBtn>
			{/* <div style={{ display: 'flex', alignItems: 'center' }}>
				Правила проверки:&nbsp;
				<button style={{ color: 'black', margin: '0 8px' }} onClick={() => dispatch(downloadRulesCheck())}>
					Скачать
				</button>
				<label style={{ margin: '0 8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					Загрузить
					<input
						type='file'
						style={{ margin: '0 8px', display: 'none' }}
						accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						onChange={e => dispatch(uploaderRulesCheck(e.target.files))}
					/>
				</label>
			</div> */}

			<div className='user-projects__options'>
				<div className='user-projects__options-item'>
					<span className='user-projects__options-shrt-txt'>Всего: </span>
					<span className='user-projects__options-count'>{userProjectsCnt}</span>
				</div>
				<div className='user-projects__options-item'>
					<span className='user-projects__options-sum'>{prettyCost(totalInvestmentUncOfferSum / 10 ** 6)} млн.р</span>
				</div>
			</div>
		</div>
	)
}
export { UserProjectsNav }
