import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { fetchData } from '@root-gipro/store/api'
import { PublicType, setPurchasesMethods, setPurchasesStages } from '../actions/public'
import { getPurchaseMethods, getPurchaseStages } from '@root-gipro/modules/purchases/api/purchases'

function* fetchPublicInfo() {
	const {
		policesGipro,
		policesRGCO,
		groups,
		regions,
		uncMains,
		depGroups,
		publicationDates,
		publicationDatesPlans,
		uncSections,
		uncThs,
		uncThDescriptions,
		rgStatuses,
	} = yield all({
		policesGipro: call(
			fetchData,
			'/policies?action=.userProject&kind=self',
			(res: any) => res.policies['.userProject']['self']
		),
		policesRGCO: call(
			fetchData,
			'/policies?action=rgco.ed.create&kind=all',
			(res: any) => res.policies['rgco.ed.create']['all']
		),
		groups: call(fetchData, '/groups', (res: any) => res.groups),
		regions: call(fetchData, '/regions', (res: any) => res.regions),
		uncMains: call(fetchData, '/uncMains', (res: any) => res.uncMains),
		depGroups: call(fetchData, '/dep-groups', (res: any) => res.depGroups),
		publicationDates: call(fetchData, '/projects/values/publicationDate', (res: any) => res.values),
		publicationDatesPlans: call(fetchData, '/purchase-plans/values/publicationDate', (res: any) => res.values),
		uncSections: call(fetchData, '/unc-sections', (res: any) => res.uncSections),
		uncThs: call(fetchData, '/info/unc-ths', (res: any) => res.uncThs),
		uncThDescriptions: call(fetchData, '/info/unc-text-parameters', (res: any) => res.uncTextParameters),
		rgStatuses: call(fetchData, '/rg-statuses', (res: any) => res.rgStatuses),
	})

	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		policesGipro,
		policesRGCO,
		groups,
		regions,
		uncMains,
		depGroups,
		publicationDates,
		publicationDatesPlans,
		uncSections,
		uncThs,
		uncThDescriptions,
		rgStatuses,
	})
}
function* getPurchasesMethods() {
	try {
		const result: string[] = yield call(getPurchaseMethods)
		if (result) yield put(setPurchasesMethods(result))
	} catch (error) {}
}

function* getPurchasesStages() {
	try {
		const result: string[] = yield call(getPurchaseStages)
		if (result) yield put(setPurchasesStages(result))
	} catch (error) {}
}

export default function* watchFetchPublicInfo() {
	yield takeLatest(PublicType.FETCH_PUBLIC_INFO, fetchPublicInfo)
	yield takeEvery(PublicType.GET_PURCHASES_METHODS, getPurchasesMethods)
	yield takeEvery(PublicType.GET_PURCHASES_STAGES, getPurchasesStages)
}
