import React from 'react'

const UsersIcon = () => {
    return (
        <svg
            height="16"
            viewBox="0 0 24 24"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                fill="none"
                stroke="#878787"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <circle
                cx="9"
                cy="7"
                fill="none"
                r="4"
                stroke="#878787"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                d="M23 21v-2a4 4 0 0 0-3-3.87"
                fill="none"
                stroke="#878787"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                d="M16 3.13a4 4 0 0 1 0 7.75"
                fill="none"
                stroke="#878787"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    )
}

export default React.memo(UsersIcon)
