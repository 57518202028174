import styled from "styled-components";

export const WrapperInformation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 56px;`

export const Text = styled.div`
    text-align: center;
    margin-bottom: 24px;
`

export const TextLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #3F4F5E;
    height: 32px;
    display: flex;
    padding: 0 8px;
    font-size: 13px;
    min-width: 100px;
    font-style: normal;
    align-items: flex-start;
    font-weight: normal;
    line-height: 16px;
    border-radius: 4px;
    flex-direction: column;
    text-transform: inherit;
    background-color: #3f4f5e;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: white;
        color: #3F4F5E;
    }
`