import React, { FC, useMemo } from 'react'
import { Tbody } from '@root-gipro/modules/userProjects/components/Project/UncTable/Tbody'
import { theadData, gridTemplateColumns } from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'
import { IUnc } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import { Thead } from '@root-gipro/shared/Table/components/Thead'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'

interface TableContainerProps {
	uncs: IUnc[]
}

const TableContainer: FC<TableContainerProps> = ({ uncs }) => {
	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [uncs])

	return (
		<>
			<div className={styles.container}>
				<div className={styles.table}>
					<Thead {...theadParams} />
					<Tbody uncs={uncs} />
				</div>
			</div>
		</>
	)
}

export { TableContainer }
