import React from 'react'
import {
    IFilterInputRangeProps
} from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs'

const FilterInputRange: React.FC<IFilterInputRangeProps> = ({ onMinValueChange, onMaxValueChange, defaultMinValue, defaultMaxValue }) => {
        return (
        <div className="filter__input-range">
            <input
                type="text"
                placeholder="c"
                value={defaultMinValue}
                onChange={(e) => onMinValueChange ? onMinValueChange(e.currentTarget.value) : null }
            />
            <input
                type="text"
                placeholder="по"
                value={defaultMaxValue}
                onChange={(e) => onMaxValueChange ? onMaxValueChange(e.currentTarget.value) : null }
            />
        </div>
    )
}

export default React.memo(FilterInputRange)
