import { ReactText } from 'styled-jsx/node_modules/@types/react'
import { ProjectType } from '../models/enums/projects.enum'
import { FavoritesType } from '../models/interfaces/favorites.actions'

const sortFavoritesProjects = (name: string, sortName: string, order: boolean | undefined) => ({
	name,
	sortName,
	order,
	type: FavoritesType.SORT_FAVORITES_PROJECTS,
})
const sortFavoritesPurchases = (name: string, sortName: string, order: boolean | undefined) => ({
	name,
	sortName,
	order,
	type: FavoritesType.SORT_FAVORITES_PURCHASES,
})

const setFavoritesProjects = (projects: any, resultsCnt: number, priceSum: number) => ({
	type: FavoritesType.SET_FAVORITES_PROJECTS,
	projects,
	resultsCnt,
	priceSum,
})

const getFavoritesProjects = (toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES) => ({
	type: FavoritesType.GET_FAVORITES_PROJECTS,
	toggleType,
})
const getFavoritesProjectsSetPurchases = (toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES) => ({
	type: FavoritesType.GET_FAVORITES_PROJECTS_SET_PURCHASES,
	toggleType,
})

const deleteFavoritesProjects = (
	projectId: string | string[],
	project: ProjectType.PROJECTS | ProjectType.PURCHASES
) => ({
	type: FavoritesType.DELETE_FAVORITES_PROJECTS,
	projectId,
	project,
})

const editToggleType = (toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES) => ({
	type: FavoritesType.EDIT_TOGGLE_TYPE_FAVORITES,
	toggleType,
})
const setLoadingFavorites = (loading: boolean) => ({
	type: FavoritesType.SET_LOADING_FAVORITES,
	loading,
})

const getCountPurchases = (id: ReactText) => ({
	type: FavoritesType.GET_COUNT_PURCHASES,
	id,
})

const setFavoritesProjectsCount = (projectsCount: number) => ({
	type: FavoritesType.SET_FAVORITES_PROJECTS_COUNT,
	projectsCount,
})
const setFavoritesProjectsSum = (valuationOfferForecastSum: number, valuationPlanForecastSum: number) => ({
	type: FavoritesType.SET_FAVORITES_PROJECTS_SUM,
	valuationOfferForecastSum,
	valuationPlanForecastSum,
})
const setFavoritesPurchasesCount = (projectsCount: number) => ({
	type: FavoritesType.SET_FAVORITES_PURCHSES_COUNT,
	projectsCount,
})
const setFavoritesPurchasesSum = (maximumPriceSum: number) => ({
	type: FavoritesType.SET_FAVORITES_PURCHSES_SUM,
	maximumPriceSum,
})

export {
	getCountPurchases,
	editToggleType,
	setLoadingFavorites,
	setFavoritesProjects,
	getFavoritesProjects,
	deleteFavoritesProjects,
	sortFavoritesProjects,
	sortFavoritesPurchases,
	getFavoritesProjectsSetPurchases,
	setFavoritesProjectsCount,
	setFavoritesProjectsSum,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
}
