import { ReactText } from 'react'
import {
	IUncTableData,
	IObj,
	IUnc,
	IUncTableElem,
	IUserProject,
	IUncTableRequest,
	IUncAddBuffer,
} from '@root-gipro/store/interfaces'

export enum UserProjectsType {
	CREATE_PROJECT_TEMPLATE = 'SET_CREATE_PROJECT_TEMPLATE',
	FETCH_USER_PROJECT = 'FETCH_USER_PROJECT',
	SAVE_USER_PROJECT = 'SAVE_USER_PROJECT',
	CHOOSE_PROJECT = 'CHOOSE_PROJECT',
	SET_ORIGINAL_OBJ = 'SET_ORIGINAL_OBJ',
	DELETE_USER_PROJECT = 'DELETE_PROJECT',
	SET_TOGGLE_TABS = 'SET_TOGGLE_TABS',
	FETCH_REQUEST = 'FETCH_REQUEST',
	UNC_POSITION_EDIT = 'UNC_POSITION_EDIT',
	ADD_UNC = 'ADD_UNC',
	CREATE_UNC_B1 = 'CREATE_UNC_B1',
	CREATE_SPECIAL_UNC = 'CREATE_SPECIAL_UNC',
	FETCH_MINI_TABLE = 'FETCH_MINI_TABLE',
	DELETE_UNC = 'DELETE_UNC',
	CHANGE_REGION_FACTOR = 'CHANGE_REGION_FACTOR',
	CHANGE_FORM_TWENTY_NAME = 'CHANGE_FORM_TWENTY_NAME',
	FETCH_COST_TABLE = 'FETCH_COST_TABLE',
	APPEND_UNC_SEARCH = 'APPEND_UNC_SEARCH',
	GET_USER_PROJECTS = 'GET_USER_PROJECTS',
	FETCH_UNCS = 'FETCH_UNCS',
	// FETCH_UNC_TABLE_DATA = 'FECHANGE_UNC_COMMENTUNC', // возможно нужно удалить ( перепутал при добавлении )
	FETCH_UNC_TABLE_DATA = 'FETCH_UNC_TABLE_DATA',
	CHANGE_UNC_COMMENT = 'CHANGE_UNC_COMMENT',
	CHANGE_UNC_COUNTER = 'CHANGE_UNC_COUNTER',
	CHANGE_UNC_REGION = 'CHANGE_UNC_REGION',
	CHANGE_UNC_VOLTAGE = 'CHANGE_UNC_VOLTAGE',
	SELECT_VOLTAGE = 'SELECT_VOLTAGE',
	UPDATE_DEFLATORS = 'UPDATE_DEFLATORS',
	GET_CHECKED_ERRORS = 'GET_CHECKED_ERRORS',
	DOWNLOAD_RULES_CHECK = 'DOWNLOAD_RULES_CHECK',
	UPLOADER_RULES_CHECK = 'UPLOADER_RULES_CHECK',
	DOWNLOAD_PROJECT = 'DOWNLOAD_PROJECT',
	GET_TEMPLATE_UNNORMOLIZED_COSTS = 'GET_TEMPLATE_UNNORMOLIZED_COSTS',
	COPY_PROJECT = 'COPY_PROJECT',
	GET_UNNORMOLIZED_COSTS = 'GET_UNNORMOLIZED_COSTS',
	UPLOAD_UNNORMOLIZED_COSTS = 'UPLOAD_UNNORMOLIZED_COSTS',
	DELETE_UNNORMOLIZED_COSTS = 'DELETE_UNNORMOLIZED_COSTS',
	ADD_PRIVATE_PROJECT = 'ADD_PRIVATE_PROJECT',
	SET_VOLTAGE = 'SET_VOLTAGE',
	SELECT_VOLTAGE_ID = 'SELECT_VOLTAGE_ID',
	SHOW_PROJECT_ERRORS = 'SHOW_PROJECT_ERRORS',
	SET_PROJECT_ERRORS = 'SET_PROJECT_ERRORS',
	SET_PROJECT_FILTER = 'SET_PROJECT_FILTER',
	SET_INTERPOLATION = 'SET_INTERPOLATION',
	SET_PROJECT_YEARS = 'SET_PROJECT_YEARS',
	SET_UNC_FILTER = 'SET_UNC_FILTER',
	SET_UNC_ADD_BUFFER = 'SET_UNC_ADD_BUFFER',
	SELECT_TABLE_ELEM = 'SELECT_TABLE_ELEM',
	SET_ACTUAL_FUNDING = 'SET_ACTUAL_FUNDING',
	SET_OFFER_RATIONALE = 'SET_OFFER_RATIONALE',
	SET_ADDITIONAL_FUNDING_OFFER = 'SET_ADDITIONAL_FUNDING_OFFER',
	SET_VALUATION_OFFER_FORECAST = 'SET_VALUATION_OFFER_FORECAST',
	SET_YEARS = 'SET_YEARS',
	SET_PROJECT_NAME = 'SET_PROJECT_NAME',
	SET_PROJECT_СODE = 'SET_PROJECT_СODE',
	SET_PROJECT_UNCS = 'SET_PROJECT_UNCS',
	SET_IS_NEW_PROJECT = 'SET_IS_NEW_PROJECT',
	SET_NDS = 'SET_NDS',
	SET_ACTIVE_UNCS = 'SET_ACTIVE_UNCS',
	SET_UNC_EDIT_MODE = 'SET_UNC_EDIT_MODE',
	SET_USER_PROJECTS = 'SET_USER_PROJECTS',
	SET_REGION_IDS = 'SET_REGION_IDS',
	SET_UNC_TABLE_DATA = 'SET_UNC_TABLE_DATA',
	SET_VOLTAGE_ID = 'SET_VOLTAGE_ID',
}

interface FetchRequest {
	type: UserProjectsType.FETCH_REQUEST
}
interface SetUncEditMode {
	type: UserProjectsType.SET_UNC_EDIT_MODE
	mode: boolean
}
interface SetUserProjects {
	type: UserProjectsType.SET_USER_PROJECTS
	projects: IUserProject[]
	totalInvestmentUncOfferSum: number
	userProjectsCnt: number
}
interface ShowProjectErrors {
	type: UserProjectsType.SHOW_PROJECT_ERRORS
	show: boolean
}

interface CreateProjectTemplate {
	type: UserProjectsType.CREATE_PROJECT_TEMPLATE
}
interface ChooseProject {
	type: UserProjectsType.CHOOSE_PROJECT
	project: IUserProject
}
interface SetOriginal {
	type: UserProjectsType.SET_ORIGINAL_OBJ
	project: IUserProject
}
interface SetActiveUncs {
	type: UserProjectsType.SET_ACTIVE_UNCS
	uncs: IUnc[]
}

interface SetProjectUncs {
	type: UserProjectsType.SET_PROJECT_UNCS
	uncs: IUnc[]
}
interface SetIsNewProject {
	type: UserProjectsType.SET_IS_NEW_PROJECT
	isNew: boolean
}

interface SetProjectName {
	type: UserProjectsType.SET_PROJECT_NAME
	name: string
}

interface SetProjectYears {
	type: UserProjectsType.SET_YEARS
	begin: number | null
	end: number | null
}

interface SetProjectValuation {
	type: UserProjectsType.SET_VALUATION_OFFER_FORECAST
	valuation: number
}
interface SetProjectAdditional {
	type: UserProjectsType.SET_ADDITIONAL_FUNDING_OFFER
	offer: number
}
interface SetProjectRationale {
	type: UserProjectsType.SET_OFFER_RATIONALE
	offerRationale: string
}
interface SetProjectActual {
	type: UserProjectsType.SET_ACTUAL_FUNDING
	actualFunding: number
}
interface SetProjectNds {
	type: UserProjectsType.SET_NDS
	nds: number
}
interface SelectTableElem {
	type: UserProjectsType.SELECT_TABLE_ELEM
	selectedElem: Omit<IUncTableRequest, 'uncTable'> | null
}
interface ClearBuffer {
	type: UserProjectsType.SET_UNC_ADD_BUFFER
	buffer: IUncAddBuffer[]
}
interface SetYears {
	type: UserProjectsType.SET_PROJECT_YEARS
	years: { [key: string]: { offer: number | null; deflator: number | null } }
}
interface SetInterpolation {
	type: UserProjectsType.SET_INTERPOLATION
	obj: { [key: string]: string }
}

interface SetProjectFilter {
	type: UserProjectsType.SET_PROJECT_FILTER
	filter: string
}

interface SetProjectErrors {
	type: UserProjectsType.SET_PROJECT_ERRORS
	errors: { [key: string]: string[] } | null
}

interface SetVoltage {
	type: UserProjectsType.SET_VOLTAGE
	targetVoltageValues: { [key: string]: string }
	uncId: ReactText
}

interface SetProjectCode {
	type: UserProjectsType.SET_PROJECT_СODE
	code: string
}

interface SetRegionIds {
	type: UserProjectsType.SET_REGION_IDS
	regionIds: string[]
}

interface SetUncTableData {
	type: UserProjectsType.SET_UNC_TABLE_DATA
	uncTableData: any
}

interface SetUncFilter {
	type: UserProjectsType.SET_UNC_FILTER
	filter: string
}

interface SetUncFilter {
	type: UserProjectsType.SET_UNC_FILTER
	filter: string
}

interface SetVoltageId {
	type: UserProjectsType.SET_VOLTAGE_ID
	targetVoltageId: string
	uncId: string
}
export type UserProjectActionsTypes =
	| SetVoltageId
	| SetUncFilter
	| SetUncTableData
	| SetRegionIds
	| SetProjectCode
	| SetUserProjects
	| SetVoltage
	| SetProjectErrors
	| SetProjectFilter
	| SetInterpolation
	| SetYears
	| ClearBuffer
	| SelectTableElem
	| SetProjectNds
	| SetProjectActual
	| SetProjectRationale
	| SetProjectAdditional
	| SetProjectValuation
	| SetProjectYears
	| SetProjectName
	| SetIsNewProject
	| SetProjectUncs
	| SetActiveUncs
	| SetOriginal
	| ChooseProject
	| FetchRequest
	| CreateProjectTemplate
	| SetUncEditMode
	| ShowProjectErrors
