import React, { memo } from 'react'
import { Breadcrumbs, Typography } from '@material-ui/core/'
import { TabsNavLink } from '@root-gipro/shared/TabsComponents/TabsNavLink'
import { NameLinkShrt } from '@root-gipro/modules/header/models/header-name-link.const'
import '@root-gipro/shared/BreadcrumbsComponent/BreadcrumbsComponent.scss'
import { Link } from 'react-router-dom'
import { TypesNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { IGroup } from '@root-gipro/store/interfaces'

export interface BreadcrumbsComponentProps {
	group: IGroup
	path: string
	nameLink: string
	typesRoute: TypesNavLink[]
	urlId?: string
}

export const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = memo(
	({ group, path, nameLink, typesRoute, urlId }) => {
		const isShow = NameLinkShrt.PURCHASES === nameLink
		const hash = urlId ? `#${urlId}` : ''

		return (
			<div className='breadcrumbs-wrapper'>
				<Breadcrumbs
					separator={<span className='arrow-right-icon breadcrumbs-right-icon '></span>}
					aria-label='breadcrumb'
				>
					<Link
						to={{
							pathname: path,
							hash: hash,
						}}
						className='breadcrumbs-navlink'
					>
						<span className='arrow-left-icon breadcrumbs-left-icon'></span>
						{nameLink}
					</Link>
					<Typography className='breadcrumbs__shrt-txt'>{group && group.name}</Typography>
				</Breadcrumbs>
				{!isShow && <TabsNavLink types={typesRoute} path={path} />}
			</div>
		)
	}
)
