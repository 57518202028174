import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSpecialUnc, addUnc } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { ISelectTableProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { SelectTableThead } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableThead'
import { SelectTableTbody } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableTbody'
import { SelectTableHeader } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableHeader'
import { selectTableElem } from '@root-gipro/modules/userProjects/store/actions'
import { Button } from '@material-ui/core'

export const SelectTable: React.FC<ISelectTableProps> = ({ setShowDirections }) => {
	const [checkedTable, setChecked] = useState('1')
	const [showInter, setShowInter] = useState(false)
	const { selectedElem, uncTableData, interpolation, originalObj } = useSelector((state: IState) => state.userProjects)
	const tableNums: string[] = tbodyArr.filter(item => item.sitePreparation).map(item => String(item.tableNum))
	const dispatch = useDispatch()
	const handlerAddUnc = (uncCellId: string, interpolation: number, active: number) => {
		if (uncCellId && !interpolation && active) {
			dispatch(addUnc(checkedTable, uncCellId, 0, true))
		}
	}
	const confirmHandler = () => {
		let clone = Object.assign({}, interpolation)
		Object.keys(interpolation).forEach(key => {
			if (clone[key] === 'deleted') {
				delete clone[key]
			}
		})

		selectedElem && dispatch(createSpecialUnc(selectedElem.id, originalObj.regionIds[0], clone, checkedTable))
	}

	return (
		<div className={`main_table ${selectedElem ? 'main_table__show' : ''}`}>
			<div className='pannel_header'>
				<div className='pannel__nav'>
					<Button
						onClick={() => {
							dispatch(selectTableElem(null))
						}}
						className='pannel__nav-btn'
					>
						Назад
					</Button>
				</div>
				{selectedElem && (
					<>
						<div className='pannel_header-wrapper'>
							<div className='title'>
								<div>
									<span className='table_num'>{`Таблица ${selectedElem.code}. `}</span>
									{selectedElem.name}
								</div>
							</div>
							<div>
								<div className='subtitle'>{`Измеритель: ${selectedElem.unit}`}</div>
								<div onClick={() => setShowDirections(true)} className='note_btn'>
									Указания
								</div>
							</div>
						</div>
						<SelectTableHeader
							setChecked={setChecked}
							checkedTable={checkedTable}
							tableNums={tableNums}
							selectedElem={selectedElem}
							uncTableData={uncTableData}
							setShowInter={setShowInter}
							showInter={showInter}
							createSpecialUnc={confirmHandler}
						/>
					</>
				)}
			</div>
			<div className='table_wrapper'>
				<table className='add_table'>
					<SelectTableThead uncTableData={uncTableData} />
					<SelectTableTbody handlerAddUnc={handlerAddUnc} uncTableData={uncTableData} />
				</table>
			</div>
		</div>
	)
}
