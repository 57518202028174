export const objectStylesForCard = [
	// {
	// 	id:1,
	// 	img: 'tariff-search.png',
	// 	color: null,
	// 	name:' Поиск',
	// 	description: 'Вы найдете перспективные проекты электросетевого строительства с детализацией по номенклатуре, количеству, стоимости оборудования и материалов',
	// 	tarifYear:499,
	// 	tarifMonth:699,
	// 	tarifYear_unlimit:0,
	// 	tarifMonth_unlimit:0,
	// 	data:['84 000 проектов электросетевого строительства до 2029 года','Детализация по строительным ресурсам','Паспорта проектов',' Поиск по фильтрам','Выгрузка аналитических отчетов']
	// },
	{
		id:2,
		img: 'tariff-calc.png',
		color: '#5D5FEF',
		name:'Расчёты',
		description:'Вы безошибочно рассчитаете предел цены реализации инвестиционного проекта по методике с применением укрупненных нормативов цены (УНЦ)',
		tarifYear:4400,
		tarif6Month:4750,
		tarifMonth:5000,
		tarifYear_unlimit:17600,
		tarif6Month_unlimit:19000,
		tarifMonth_unlimit:20000,
		data:['89 000 проектов строительства до 2029 года','Поиск по строительным ресурсам','Паспорта проектов','Выгрузка аналитических отчетов','Расчет 5 проектов в месяц','Автоматическая проверка расчетов'],
		data_unlimit:['89 000 проектов строительства до 2029 года','Поиск по строительным ресурсам','Паспорта проектов','Выгрузка аналитических отчетов','Расчет неограниченного кол-ва проектов','Автоматическая проверка расчетов']
	},
	{
		id:3,
		img: 'tariff-purchase.png',
		color: '#EF5DA8',
		name:'Закупки',
		description:'Вы найдете перспективные проекты и закупки электросетевого строительства с детализацией по номенклатуре и количеству оборудования и материалов',
		tarifYear:4400,
		tarif6Month:4750,
		tarifMonth:5000,
		tarifYear_unlimit:17600,
		tarif6Month_unlimit:19000,
		tarifMonth_unlimit:20000,
		data:['89 000 проектов строительства до 2029 года','Поиск по строительным ресурсам','Паспорта проектов','Выгрузка аналитических отчетов','Ссылки на сайт Госзакупок']
	},
]
