import React, { FC, ReactText } from 'react'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import cn from 'classnames'
import { nanoid } from 'nanoid'

interface SubscriptionProps {
	currenDiscount: ReactText | null
	discountMap: any // todo any
	handleClickSubscribe(month: string): void
}

const Subscription: FC<SubscriptionProps> = ({ handleClickSubscribe, discountMap, currenDiscount }) => {
	return (
		<div className={styles.subscription}>
			<div className={styles.shrtTxt}>Срок подписки</div>
			<div className={styles.wrapperTiming}>
				<div className={styles.timing}>
					{discountMap.map((item: string[]) => (
						<div
							key={nanoid()}
							onClick={() => handleClickSubscribe(item[0])}
							className={cn(styles.time, {
								[styles.timeActive]: item[0] === currenDiscount,
							})}
						>
							{item[0]}
						</div>
					))}
				</div>
				{!currenDiscount && <div className={styles.discount}>Скидка {currenDiscount}%</div>}
			</div>
		</div>
	)
}

export { Subscription }
