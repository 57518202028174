import { call, takeEvery, put, takeLatest, debounce } from 'redux-saga/effects'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import { setFilterResultCount, filtersRequest } from '@root-gipro/modules/filter/store/actions'
import store from '@root-gipro/store'
import {
	setCommonProjectsCount,
	setCommonProjectsSum,
	setUncInfo,
	setAllowMoreProjects,
	setCommonProjectsPaginationCount,
	setFilterIdsCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { ICommonProject, IUncInfo } from '@root-gipro/store/interfaces'

import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { setPublicUncTechnicalSpecifications } from '@root-gipro/store/actions/public'
//import { refreshPurchasesState, setFilterIdsPurchases, setPurchases } from '@root-gipro/modules/purchases/store/actions'
import { setFilterIdsPurchases, setPurchases } from '@root-gipro/modules/purchases/store/actions'
import { ProjectType } from '@root-gipro/modules/favorites/models/enums/projects.enum'
import {
	setFavoritesProjectsCount,
	setFavoritesProjectsSum,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
} from '@root-gipro/modules/favorites/store/actions'

function* refreshFilterCountResult(action: any) {
	const state = store.getState().filter

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: state.purchasesFavorite

	const object = excludeData(currentFilter.showFilterState)
	const params = {
		...object,
		...currentFilter.sortState,
	}

	if (state.showFilterResult) {
		const res = yield call(state.showFilterResult, params)
		// надо решить вопрос с обработкой запроса при одновременном указании параметров строительные ресурсы и технические решения! Пока такая заплатка
		if (res) {
			yield put(setPublicUncTechnicalSpecifications(res.uncTechnicalSpecifications)) // для обновления массива uncTechnicalSpecifications при сбросе фильтра
			yield put(setFilterResultCount(res.resultsCnt, action.url))
		}
	}
}

function* showFilterResult(action: any) {
	const state = store.getState().filter
	const { sort } = store.getState().commonProjects
	const { favoritesSort } = store.getState().favorites
	const { sortParams, paginationCounter } = store.getState().purchases

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: state.purchasesFavorite

	const object = excludeData(currentFilter.showFilterState)

	const width = (currentFilter && currentFilter.sortState?.with) || []

	let params = {
		...object,
		limit: 5,
		with: [...width, 'IDs', 'linkedProjectIDs'],
		// with: width,
	}
	if (action.url === 'purchases') {
		params = {
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	}
	if (action.url === 'favorites-purchases') {
		params = {
			// @ts-ignore
			favoritesOnly: 1,
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	}
	if (action.url === 'common-projects') {
		params = {
			order: (sort.order ? '' : '-') + sort.sortName,
			...params,
		}
	}
	if (action.url === 'favorites-common-projects') {
		params = {
			// @ts-ignore
			favoritesOnly: 1,
			order: (favoritesSort.order ? '' : '-') + favoritesSort.sortName,
			...params,
		}
	}

	if (state.showFilterResult && state.refreshList && state.toggleLoadingList) {
		yield put(state.toggleLoadingList(true))

		const res = yield call(state.showFilterResult, params)
		if (res?.projects) {
			const projects: ICommonProject[] = res.projects
			const count: number = Number(res.resultsCnt)
			const valuationOfferForecastSum: number = Number(res.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(res.valuationPlanForecastSum) / 10 ** 6
			const uncInfo: IUncInfo = res.uncInfo
			const IDs: string[] = res.IDs
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			const newVoltageValues = Object.values(uncInfo.voltageValues)
			uncInfo.voltageValues = newVoltageValues
			if (action.url === 'favorites-common-projects') {
				yield put(setFavoritesProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(setFavoritesProjectsCount(count))
				yield put(state.refreshList(projects, false))
				yield put(state.toggleLoadingList(false))
			} else {
				yield put(setCommonProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(state.refreshList(projects, false))
				yield put(setAllowMoreProjects(true))
				yield put(setCommonProjectsCount(count))
				yield put(setCommonProjectsPaginationCount(0))
				yield put(setUncInfo(uncInfo))
				yield put(state.toggleLoadingList(false))
				yield put(setFilterIdsCommonProjects(IDs))
			}
		} else if (res?.purchases) {
			const count: number = Number(res.resultsCnt)
			const purchases: ICommonProject[] = res.purchases
			const maximumPriceSum = Number(res.maximumPriceSum) / 10 ** 6
			if (action.url === 'favorites-purchases') {
				yield put(state.refreshList(purchases, false))
				yield put(setFavoritesPurchasesSum(maximumPriceSum))
				yield put(setFavoritesPurchasesCount(count))
			} else {
				yield put(state.refreshList(res, false))
				yield put(setFilterIdsPurchases(res.IDs))
				// yield put(refreshPurchasesState('isLoadingInitList', false))
			}
		} else {
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			res.uncInfo.voltageValues = Object.values(res.uncInfo.voltageValues)
			yield put(state.refreshList(res, false))
			yield put(setFilterIdsPurchases(res.IDs))
		}
	}
}

// function* initFilterState({ state }: any) {
// 	const { groups, depGroups, regions, uncMains, uncThDescriptions, uncThs, rgStatuses } = store.getState().public
// 	const filterState = store.getState().filter
// 	const params = {
// 		...state,
// 		...filterState.purchases.sortState,
// 	}

// 	if (
// 		filterState.showFilterResult &&
// 		filterState.refreshList &&
// 		filterState.toggleLoadingList &&
// 		regions.length &&
// 		depGroups.length &&
// 		groups.length &&
// 		uncThs.length &&
// 		uncThDescriptions.length
// 	) {
// 		yield put(filterState.toggleLoadingList(true))
// 		const res = yield call(filterState.purchases.showFilterResult, params)

// 		yield put(
// 			setFilterState({
// 				resultFilterState: revertFilterValue(
// 					regions,
// 					depGroups,
// 					groups,
// 					uncThs,
// 					uncThDescriptions,
// 					[
// 						...rgStatuses,
// 						{
// 							id: '',
// 							title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
// 						},
// 					] as IRgStatuses[],
// 					uncMains,
// 					state
// 				),
// 				showFilterState: state,
// 			})
// 		)
// 		if (res.projects) {
// 			yield put(filterState.refreshList(res.projects))
// 			yield put(filterState.toggleLoadingList(false))
// 		} else {
// 			yield put(filterState.refreshList(res))
// 		}
// 	}
// }

function* handleChangeFilterSaga(action: any) {
	yield put(filtersRequest(action.url))
}

export default function* watchFilter() {
	// yield takeEvery(FilterActions.SET_INIT_FILTER_STATE, initFilterState)
	// yield takeEvery(FilterActions.REPLACE_FILTER_PARAMS, initFilterState)

	yield takeEvery(FilterActions.SHOW_FILTER_RESULT, showFilterResult)
	yield debounce(500, FilterActions.REFRESH_FILTER_STATE, handleChangeFilterSaga)
	yield takeLatest(FilterActions.FILTER_REQUEST, refreshFilterCountResult)
}
