import React, { useState, useEffect } from 'react'
import { IFilterContainerProps } from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-program'

const FilterContainer: React.FC<IFilterContainerProps> = ({ onReset, title, children, subtitle }) => {
    const [opened, setOpened] = useState(false)
    const [arrowClassName, setArrowClassName] = useState('filter__container-arrow')
    const [bodyClassName, setBodyClassName] = useState('filter__container-body')

    useEffect(() => {
        if (opened) {
            setArrowClassName(arrowClassName + ' filter__container-arrow--active')
            setBodyClassName(bodyClassName + ' filter__container-body--active')
        } else {
            setArrowClassName('filter__container-arrow')
            setBodyClassName('filter__container-body')
        }
    }, [opened])

    return (
        <div className="filter__container">
            <div
                className="filter__container-header"
                onClick={() => setOpened(!opened)}
            >
                <div className={ arrowClassName } />
                <h4 className="filter__container-title">{ title }</h4>
            </div>

            <div className={ bodyClassName }>
                <div className="filter__container-subtitle-container">
                    <h5 className="filter__container-subtitle">{ subtitle }</h5>

                    <button
                        className="filter__container-reset"
                        type="button"
                        onClick={() => { onReset() }}
                    >
                        Сбросить
                    </button>
                </div>
                { children }
            </div>
        </div>
    )
}

export default React.memo(FilterContainer)
