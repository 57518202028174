import React, { useEffect, useContext, MouseEvent, MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectVoltage } from '@root-gipro/modules/userProjects/store/actions'
import { ModalContext } from '@root-gipro/context/modal-context'
import { IUnc } from '@root-gipro/store/interfaces'

export interface VoltageProps {
	unc: IUnc
	dataAttr: string
	voltageRef: MutableRefObject<undefined | string>
}

const Voltage: React.FC<VoltageProps> = ({ voltageRef, dataAttr, unc }) => {
	const { setAnchorEl, accessoryRef } = useContext(ModalContext)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(selectVoltage(unc.uncCellId, unc.id))
	}, [])

	const handle = (event: MouseEvent<HTMLElement>) => {
		dispatch(selectVoltage(unc.uncCellId, unc.id))
		setAnchorEl!(event.currentTarget)
		if (accessoryRef) accessoryRef.current = unc.id
		voltageRef.current = unc.voltage
	}

	return (
		<>
			<div
				key={unc.uncCellId}
				onClick={handle}
				className='add-unc__voltage-wrapper'
				data-uncid={unc.id}
				data-popup={
					unc.voltage ||
					(unc.targetVoltageId === unc.id &&
						!!unc.targetVoltageValues &&
						Object.values(unc.targetVoltageValues).length > 0)
						? dataAttr
						: null
				}
			>
				<div
					key={unc.id}
					className={
						unc.voltage === null && !!unc.targetVoltageValues && Object.values(unc.targetVoltageValues).length > 0
							? 'voltage-select'
							: ''
					}
				>
					{unc.voltage === null && unc.targetVoltageValues === null && 'Отсутствует'}

					{unc.voltage === null &&
						!!unc.targetVoltageValues &&
						Object.values(unc.targetVoltageValues).length > 0 &&
						'Задать'}
					{unc.voltage && !!unc.targetVoltageValues && Object.values(unc.targetVoltageValues).length > 0 && unc.voltage}
				</div>
			</div>
		</>
	)
}

export default Voltage
