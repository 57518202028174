import React from 'react'
import { IFilterSelectProps } from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs'

const FilterSelect: React.FC<IFilterSelectProps> = ({ onChange, items, defaultValue }) => {
    return (
        <select
            className="filter__select"
            value={defaultValue}
            onChange={(e) => onChange ? onChange(e.currentTarget.value) : null}
        >
            <option value="" />
            {items.map(item =>
                <option
                    key={item.value}
                    value={ item.value }
                >
                    { item.text }
                </option>
            )}
        </select>
    )
}

export default React.memo(FilterSelect)
