import React, { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { setDevelopmentSchemesAndProgramsOrder } from '@root-gipro/modules/developmentSchemesAndPrograms/store/actions'

const Header = () => {
	const dispatch = useDispatch()
	const orderBy = useSelector((state: IState) => state.developmentSchemesAndPrograms.order.by)
	const orderDirection = useSelector((state: IState) => state.developmentSchemesAndPrograms.order.direction)
	const totalPrograms = useSelector((state: IState) => state.developmentSchemesAndPrograms.totalPrograms)
	const [asc, setAsc] = useState(false)
	const [arrowClassName, setArrowClassName] = useState('programs__header-select__arrow')

	const onDirectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
		dispatch(
			setDevelopmentSchemesAndProgramsOrder({
				// @ts-ignore
				by: e.currentTarget.value,
				direction: orderDirection,
			})
		)
	}

	useEffect(() => {
		if (asc) {
			setArrowClassName(arrowClassName + ' programs__header-select__arrow--asc')
		} else {
			setArrowClassName('programs__header-select__arrow')
		}
		dispatch(
			setDevelopmentSchemesAndProgramsOrder({
				by: orderBy,
				direction: asc ? 'ASC' : 'DESC',
			})
		)
	}, [asc])

	return (
		<div className='programs__header'>
			<div className='programs__header-sortWrap'>
				<span className='programs__header-sort-text'>Сортировка по:</span>
				<div className='programs__header-sort'>
					<select className='programs__header-select' value={orderBy} onChange={onDirectionChange}>
						<option value='title'>Наименование организации</option>
						<option value='since'>Год утверждения</option>
						<option value='startedAt'>Год начала</option>
						<option value='finishedAt'>Год окончания</option>
						<option value='quantity'>Количество проектов</option>
					</select>
					<div className={arrowClassName} onClick={() => setAsc(!asc)} />
				</div>
			</div>

			<div className='programs__header-stat'>
				<p>
					Всего: <strong>{totalPrograms}</strong>
				</p>
			</div>
		</div>
	)
}

export default React.memo(Header)
