import React, { useMemo, useContext, useState, useEffect, ReactText, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IRegion } from '@root-gipro/store/interfaces'
import { DragDropContext } from 'react-beautiful-dnd'
import UncAddPannel from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncAddPannel'
import { createUncB1, editPositionUnc } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { SitePreparationWindow } from '@root-gipro/modules/userProjects/components/Project/Modal'
import { ModalContext } from '@root-gipro/context/modal-context'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { IconButton } from '@material-ui/core'
import { copyProject, setUncEditMode } from '@root-gipro/modules/userProjects/store/actions'
import '@root-gipro/modules/userProjects/styles/Project.scss'
import { TableContainer } from '@root-gipro/modules/userProjects/components/Project/UncTable/TableContainer'

const UncTablContainer: React.FC = () => {
	const [activeTotal, setActiveTotal] = useState<number | undefined>()
	const tableNums: string[] = tbodyArr.filter(item => item.sitePreparation).map(item => String(item.tableNum))
	const [checkedTable, setCheckedTable] = useState('1')
	const [checkedRegionId, setCheckedRegion] = useState<ReactText>()
	const { projectObj, activeUncs, uncs } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)
	const { uncEditMode } = useSelector((state: IState) => state.userProjects)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()

	const regionList: IRegion[] = useMemo(() => {
		return regions?.filter((elem: IRegion) => projectObj.regionIds.includes(elem.id))
	}, [projectObj, regions])

	const onDragEnd = useCallback(params => {
		if (params.destination) dispatch(editPositionUnc(params))
	}, [])

	useEffect(() => {
		let costC1 = activeUncs.filter(i => i.uncMain.code === 'С1')

		if (costC1.length > 0) {
			let sumCostC1 = costC1.reduce((accum, curr) => (accum += curr.totalCost), 0)
			setActiveTotal(sumCostC1)
		} else {
			setActiveTotal(undefined)
		}
	}, [activeUncs, uncs])

	useEffect(() => {
		if (projectObj.regionIds) {
			let defaultRegion = [...projectObj.regionIds]
			defaultRegion.sort((a: unknown, b: unknown) => (a as number) - (b as number))
			return setCheckedRegion(defaultRegion[0])
		}
	}, [projectObj.regionIds])

	return (
		<>
			<div className='table_wrapper'>
				<DragDropContext onDragEnd={onDragEnd}>
					<div className='container'>
						<div className='unc_table__head'>
							<div className='unc_table__head-left'>
								<div className='head-left__title'>
									Расценки УНЦ <span className='currency'>тыс ₽</span>
								</div>
							</div>
							<div className='unc_table__head-right'>
								<div className='user-projects__options'>
									<IconButton onClick={() => dispatch(copyProject(projectObj.id!, `${projectObj.code}_COPY`))}>
										<span className='copy-icon'></span>
									</IconButton>
									<IconButton onClick={() => dispatch(setUncEditMode(!uncEditMode))}>
										<span className='add-unc-icon'></span>
									</IconButton>
								</div>
							</div>
						</div>
					</div>

					<TableContainer uncs={uncs} />
				</DragDropContext>

				{anchorEl?.dataset.popup === cellTags[1] && (
					<SitePreparationWindow
						anchorEl={anchorEl!}
						setAnchorEl={setAnchorEl!}
						activeTotal={activeTotal!}
						regionList={regionList}
						tableNums={tableNums}
						checkedRegionId={checkedRegionId}
						setCheckedRegion={setCheckedRegion}
						checkedTable={checkedTable}
						setCheckedTable={setCheckedTable}
						handler={() => dispatch(createUncB1(34, checkedRegionId!, 1, checkedTable, activeTotal! / 1000))}
					/>
				)}
			</div>
			<UncAddPannel uncEditMode={uncEditMode} />
		</>
	)
}
export { UncTablContainer }
