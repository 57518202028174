import React, { FC, useCallback, ReactText, useState, useMemo, useEffect, ChangeEvent } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import { TariffPlan } from '@root-gipro/modules/tariffs/components/Modal/ExtensionAccess/TariffPlan'
import { Subscription } from '@root-gipro/modules/tariffs/components/Modal/ExtensionAccess/Subscription'
import { AddMore } from '@root-gipro/modules/tariffs/components/Modal/ExtensionAccess/AddMore'
import { Order } from '@root-gipro/modules/tariffs/components/Modal/ExtensionAccess/Order'
import { useDispatch, useSelector } from 'react-redux'
import { paymentCreate } from '@root-gipro/modules/tariffs/store/actions'
import { Switch } from '@material-ui/core'
import { IState } from '@root-gipro/store/interfaces'

interface ExtensionAccessProps {}

const ExtensionAccess: FC<any> = ({ anchorEl, setAnchorEl, hideModal }) => {
	const [state, setState] = useState(false)
	const [currenDiscount, setCurrentDiscount] = useState<string>('1 мес')
	const [openIncludes, setOpenIncludes] = useState<boolean>(false)
	const [openMore, setOpenMore] = useState<boolean>(false)
	const dispatch = useDispatch()
	const { tarif_price_id, unlimit } = useSelector((state: IState) => state.tariffs.selectedSettings)
	const handleClickIncludes = useCallback(() => {
		setOpenIncludes(!openIncludes)
	}, [openIncludes])
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setState(true)
	}

	// сопоставление ид тарифным планам из БД (id = номер тарифа)
	const getTarif = (id: string, month: string, unlimit: boolean = false) => {

		switch(id) {

			//поиск (убрали)
			//  case '1':
			//    return month === '12' ? '1' : '2'
			//    break;

			// расчеты
			case '2':
				if (unlimit) {
				  	if (month === '3') return '19'
				  	if (month === '6') return '20'
				  	if (month === '12') return '21'
				} else {
				  	if (month === '3') return '10'
				  	if (month === '6') return '11'
				  	if (month === '12') return '12'
				}
			break;

			// закупки
			case '3':
				if (month === '3') return '22'
				if (month === '6') return '23'
				if (month === '12') return '24'
			break;
		}

		return '0'
	}

	const handleClickMore = useCallback(() => {
		setOpenMore(!openMore)
	}, [openMore])

	const discountMonth = {
		'3 мес': 2,
		'6 мес': 3,
		'12 мес': 10,
	}
	const buttons = {
		PrimaryBtn: {
			handler: () => {
				setAnchorEl && setAnchorEl(null)
				dispatch(
					paymentCreate({
						tarif_price_id: getTarif(tarif_price_id, currenDiscount.split(' ')[0], unlimit),
						months: currenDiscount.split(' ')[0],
					})
				)
			},
			value: 'Оплатить',
			fullWidth: true,
		},
	}

	const title = [
		{
			classes: 'leftTitle',
			value: 'Сумма заказа',
		},
		{
			classes: 'rightTitleGray',
			icon: true,
			action: hideModal,
		},
	]

	const discountMap = useMemo(() => {
		return Object.entries(discountMonth)
	}, [anchorEl])

	const handleClickSubscribe = useCallback(
		month => {
			setCurrentDiscount(month)
		},
		[currenDiscount]
	)

	useEffect(() => {
		setCurrentDiscount('3 мес')
	}, [])

	return (
		<Modal
			classes={styles.wrapper}
			widthWindow={'400px'}
			buttonOptions={buttons}
			setAnchorEl={setAnchorEl}
			anchorEl={anchorEl}
			title={title}
		>
			<TariffPlan openIncludes={openIncludes} handleClickIncludes={handleClickIncludes} />
			{(
				<Subscription
					currenDiscount={currenDiscount}
					discountMap={discountMap}
					handleClickSubscribe={handleClickSubscribe}
				/>
			)}

			{/* <AddMore handleClickMore={handleClickMore} openMore={openMore} /> */}
			<Order currenDiscount={currenDiscount} />
		</Modal>
	)
}
export { ExtensionAccess }
