import { RouteComponentProps } from 'react-router-dom'
import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'

export const getIdentifier = (location: RouteComponentProps['location'], sortList: ISortItem[]) => {
	let indexIdentifier: number | undefined

	if (location.pathname.indexOf('/common-projects') === 0 && sortList) {
		indexIdentifier = sortList.findIndex(item => item.sortName === 'valuationOfferForecast')
	}
	if (location.pathname.indexOf('/favorites-common-projects') === 0 && sortList) {
		indexIdentifier = sortList.findIndex(item => item.sortName === 'valuationOfferForecast')
	}
	if (location.pathname.indexOf('/purchases') === 0 && sortList) {
		indexIdentifier = sortList.findIndex(item => item.sortName === 'registrationNumber')
	}
	if (location.pathname.indexOf('/favorites-purchases') === 0 && sortList) {
		indexIdentifier = sortList.findIndex(item => item.sortName === 'registrationNumber')
	}

	return indexIdentifier
}
