import React, { useState, useCallback, useEffect, FC, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { ErrorsList } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/ErrorsList'
import { filterWidthWindow } from '@root-gipro/modules/userProjects/utils/helpers'
import Loader from '@root-gipro/shared/Loader/Loader'
import { ISectionIds } from '@root-gipro/store/interfaces'
interface CheckProjectProps {
	title?: string
	handler: () => void
	sectionIds: ISectionIds[]
	sectionIdsArr: number[]
	accessoryRef: MutableRefObject<string | undefined> | undefined
	accessory: string | undefined
	setAccessory?(string: string): void
	setAnchorEl(event: HTMLElement): void
	anchorEl: (EventTarget & HTMLElement) | null | undefined
}
const CheckProjectWindow: FC<CheckProjectProps> = ({
	handler,
	setAnchorEl,
	sectionIds,
	sectionIdsArr,
	accessoryRef,
	accessory,
	setAccessory,
	anchorEl,
}) => {
	const { projectErrors } = useSelector((state: IState) => state.userProjects)
	const [isExpand, setIsExpand] = useState(false)
	const [indexPanel, setIndex] = useState<number | undefined>()
	const [loader, setLoader] = useState(false)
	const handleClick = useCallback(
		(panel: number) => {
			setIndex(panel)
			setIsExpand(panel === indexPanel ? !isExpand : true)
		},
		[indexPanel, isExpand]
	)

	const filterButtonOptions = (step: string) => {
		switch (step) {
			case 'check-list':
				return {
					PrimaryBtn: {
						handler: handler,
						value: 'Проверить',
					},

					SecondaryBtn: {
						handler: () => {
							setAnchorEl!(null!)
						},
						value: 'Отмена',
					},
				}
			case 'error-list':
				return {
					PrimaryBtn: {
						handler: () => {
							setAnchorEl!(null!)
						},
						value: 'Готово',
					},
					SecondaryBtn: {
						handler: () => accessoryRef?.current && setAccessory!(accessoryRef.current),
						value: 'Назад',
					},
				}

			default:
				break
		}
	}
	const filterTitle = (step: string | undefined) => {
		let title
		switch (step) {
			case 'check-list':
				title = [
					{
						classes: 'leftTitle',
						value: 'Проверка',
						action: undefined,
					},
				]
				return title
			case 'error-list':
				title = undefined
				return title

			default:
				title = undefined
				return title
		}
	}
	useEffect(() => {
		if (accessory === 'error-list') {
			setTimeout(() => {
				setLoader(true)
			}, 500)
		}
	}, [accessory])
	return (
		<Modal
			keyBtn={accessory}
			buttonOptions={accessory ? filterButtonOptions(accessory) : null}
			setAnchorEl={setAnchorEl}
			anchorEl={anchorEl}
			title={filterTitle(accessory)}
			widthWindow={filterWidthWindow(accessory)}
		>
			{accessory === 'check-list' && (
				<>
					<div className='check-plate__shrt-txt mb-8'>Выберите категории</div>
					{sectionIds.map((elem: ISectionIds) => {
						return (
							<div key={elem.id} className='check-plate__checkbox'>
								<input
									id={elem.id}
									type='checkbox'
									defaultChecked
									onChange={e => {
										const checkElem: number = sectionIdsArr.indexOf(+elem.id)
										e.target.checked ? sectionIdsArr.push(+elem.id) : sectionIdsArr.splice(checkElem, 1)
									}}
								/>
								<label htmlFor={elem.id}>{elem.name}</label>
							</div>
						)
					})}
				</>
			)}
			{accessory === 'error-list' && !loader && <Loader />}
			{accessory === 'error-list' && loader && (
				<ErrorsList
					handleClick={handleClick}
					projectErrors={projectErrors}
					indexPanel={indexPanel}
					sectionIds={sectionIds}
					isExpand={isExpand}
				/>
			)}
		</Modal>
	)
}
export default CheckProjectWindow
