import React, { FC } from 'react'
import List from '@root-gipro/modules/userProjects/components/UserProjects/List'
import { HeaderWrapper } from '@root-gipro/modules/userProjects/components/HeaderWrapper'
import { UserProjectsNav } from '@root-gipro/modules/userProjects/components/UserProjects/UserProjectsNav'
import '@root-gipro/modules/userProjects/styles/UserProjects.scss'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { Text, TextLink, WrapperInformation } from './styles'
import store from '@root-gipro/store'
const UserProjects: FC = () => {
	// const { policesGipro } = useSelector((state: IState) => state.public)
	const { roleId } = store.getState().auth.user

	return  (
		<>
		{
			(roleId && roleId < 999) ? (
			<>
			<div className='container'>
				<HeaderWrapper>
					<UserProjectsNav />
				</HeaderWrapper>
			</div>
			<div className='container-full'>
				<List />
			</div>
			</>
			)
		: (
			<WrapperInformation>
				<Text>Gipro "Расчетный модуль".</Text>
				<Text>Вы безошибочно рассчитаете предел цены реализации инвестиционного проекта по методике с применением укрупненных нормативов цены (УНЦ).</Text>
				<TextLink  href={`https://${process.env.REACT_APP_ENV}/tariffs`}>Получить доступ</TextLink>
			</WrapperInformation>
		)
		}	
		</>
	)
}

export default UserProjects
