import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'

export const PurchaseSortList: ISortItem[] = [
	{ sortName: 'groupId', name: 'Организация' },
	// { sortName: 'groupId', name: 'ДЗО' },
	// { sortName: 'subject', name: 'Назначение' },
	{ sortName: 'regionId', name: 'Регион поставки' },
	{ sortName: 'plannedDate', name: 'Планируемая дата размещения извещения' },
	{ sortName: 'endDate', name: 'Срок исполнения договора' },
	// { sortName: 'method', name: 'Способ закупки' },
	// { sortName: 'isElectronic', name: 'Заявка в электронном виде' },
	// { sortName: 'status', name: 'Статус позиции' },
	{ sortName: 'registrationNumber', name: 'Идентификатор' },
	// { sortName: 'publicationDate', name: 'Дата изменения' },
	{ sortName: 'submissionCloseDate', name: 'Дата окончания подачи заявок' },
	// { sortName: 'summingUpDate', name: 'Дата подведения итогов' },
	// { sortName: 'planType', name: 'Тип договора' },
	{ sortName: 'maximumPrice', name: 'Начальная цена договора ' },
]
