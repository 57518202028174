import { purchasesState } from '@root-gipro/modules/purchases/models/consts/init-purchase-state.const'
import { IPurchasesState } from '@root-gipro/modules/purchases/models/interfaces/purchases-state.model'
import { PurchasesType, PurchasesTypes } from '../models/interfaces/purchases.action'
import produce, { Draft } from 'immer'

const purchase = (draft: Draft<IPurchasesState>, action: PurchasesTypes) => {
	switch (action.type) {
		case PurchasesType.SET_PURCHASES:
			draft.list = action.state.list
			draft.count = action.state.count
			draft.purchasesType = action.state.purchasesType
			draft.uncInfo = action.state.uncInfo
			draft.state = action.state.state
			draft.mSum = action.state.mSum
			draft.iSum = action.state.iSum
			draft.sortParams = action.state.sortParams
			draft.isFavorite = action.isFavorite
			break
		case PurchasesType.SET_PURCHASE_FAVORITE:
			draft.list = action.list
			break
		case PurchasesType.TOGGLE_LOADING_LIST:
			draft.state.isLoadingInitList = !draft.state.isLoadingInitList
			break
		case PurchasesType.REFRESH_PURCHASES_STATE:
			draft.state.isMoreItemsLoading = draft.state.isMoreItemsLoading ? false : draft.state.isMoreItemsLoading
			draft.state[action.fieldName] = action.value
			break
		case PurchasesType.CHANGE_PURCHASES_TYPE:
			draft.purchasesType = action.purchasesType
			break
		case PurchasesType.SET_SELECTED_PURCHASE:
			draft.selectedPurchase = action.selectedPurchase
			break
		case PurchasesType.SET_PURCHASE_ITEMS:
			draft.list = action.items
			break
		case PurchasesType.APPEND_PURCHASE_INFO:
			const pInd = draft.list.findIndex(purchase => purchase.guid === action.guid)
			if (pInd !== -1) {
				draft.list[pInd].isExpand = !draft.list[pInd].isExpand
				;(draft.list[pInd] as any)[action.fieldName] = action.value
			}
			break
		case PurchasesType.REFRESH_LIST:
			draft.list = action.list.purchases
			draft.count = Number(action.list.resultsCnt)
			draft.uncInfo = action.list.uncInfo
			draft.state.isLoadingInitList = false
			draft.state.allowMoreItems = (action.list?.purchases?.length || 0) < Number(action.list?.resultsCnt || 0)
			draft.iSum = Number(action.list?.initialSumSum) / 10 ** 6
			draft.mSum = Number(action.list?.maximumPriceSum) / 10 ** 6
			draft.isFavorite = action.isFavorite
			break
		case PurchasesType.SET_PURCHASES_PAGINATION_COUNTER:
			draft.paginationCounter = action.paginationCounter
			break
		case PurchasesType.SET_FILTER_IDS_PURCHASES:
			draft.IDs = action.IDs
			break
		default:
			return draft
	}
}
export const purchaseReducer = produce(purchase, purchasesState)
