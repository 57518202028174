import React  from 'react'
import InvestmentProgram from '@root-gipro/modules/investmentPrograms/components/partials/Card'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

const Main = () => {
    const programs = useSelector((state:IState) => state.investmentPrograms.programs)

    return (
        <>
            {programs.length > 0 &&
                <ul className="programs__list">
                    {programs.map(({
                        id,
                        quantity,
                        since,
                        title,
                        startedAt,
                        finishedAt,
                        publishedAt,
                        cost,
                        resource,
                        mediaId
                    }) =>
                        <InvestmentProgram
                            key={`ipr${id}`}
                            quantity={ quantity }
                            since={ since }
                            title={ title }
                            startedAt={ startedAt }
                            finishedAt={ finishedAt }
                            publishedAt={ publishedAt }
                            cost={ cost }
                            resource={ resource }
                            mediaId={ mediaId }
                        />
                    )}
                </ul>
            }

            {!programs.length &&
                <p>Нет данных</p>
            }
        </>
    )
}

export default React.memo(Main)
