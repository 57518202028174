import React, { ChangeEvent, forwardRef, FocusEvent, RefObject, LegacyRef } from 'react'
import { InputInt } from '@root-gipro/shared/FormElements/Input/InputInt'
import InputMask, { ReactInputMask } from 'react-input-mask'
import '@root-gipro/shared/FormElements/Input/Input.scss'

export interface IInput {
	labelName?: string
	inputName?: string
	handleInput?(e: ChangeEvent<HTMLInputElement>): void
	handleBlur?(e: ChangeEvent<HTMLInputElement>): void
	onFocus?(e: FocusEvent<HTMLInputElement>): void
	inputValue?: string | number | undefined
	type?: string
	disabled?: boolean
	classes?: string
	placeholderValue?: string
	changeFunc?: (value: string) => void
	InputIntShow?: boolean
	inputRef?: unknown
	required?: boolean
	errors?: boolean
	helperText?: string | undefined
	mask?: string
	inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
}
const Input: React.FC<IInput> = forwardRef(
	({
		labelName,
		inputName,
		handleInput,
		handleBlur,
		inputValue,
		type,
		disabled,
		classes,
		placeholderValue,
		children,
		onFocus,
		changeFunc,
		InputIntShow = false,
		inputRef,
		required = false,
		errors,
		helperText,
		mask,
		inputMode,
	}) => {
		return (
			<div className={`input-box ${classes && classes}`}>
				{labelName && <label className='universal-label'>{labelName}</label>}
				<div className='wrapper-input'>
					{!InputIntShow && !mask ? (
						<input
							key={inputName}
							type={type}
							name={inputName}
							onChange={handleInput}
							onBlur={handleBlur}
							onFocus={onFocus && onFocus}
							value={inputValue || ''}
							disabled={disabled}
							autoComplete='off'
							className='universal-input'
							placeholder={placeholderValue && placeholderValue}
							ref={inputRef as RefObject<HTMLInputElement>}
							required={required}
							inputMode={inputMode ? inputMode! : 'text'!}
						/>
					) : (
						!mask && <InputInt value={inputValue} placeholder={placeholderValue!} changeFunc={changeFunc!} />
					)}
					{mask && (
						<InputMask
							mask={mask}
							key={inputName}
							type={type}
							name={inputName}
							onChange={handleInput}
							onBlur={handleBlur}
							onFocus={onFocus && onFocus}
							value={inputValue || ''}
							disabled={disabled}
							autoComplete='off'
							className='universal-input'
							placeholder={placeholderValue && placeholderValue}
							ref={inputRef as RefObject<ReactInputMask>}
							required={required}
							inputMode={inputMode ? inputMode! : 'text'!}
						/>
					)}
					{children && <div className='place-input'>{children}</div>}
					{helperText && <div className={`${errors ? 'massage-error' : 'massage'}`}>{helperText}</div>}
				</div>
			</div>
		)
	}
)
export default Input
