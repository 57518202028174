import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { Checkbox, ListItemText } from '@material-ui/core'
import { spaceFormat } from '@root-gipro/utils/helpers/helperInputFloat'
import { isEqual } from 'lodash'
import { FilterItemRangePriceProps } from '@root-gipro/shared/Filter/models/filter-item-range-price.props'
import { Input } from '@root-gipro/shared/FormElements'
import { intFormat } from '@root-gipro/utils/helpers/common'

const FilterItemRangeNumbers: React.FC<FilterItemRangePriceProps> = ({
	setStart,
	setEnd,
	start,
	end,
	name,
	startName = 'с',
	endName = 'по',
	disabledAll,
	withCheckbox,
	convertValue,
}) => {
	const resetFields = () => {
		if (start || end) {
			setStart('')
			setEnd('')
		}
	}

	const handlerFocusField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = intFormat(e.currentTarget.value)

		setFn(value)
	}

	const handlerChangeField = (e: ChangeEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = intFormat(e.currentTarget.value)
		setFn(value)
	}

	const handlerBlurField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		const value = spaceFormat(e.currentTarget.value)
		setFn(value)
	}

	const [startPrice, setStartPrice] = useState(start || '')
	const [endPrice, setEndPrice] = useState(end || '')

	useEffect(() => {
		if (!isEqual(start, startPrice)) {
			setStartPrice(start || '')
		}
		if (!isEqual(end, endPrice)) {
			setEndPrice(end || '')
		}
	}, [start, end])

	return (
		<div className='range-price'>
			<div className='range-price__head'>
				<h5 className='range-price__head-title'>{name}</h5>
				<span className='range-price__head-title link link-grey' onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<div className='range-price__fields'>
				<Input
					inputValue={startPrice}
					disabled={disabledAll || withCheckbox?.checked}
					onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setStart)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setStart)}
					handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setStart)}
					classes='part-width'
				>
					<div className='shrt-text-input'>{startName}</div>
				</Input>
				<Input
					inputValue={endPrice}
					disabled={disabledAll || withCheckbox?.checked}
					onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setEnd)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setEnd)}
					handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setEnd)}
					classes='part-width'
				>
					<div className='shrt-text-input'>{endName}</div>
				</Input>
			</div>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox
						checked={withCheckbox.checked}
						disabled={withCheckbox.disabled}
						onChange={() => {
							if (withCheckbox.checkboxChange) withCheckbox.checkboxChange()
							withCheckbox.setChecked(!withCheckbox.checked)
							// if (!withCheckbox.checked) resetFields() // сбрасывает значения полей, ломает функционал. Не нашел, где может пригодится
						}}
					/>
					<ListItemText primary={withCheckbox.label} />
				</div>
			)}
		</div>
	)
}

export default FilterItemRangeNumbers
