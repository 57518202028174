import { PublicType, PublicTypes } from '../actions'
import { IPublic } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

const initialState: IPublic = {
	policesGipro: false,
	policesRGCO: false,
	groups: [],
	regions: [],
	uncMains: [],
	depGroups: [],
	publicationDates: [],
	publicationDatesPlans: [],
	yearBegin: '',
	yearEndOffer: '',
	uncSections: [],
	uncThs: [],
	uncThDescriptions: [],
	rgStatuses: [],
	purchasesMethods: [],
	purchasesStages: [],
	uncTechnicalSpecifications: {
		name:{},
		paramName:{},
		param:{}
	},
}
const publicFn = (draft: Draft<IPublic>, action: PublicTypes) => {
	switch (action.type) {
		case PublicType.SET_PUBLIC_INFO:
			draft.policesGipro = action.policesGipro
			draft.policesRGCO = action.policesRGCO
			draft.groups = action.groups
			draft.regions = action.regions
			draft.uncMains = action.uncMains
			draft.depGroups = action.depGroups
			draft.publicationDates = action.publicationDates
			draft.publicationDatesPlans = action.publicationDatesPlans
			draft.uncSections = action.uncSections
			draft.uncThs = action.uncThs
			draft.uncThDescriptions = action.uncThDescriptions
			draft.rgStatuses = action.rgStatuses
			break
		case PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS:
			draft.uncTechnicalSpecifications = action.uncTechnicalSpecifications
			break
		case PublicType.SET_PURCHASES_METHODS:
			draft.purchasesMethods = action.purchasesMethods
			break
		case PublicType.SET_PURCHASES_STAGES:
			draft.purchasesStages = action.purchasesStages
			break
		default:
			return draft
	}
}
export const publicReducer = produce(publicFn, initialState)
