import React, { useState, useEffect, useMemo, useContext, useCallback, FC, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { IUnc } from '@root-gipro/store/interfaces'
import { Cells } from '@root-gipro/modules/userProjects/components/Project/UncTable/Cells'
import { Collapse } from '@material-ui/core'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { Droppable } from 'react-beautiful-dnd'
import { IState } from '@root-gipro/store/interfaces/state'
import Tooltip from '@material-ui/core/Tooltip'
import uniqueId from 'lodash/uniqueId'
import {
	BodyType,
	gridTemplateColumnsAreaCalculation,
	theadCAreaCalculation,
} from '@root-gipro/modules/userProjects/models/consts/unc-thead.const'
import { ModalContext } from '@root-gipro/context/modal-context'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import cn from 'classnames'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'

interface RowCellsProps {
	title: string
	uncs: IUnc[]
	tableNum: number
	currentCellTags: string
	voltageRef: MutableRefObject<undefined | string>
}

export const RowCells: FC<RowCellsProps> = ({ voltageRef, title, uncs, tableNum, currentCellTags }) => {
	const [show, setShow] = useState(uncs.length > 0 ? true : false)
	const uncsList = useSelector((state: IState) => state.userProjects.uncs)
	const { setAnchorEl } = useContext(ModalContext)

	useEffect(() => {
		if (uncs.length > 0) setShow(true)
	}, [uncs])

	const total = useMemo(() => {
		if (uncs.length > 0) {
			return uncs.reduce((accum, curr) => (accum += curr.totalCost), 0)
		} else {
			return 0
		}
	}, [uncs])
	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			setAnchorEl!(e.currentTarget)
		},
		[setAnchorEl]
	)

	return (
		<>
			<div className={styles.row}>
				<div className={styles.rowTitle}>
					<div className={styles.TitleWrapper} onClick={() => setShow(!show)}>
						<span
							className={cn('dropdown-icon', styles.arrow, {
								[styles.open]: !show,
							})}
						/>
						{`${tableNum !== 8 ? tableNum + '.' : ''} ${title}`}
					</div>

					{tableNum === 8 && (
						<div className='link link-blue ml-8' data-popup={cellTags[1]} onClick={openWindow}>
							Подготовка территории
						</div>
					)}
				</div>

				{uncs.length > 0 && <div className={styles.total}>{prettyCost(total / 1000)}</div>}
			</div>
			<Collapse in={show} timeout='auto' unmountOnExit>
				{tableNum === 8 && uncs.length > 0 && (
					<div
						style={{ gridTemplateColumns: gridTemplateColumnsAreaCalculation }}
						className={cn(styles.thead, styles.grid)}
					>
						{theadCAreaCalculation.map((item: BodyType) => (
							<Tooltip
								key={uniqueId('unc_')}
								title={item.tooltip}
								enterDelay={500}
								enterNextDelay={500}
								placement='top'
							>
								<div className={cn(styles.theadCell, styles[item.textAlign])}>{item.title}</div>
							</Tooltip>
						))}
					</div>
				)}

				<Droppable droppableId={`${tableNum && tableNum}`}>
					{(provided, snapshot) => {
						return uncs.length > 0 ? (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{uncs.map((elem, ind) => (
									<Cells
										index={uncsList.findIndex((uncElem: IUnc) => uncElem.id === elem.id)}
										key={elem.id}
										unc={elem}
										currentCellTags={currentCellTags}
										voltageRef={voltageRef}
										tableNum={tableNum}
									/>
								))}
								{provided.placeholder}
							</div>
						) : (
							<div {...provided.droppableProps} ref={provided.innerRef} className={styles.nothing}>
								Нет данных
							</div>
						)
					}}
				</Droppable>
			</Collapse>
		</>
	)
}
