import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import uniqueId from 'lodash/uniqueId'
import { SelectedPurchaseCollapseDetail } from '@root-gipro/modules/purchases/models/consts/selected-purchase-collapse-detail.const'
import { CardNoticeContentItem, CardProtocolContentItem } from '@root-gipro/modules/purchases/models/consts/card-inside-content-items.const'
import { Collapse } from '@material-ui/core'
import { CardFull, CardLeft, CardRight, CardItem, CardDetailedInfoItem } from '@root-gipro/shared/Card'
import { PurchaseCollapseProps } from './purchase-collapse.props'
import { PurchaseCollapseDetail } from '../../models/consts/purchase-collapse-detail.const'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import CardDetailedInfo from '@root-gipro/shared/Card/CardDetailedInfo/CardDetailedInfo'

const PurchasesCollapse: React.FC<PurchaseCollapseProps> = ({
	model,
	purchase,
	toggleInvestDetail,
	isExpand,
	onList,
	children,
}) => {
	const { groups, depGroups, regions } = useSelector((state: IState) => state.public)

	return (
		<>
			<Collapse in={isExpand} unmountOnExit>


				<CardFull classes='detail-purchases'>
					<h4 className='card-inside-title'>Данные из Плана закупки</h4>
					{(
						<div className='card-inside__detailed-expand' key={purchase.id}>
							{SelectedPurchaseCollapseDetail(depGroups, regions, toggleInvestDetail, model.Plan).map(item => (
								<CardDetailedInfoItem
									key={uniqueId('CardDetailedInfoItem')}
									title={item.title}
									value={item.value(purchase) ? item.value(purchase) : 'Нет данных'}
									classes={item.extraClass}
									clickLabel={item.clickLabel}
									projects={item.projects && item.projects(purchase.projects?.length)}
								/>
							))}
						</div>
					)}
				</CardFull>


				<CardFull classes='detail-purchases'>
					<h4 className='card-inside-title'>Извещение о закупке</h4>
					<div key={uniqueId('cardInsideDetailedExpand_')} className='card-inside__head'>
						{CardNoticeContentItem.map(item => (
							<div
								key={uniqueId('cardInsideDetailedExpand')}
								className={`card-inside__detailed-expand-info__item item-s`}
							>
								<div className='card-inside__detailed-expand-title'>{item.title}</div>
								<div
									className={`card-inside__detailed-expand-value ${
										item.title === null && 'card-container__detailed--disabled'
									}`}
									onClick={item.click && item.click}
								>
									<span>{item.value(purchase) ? item.value(purchase) : 'Нет данных'}</span>
								</div>
							</div>
						))}
					</div>
				</CardFull>


				<CardFull classes='detail-purchases'>
					<h4 className='card-inside-title'>Результат торгов</h4>
					<div key={uniqueId('cardInsideDetailedExpand_')} className='card-inside__head'>
						{CardProtocolContentItem.map(item => (
							<div
								key={uniqueId('cardInsideDetailedExpand')}
								className={`card-inside__detailed-expand-info__item item-s`}
							>
								<div className='card-inside__detailed-expand-title'>{item.title}</div>
								<div
									className={`card-inside__detailed-expand-value ${
										item.title === null && 'card-container__detailed--disabled'
									}`}
									onClick={item.click && item.click}
								>
									<span>{item.value(purchase) ? item.value(purchase) : 'Нет данных'}</span>
								</div>
							</div>
						))}
					</div>
				</CardFull>


			</Collapse>
		</>
	)
}

export default PurchasesCollapse
