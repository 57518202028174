import { takeEvery, call, put, all } from '@redux-saga/core/effects'
import { commonProjectsApi, fetchData } from '@root-gipro/store/api'
import { actionFavoritesProjectsApi, getFavoritesApi } from '../api/favorites'
import { FavoritesType } from '../models/interfaces/favorites.actions'
import {
	setFavoritesProjects,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
	setLoadingFavorites,
} from './actions'
import store from '@root-gipro/store'
import produce from 'immer'
import { ProjectType } from '../models/enums/projects.enum'
import { generateWithArray } from '../utils/generateWithArray'
import { ReactText } from 'styled-jsx/node_modules/@types/react'
import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { setFavoritesProjectsSum, setFavoritesProjectsCount } from '@root-gipro/modules/favorites/store/actions'
import { setFilterResultCount } from '@root-gipro/modules/filter/store/actions'
import { favoritesPurchasesApi } from '@root-gipro/modules/favorites/api/favorites'
import { Plans } from '@root-gipro/store/interfaces'
import { functionsIn } from 'lodash'
import { setTimeout } from 'timers'

function* getFavoritesProjects({
	toggleType,
}: {
	type: typeof FavoritesType.GET_FAVORITES_PROJECTS
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES
}) {
	yield put(setLoadingFavorites(true))

	const params = {
		favoritesOnly: 1,
		with: generateWithArray(toggleType),
	}
	try {
		const url = toggleType === ProjectType.PROJECTS ? '/project-general-search' : '/purchase-search'
		const result = yield call(getFavoritesApi, url, params)
		if (result.status === 'success') {
			const urlToResultCount = toggleType === ProjectType.PROJECTS ? 'favorites-common-projects' : 'favorites-purchases'
			const key = toggleType === ProjectType.PROJECTS ? 'projects' : 'purchases'
			const sum =
				toggleType === ProjectType.PROJECTS
					? +result.valuationPlanForecastSum / 10 ** 6
					: +result.maximumPriceSum / 10 ** 6

			yield put(setFavoritesProjects(result[key], result.resultsCnt, sum))
			yield put(setFilterResultCount(result.resultsCnt, urlToResultCount))
			yield put(setLoadingFavorites(false))
		}
	} catch (error) {
		console.log(error)
	}
}

function* setFavoritesPurchasesOnProjectCard({
	toggleType,
}: {
	type: typeof FavoritesType.GET_FAVORITES_PROJECTS_SET_PURCHASES
	toggleType: ProjectType.PURCHASES
}) {
	yield put(setLoadingFavorites(true))

	const params = {
		favoritesOnly: 1,
		with: generateWithArray(toggleType),
	}
	try {
		const url = '/purchase-search'
		const result = yield call(getFavoritesApi, url, params)

		if (result.status === 'success') {
			const key = 'purchases'
			const sum = +result.maximumPriceSum / 10 ** 6

			// yield put(setFavoritesProjects(result[key], result.resultsCnt, sum))
			yield put(setLoadingFavorites(false))
		}
	} catch (error) {
		console.log(error)
	}
}

function* deleteFavoritesProjects({
	projectId,
	project,
}: {
	type: typeof FavoritesType.DELETE_FAVORITES_PROJECTS
	projectId: string | string[]
	project: ProjectType.PROJECTS | ProjectType.PURCHASES
}) {
	yield put(setLoadingFavorites(true))

	const urlSearch = project === ProjectType.PROJECTS ? '/project-general-search' : '/purchase-search'
	const urlDelete = project === ProjectType.PROJECTS ? 'user-favorite-projects' : 'user-favorite-purchases'
	const key = project === ProjectType.PROJECTS ? 'projectId' : 'purchaseId'
	const params = {
		favoritesOnly: 1,
		with: generateWithArray(project),
	}

	const data = {
		[key]: Array.isArray(projectId) ? projectId.join() : projectId,
	}
	try {
		const remove = yield call(actionFavoritesProjectsApi, urlDelete, data, 'DELETE')

		if (remove.status === 'success') {
			const result = yield call(getFavoritesApi, urlSearch, params)

			const key = project === ProjectType.PROJECTS ? 'projects' : 'purchases'
			const sum =
				project === ProjectType.PROJECTS
					? +result.valuationPlanForecastSum / 10 ** 6
					: +result.maximumPriceSum / 10 ** 6

			yield put(setFavoritesProjects(result[key], result.resultsCnt, sum))
			yield put(setLoadingFavorites(false))
		}
	} catch (error) {
		console.log(error)
	}
}
function* getCountPurchases({ id }: { type: typeof FavoritesType.GET_COUNT_PURCHASES; id: ReactText }) {
	try {
		// const url = `projects/8871`
		// const params = { width: ['plans', 'notices', 'countPurchases'] }
		// const result = yield call(getFavoritesApi, url, params)
		// console.log(result)
	} catch (error) {}
}

function* sortFavoritesProjects({
	name,
	sortName,
	order,
}: {
	name: string
	sortName: string
	order?: boolean
	type: typeof FavoritesType.SORT_FAVORITES_PROJECTS
}) {
	yield put(setLoadingFavorites(true))
	const toggleType = store.getState().favorites.toggleType

	let params = {
		favoritesOnly: 1,
		with: generateWithArray(toggleType),
		order: order ? `-${sortName}` : sortName,
	}
	// if (params.order === 'newPurchases') {
	// 	console.log('sorting')
	// }
	try {
		const url = toggleType === ProjectType.PROJECTS ? '/project-general-search' : '/purchase-search'
		const result = yield call(getFavoritesApi, url, params)

		if (result?.status === 'success') {
			const key = toggleType === ProjectType.PROJECTS ? 'projects' : 'purchases'
			const count: number = Number(result.resultsCnt)
			const valuationOfferForecastSum: number = Number(result.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(result.valuationPlanForecastSum) / 10 ** 6
			const sum =
				toggleType === ProjectType.PROJECTS
					? +result.valuationPlanForecastSum / 10 ** 6
					: +result.maximumPriceSum / 10 ** 6
			if (toggleType === ProjectType.PROJECTS ? 'projects' : 'purchases') {
				yield put(setFavoritesProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(setFavoritesProjectsCount(count))
			}
			yield put(setLoadingFavorites(false))

			if (params.order === 'newPurchases') {
				//@ts-ignore
				const newArr = result[key].sort(async (project1, project2) => {
					let project1PurchasesMax: number = 0
					let project2PurchasesMax: number = 0
					fetchData(`/projects/${project1.id}/linkedPurchases`, (res: unknown) => res)
						//@ts-ignore
						.then(purcheses => {
							if (purcheses?.plans) {
								//@ts-ignore
								purcheses.plans.forEach(async item => {
									if (Number(new Date(item.publicationDate)) > project1PurchasesMax) {
										project1PurchasesMax = await item.publicationDate
									}
								})
							}
						})
						.catch(error => console.warn(error.message))

					fetchData(`/projects/${project2.id}/linkedPurchases`, (res: unknown) => res)
						//@ts-ignore
						.then(purcheses => {
							if (purcheses?.plans) {
								//@ts-ignore
								purcheses.plans.forEach(async item => {
									if (new Date(item.publicationDate) > new Date(project2PurchasesMax)) {
										project2PurchasesMax = await item.publicationDate
									}
								})
							}
						})
						.catch(error => console.warn(error.message))
					await setTimeout(() => {
						return project2PurchasesMax - project1PurchasesMax
					}, 1000)
				})
				yield put(setFavoritesProjects(newArr, result.resultsCnt, sum))
			} else {
				yield put(setFavoritesProjects(result[key], result.resultsCnt, sum))
			}
		}
	} catch (error) {
		console.log(error)
	}
}
function* sortFavoritesPurchases({
	name,
	sortName,
	order,
}: {
	name: string
	sortName: string
	order?: boolean
	type: typeof FavoritesType.SORT_FAVORITES_PROJECTS
}) {
	yield put(setLoadingFavorites(true))
	const toggleType = store.getState().favorites.toggleType
	const params = {
		favoritesOnly: 1,

		with: generateWithArray(toggleType),
		order: order ? `-${sortName}` : sortName,
	}

	try {
		const url = toggleType === ProjectType.PROJECTS ? '/project-general-search' : '/purchase-search'

		const result = yield call(getFavoritesApi, url, params)
		if (result.status === 'success') {
			const key = toggleType === ProjectType.PROJECTS ? 'projects' : 'purchases'
			const count: number = Number(result.resultsCnt)
			const maximumPriceSum = Number(result.maximumPriceSum) / 10 ** 6
			const sum =
				toggleType === ProjectType.PROJECTS
					? +result.valuationPlanForecastSum / 10 ** 6
					: +result.maximumPriceSum / 10 ** 6
			yield put(setFavoritesProjects(result[key], result.resultsCnt, sum))
			if (toggleType === ProjectType.PROJECTS ? 'projects' : 'purchases') {
				yield put(setFavoritesPurchasesSum(maximumPriceSum))
				yield put(setFavoritesPurchasesCount(count))
			}
			yield put(setLoadingFavorites(false))
		}
	} catch (error) {
		console.log(error)
	}
}

export default function* watchFavorites() {
	yield takeEvery(FavoritesType.GET_COUNT_PURCHASES, getCountPurchases)
	yield takeEvery(FavoritesType.SORT_FAVORITES_PROJECTS, sortFavoritesProjects)
	yield takeEvery(FavoritesType.SORT_FAVORITES_PURCHASES, sortFavoritesPurchases)
	yield takeEvery(FavoritesType.GET_FAVORITES_PROJECTS, getFavoritesProjects)
	yield takeEvery(FavoritesType.GET_FAVORITES_PROJECTS_SET_PURCHASES, setFavoritesPurchasesOnProjectCard)
	yield takeEvery(FavoritesType.DELETE_FAVORITES_PROJECTS, deleteFavoritesProjects)
}
