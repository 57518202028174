import React, { FC } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useInput } from '@root-gipro/shared/hooks/useInput'
import { Input } from '@root-gipro/shared/FormElements'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import { useDispatch } from 'react-redux'
import { userInfoAction } from '@root-gipro/modules/commonProjects/store/actions'
import styles from '@root-gipro/modules/commonProjects/styles/Reports.module.scss'
import cn from 'classnames'
import { IUserInfoLog } from '@root-gipro/modules/commonProjects/models/interfaces/reports.models'

const schema = yup.object().shape({
	email: yup
		.string()
		.matches(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+/, 'Некорректный  Email')
		.required('Поле должно быть заполненно'),
})

export const UserInfoFormReports: FC = () => {
	const { value, onChange } = useInput()
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()

	const onSubmit = (data: IUserInfoLog) => {
		dispatch(userInfoAction(data.email))
	}
	return (
		<form noValidate onSubmit={handleSubmit(onSubmit)}>
			<div>
				<Input
					labelName={'Email'}
					inputName={'email'}
					inputValue={value && value['email']}
					handleInput={onChange}
					classes='full-width'
					inputRef={register}
					errors={!!errors['email']}
					helperText={errors && errors['email'] && errors['email'].message}
				/>
			</div>
			<div className={styles.mt}>
				<PrimaryBtn type='submit'>Показать</PrimaryBtn>
			</div>
		</form>
	)
}
