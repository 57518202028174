import { store } from '@root-gipro/store'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { Color } from '@material-ui/lab/Alert'

const handleAccessError = (message: string) => {
	store.dispatch(
		showNotify({
			type: 'error',
			message,
		})
	)
}

export { handleAccessError }
