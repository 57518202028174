import React from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import FilterContainer from '@root-gipro/modules/investmentPrograms/components/partials/filter/FilterContainer'
import FilterSelect from '@root-gipro/modules/investmentPrograms/components/partials/filter/FilterSelect'
import FilterInputRange from '@root-gipro/modules/investmentPrograms/components/partials/filter/FilterInputRange'
import { IState, IGroup } from '@root-gipro/store/interfaces'
import { IFilterProps } from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-program'
import { setFilterValue, resetFilter } from '@root-gipro/modules/investmentPrograms/store/actions'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'

const Filter: React.FC<IFilterProps> = ({ filter, titles, periods, approvedYears, industries }) => {
	const dispatch = useDispatch()

	const shortTitleReset = () => {
		dispatch(setFilterValue('', 'shortTitle'))
	}
	const industryReset = () => {
		dispatch(setFilterValue('', 'industry'))
	}
	const publishedAtReset = () => {
		dispatch(setFilterValue('', 'publishedAt'))
	}
	const sinceReset = () => {
		dispatch(setFilterValue('', 'since'))
	}
	const periodReset = () => {
		dispatch(setFilterValue('', 'startedAt'))
		dispatch(setFilterValue('', 'finishedAt'))
	}
	const costReset = () => {
		dispatch(setFilterValue('', 'minCost'))
		dispatch(setFilterValue('', 'maxCost'))
	}

	const resetFilterValues = () => {
		dispatch(resetFilter())
	}

	var groups: IGroup[] = []
	titles?.map(title => {
		groups.push({
			id: '',
			name: title.text,
		})
	})

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if (fieldName == 'shortTitle' && typeof value === 'string') {
			dispatch(setFilterValue(value, 'shortTitle'))
		}
	}

	return (
		<aside className='programs__filter'>
			<div className='programs__filter-header'>
				<h3 className='programs__filter-title'>Фильтр</h3>

				<button className='programs__filter-reset' type='button' onClick={resetFilterValues}>
					Сбросить
				</button>
			</div>

			<div className='programs__filter-body'>
				<FilterContainer title='Организация' subtitle='Наименование' onReset={shortTitleReset}>
					<FilterItem
						filterState={filter.shortTitle}
						mode='select'
						fieldName='shortTitle'
						setValue={refreshFilterValue}
						items={groups?.map((group: IGroup) => group.name) || []}
					/>
				</FilterContainer>

				{/* It is Base version
                <FilterContainer
                    title="Организация"
                    subtitle="Наименование"
                    onReset={shortTitleReset}
                >
                    <FilterSelect
                        defaultValue={filter.shortTitle}
                        items={titles}
                        onChange={(value: string) => dispatch(setFilterValue(value, 'shortTitle'))}
                    />
                </FilterContainer>
                */}

				<FilterContainer title='Отрасль' subtitle='Сфера деятельности' onReset={industryReset}>
					<FilterSelect
						defaultValue={filter.industry}
						items={industries}
						onChange={(value: string) => dispatch(setFilterValue(value, 'industry'))}
					/>
				</FilterContainer>
				<FilterContainer title='Период' subtitle='Публикация в открытых источниках' onReset={publishedAtReset}>
					<FilterSelect
						defaultValue={filter.publishedAt}
						items={periods}
						onChange={(value: string) => dispatch(setFilterValue(value, 'publishedAt'))}
					/>
				</FilterContainer>
				<FilterContainer title='Год утверждения инвестиционной программы' onReset={sinceReset}>
					<FilterSelect
						defaultValue={filter.since}
						items={approvedYears}
						onChange={(value: string) => dispatch(setFilterValue(value, 'since'))}
					/>
				</FilterContainer>
				<FilterContainer title='Период реализации' onReset={periodReset}>
					<FilterInputRange
						onMinValueChange={(value: string) => dispatch(setFilterValue(value, 'startedAt'))}
						onMaxValueChange={(value: string) => dispatch(setFilterValue(value, 'finishedAt'))}
						defaultMinValue={filter.startedAt}
						defaultMaxValue={filter.finishedAt}
					/>
				</FilterContainer>
				<FilterContainer title='Оценка полной стоимости, млн руб. с НДС' onReset={costReset}>
					<FilterInputRange
						onMinValueChange={(value: string) => dispatch(setFilterValue(value, 'minCost'))}
						onMaxValueChange={(value: string) => dispatch(setFilterValue(value, 'maxCost'))}
						defaultMinValue={filter.minCost}
						defaultMaxValue={filter.maxCost}
					/>
				</FilterContainer>
			</div>
			<div className='aside-filter-copyright copyright-ipr'>
				Copyright 2022 ©<br />
				ООО «Мастерская цифровых решений»
			</div>
		</aside>
	)
}

const mapStateToProps = (state: IState) => ({
	filter: state.investmentPrograms.filter,
	titles: state.investmentPrograms.titles.map(title => ({
		text: title,
		value: title,
	})),
	periods: state.investmentPrograms.publicationDates.map(date => ({
		text: moment(date).format('D.MM.Y'),
		value: moment(date).format('D.MM.Y'),
	})),
	approvedYears: state.investmentPrograms.since.map(date => ({
		text: date,
		value: date,
	})),
	industries: state.investmentPrograms.industries.map(industry => ({
		text: industry,
		value: industry,
	})),
})

export default React.memo(connect(mapStateToProps)(Filter))
