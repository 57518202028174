import React, { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { IUserProjectItemProps } from '@root-gipro/modules/userProjects/models/interfaces/user-projects-item.props'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { CardFull, CardLeft, CardRight, CardItem } from '@root-gipro/shared/Card'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import uniqueId from 'lodash/uniqueId'
import '@root-gipro/modules/commonProjects/styles/Parameters.scss'

const Item: React.FC<IUserProjectItemProps> = ({ data }) => {
	const history = useHistory()

	const cardRightItems = [
		{
			title: 'Объем финансовых потребностей по УНЦ в прогнозных ценах (с НДС)',

			value: data.totalInvestmentUncOffer ? addHTML(prettyCost(data.totalInvestmentUncOffer / 10 ** 6)) : '0.00',
		},
		{
			title: 'Итого ОФП по УНЦ в текущих ценах (с НДС)',
			value: data.totalInvestmentUncOffer ? addHTML(prettyCost(data.totalInvestmentUncOffer / 10 ** 6)) : '0.00',
		},
		{
			title: 'Сумма ненормируемых затрат',
			value: data.additionalFundingOffer ? addHTML(prettyCost(data.additionalFundingOffer / 10 ** 6)) : '0.00',
		},
	]
	const getProjectById = useCallback(
		(id: string | undefined) => {
			history.push({
				pathname: `/user-projects#${id}`,
			})
		},
		[history]
	)
	return (
		<CardFull key={data.id} id={data.id} classes='detail-purchases mb-16'>
			<div className='card-full__item '>
				<div className='card-full-wr'>
					<CardLeft>
						<div className='card-full__header'>
							<Link
								to={`/user-projects/project/${data.id}/parameters`}
								onClick={() => getProjectById(data.id)}
								className='card-container__code link-blue'
							>
								<span className='user-projects-icon' />
								<span className='code'>{data.code}</span>
							</Link>
						</div>
						<CardDescription>{data.name}</CardDescription>
						{data.offerRationale && data.offerRationale.length > 0 && (
							<div className={`card-container__comment`}>
								<div className='card-container__comment-shrt-txt'>Комментарии</div>
								<div
									className={`card-container__comment-value ${
										data.offerRationale && data.offerRationale.length > 0 && 'show-border'
									}`}
								>
									{data.offerRationale}
								</div>
							</div>
						)}
					</CardLeft>
					<CardRight>
						{cardRightItems.map(
							item => item.value && <CardItem key={uniqueId('CardItem')} title={item.title} value={item.value} />
						)}
					</CardRight>
				</div>
			</div>
		</CardFull>
	)
}

export { Item }
