import React, { MutableRefObject, FC } from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import './Voltage.scss'

interface VoltageProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	targetVoltageValues: string[]
	uncVoltage: string | undefined
	handler: (voltage: number) => void
	voltageRef: MutableRefObject<undefined | string>
}

const Voltage: FC<VoltageProps> = ({ voltageRef, handler, uncVoltage, targetVoltageValues, anchorEl, setAnchorEl }) => {
	return (
		<DropDown
			anchorEl={anchorEl!}
			setAnchorEl={setAnchorEl!}
			style={{ borderRadius: 9, width: 50 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div className='voltage_select_list'>
				{targetVoltageValues &&
					Object.values(targetVoltageValues).map(voltage => {
						return (
							<div
								key={voltage}
								onClick={() => {
									handler(+voltage)
									voltageRef.current = voltage
								}}
								className={
									+voltage === +uncVoltage!
										? 'voltage_select_item-active voltage_select_item'
										: `voltage_select_item ${voltage}`
								}
							>
								{voltage}
							</div>
						)
					})}
			</div>
		</DropDown>
	)
}
export default Voltage
