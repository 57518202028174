import { ISortItem } from '@root-gipro/store/interfaces'

const sortList: ISortItem[] = [
	{
		name: 'Идентификатор',
		sortName: 'code',
	},
	{
		name: 'Наименование проекта',
		sortName: 'name',
	},
	{
		name: 'Организация',
		sortName: 'group.name',
	},
	// {
	// 	name: 'Наименование Филиала ДЗО',
	// 	sortName: 'depGroupName',
	// },
	{
		name: 'Дата публикации на сайте Минэнерго',
		sortName: 'publicationDate',
	},
	{
		name: 'Год начала реализации',
		sortName: 'yearBegin',
	},
	{
		name: 'Год окончания реализации',
		sortName: 'yearEndOffer',
	},
	{
		name: 'Полная сметная стоимость согласно ПСД',
		sortName: 'estimatedOfferCurrent',
	},
	{
		name: 'Оценка полной стоимости по УНЦ',
		sortName: 'valuationUncOfferForecast',
	},
	{
		name: 'Оценка полной стоимости',
		sortName: 'valuationOfferForecast',
	},
	{
		name: 'Остаток финансирования',
		sortName: 'balancePlanOnDisclosure',
	},

	// {
	// 	name: 'Сначала с позициями Плана закупок',
	// 	sortName: 'name',
	// },
	// {
	// 	name: 'Сначала без позиций Плана закупок',
	// 	sortName: 'name',
	// },
	// {
	// 	name: 'Сначала с Закупками на Госзакупках',
	// 	sortName: 'name',
	// },
	// {
	// 	name: 'Сначала без Закупок на Госзакупках',
	// 	sortName: 'name',
	// },
]

export default sortList
