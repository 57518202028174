import React from 'react'
import { formatDate, formateDateTime } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'

type CardLeftType = {
	title: string
	value: (item: IPurchase) => string | null | JSX.Element
	click?: () => void
}


export const CardNoticeContentItem: CardLeftType[] = [
	{
		title: 'Извещение No',
		value: (item: IPurchase): any => item.registrationNumber,
	},
	{
		title: 'Дата размещения тек. редакции извещения',
		value: (item: IPurchase) => formatDate(item.notice_publicationDate),
	},
	{
		title: 'Дата окончания подачи заявок',
		value: (item: IPurchase) => formatDate(item.notice_submissionCloseDate),
	},
	{
		title: 'Срок исполнения договора',
		value: (item: IPurchase) => formatDate(item.notice_summingUpDate),
	},
	{
		title: 'Дополнительная информация',
		value: (item: IPurchase) => item.notice_additionalInfo,
	},
]

export const CardProtocolContentItem: CardLeftType[] = [
	{
		title: 'Протокол No',
		value: (item: IPurchase): any => item.protocol_registrationNumber,
	},
	{
		title: 'Дата создания протокола',
		value: (item: IPurchase): any => formateDateTime(item.protocol_createDate),
	},
	{
		title: 'Дата публикации протокола',
		value: (item: IPurchase): any => formateDateTime(item.protocol_publicationDate),
	},
	{
		title: 'Победитель',
		value: (item: IPurchase): any => item.protocol_winner,
	},
	{
		title: 'Контракт подписан',
		value: (item: IPurchase): string => (Boolean(item.protocol_contractSigned) ? 'Да' : 'Нет'),
	},
	{
		title: 'Дополнительная информация',
		value: (item: IPurchase) => item.protocol_additionalInfo,
	},
]
