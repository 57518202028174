import React, { useEffect, useState } from 'react'
import { Filter as FilterWrapper, FilterItemGroup } from '@root-gipro/shared/Filter'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'
import { capitalizeFirstLetter, formatDate } from '@root-gipro/utils/helpers/common'
import { isEqual } from 'lodash'
import { FilterProps } from '@root-gipro/modules/filter/models/props/filter.model'
import { useLocation } from 'react-router-dom'
import { IDepGroup, IGroup, IRegion } from '@root-gipro/store/interfaces'

const Filter: React.FC<FilterProps> = ({
	resetFilter,
	refreshFilterState,
	showFilterResult,
	filterCountResult,
	filterState,
	purchasesType,
	uncInfo,
	purchasesMethods,
	purchasesStages,
	regions,
	depGroups,
	groups,
	rgStatuses,
	publicationDates,
	uncThs,
	uncThDescriptions,
	uncMains,
	policesRGCO,
	uncTechnicalSpecifications,
}) => {
	const uncMainRows = (): string[] => {
		const arrNumbers = uncMains && uncMains.map(elem => elem.rowsCnt)
		const maxNumber = Math.max(...(arrNumbers ?? []))
		return Array.from({ length: maxNumber }, (_, i) => (i + 1).toString())
	}

	const [currentFilter, setCurrentFilter] = useState<boolean>(false)

	let location = useLocation()
	const url: string = location.pathname.slice(1)

	useEffect(() => {
		setCurrentFilter(url === 'purchases' ? true : false)
	}, [url])
	const uncMainColumns =
		uncMains
			?.map(elem => elem.colsCnt)
			.filter(i => i !== 0)
			.sort((a, b) => a - b)
			.map(i => i.toString()) || []

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if ((value !== undefined || value !== '') && !isEqual(value, (filterState as Record<string, string>)[fieldName])) {
			if (fieldName === 'uncTechnicalSpecification.name') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
				refreshFilterState!('unc.count.max', '', url)
				refreshFilterState!('unc.count.min', '', url)
			}
			if (fieldName === 'uncTechnicalSpecification.paramName') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('unc.count.max', '', url)
				refreshFilterState!('unc.count.min', '', url)
			}
			if (fieldName === 'uncTechnicalSpecification.param') {
				refreshFilterState!('unc.count.max', '', url)
				refreshFilterState!('unc.count.min', '', url)
			}
			if (filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name']?.length > 1) {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
				refreshFilterState!('unc.count.max', '', url)
				refreshFilterState!('unc.count.min', '', url)
			}
			refreshFilterState!(fieldName, value, url)
		}
		// console.log(filterState)
	}
	return (
		<FilterWrapper resultCnt={filterCountResult} resetFilterHandler={resetFilter} showFilterResult={showFilterResult}>
			<FilterItemGroup tagsFilter={['common-projects']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только имеющие расценки по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsUNC'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases']} name='Закупка'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={'registrationNumber'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={`${purchasesType}.subject`}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Закупка в электронной форме'
					filterState={filterState}
					mode='checkbox'
					fieldName='isElectronic'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases']} name='Параметры закупки'>
				<FilterItem
					isMultiple
					placeholder='Способ определения поставщика (подрядчика, исполнителя)'
					filterState={filterState}
					mode='select'
					fieldName={`${purchasesType}.method`}
					setValue={refreshFilterValue}
					items={
						purchasesMethods && purchasesMethods.length > 0
							? purchasesMethods.filter(method => method).map(method => capitalizeFirstLetter(method))
							: []
					}
				/>
				<FilterItem
					isMultiple
					placeholder='Тип закупки'
					filterState={filterState}
					mode='select'
					fieldName={`plan_typeObjectPurchase`}
					setValue={refreshFilterValue}
					// items={['G', 'W', 'S']}
					items={['Товар','Работа','Услуга']}
				/>
				<FilterItem
					isMultiple
					placeholder='Статус закупки'
					filterState={filterState}
					mode='select'
					fieldName={`${purchasesType}.stage`}
					setValue={refreshFilterValue}
					items={
						purchasesStages && purchasesStages.length > 0
							? purchasesStages.filter(stage => stage).map(stage => capitalizeFirstLetter(stage))
							: []
					}
				/>
				{/* <FilterItem
					name='Начальная цена договора'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={`${purchasesType === 'plan' ? 'plan.maximumPrice.min' : 'notice.initialSum.min'}`}
					fieldNameEnd={`${purchasesType === 'plan' ? 'plan.maximumPrice.max' : 'notice.initialSum.max'}`}
					setValue={refreshFilterValue}
				/> */}
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['purchases', 'common-projects']} name='Организация'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? `${purchasesType}.groupId` : 'groupId'}
					setValue={refreshFilterValue}
					items={groups?.map((group: IGroup) => group.name) || []}
				/>
				{/* <FilterItem
					isMultiple
					placeholder='Филиал'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? `${purchasesType}.depGroupId` : 'depGroupId'}
					setValue={refreshFilterValue}
					items={depGroups?.map((group: IDepGroup) => group.name) || []}
				/> */}
				<FilterItem
					isMultiple
					placeholder='Регион'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? `${purchasesType}.regionId` : 'regionId'}
					setValue={refreshFilterValue}
					items={regions?.map((region: IRegion) => region.name) || []}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases']} name='Период'>
				<FilterItem
					isMultiple
					placeholder='Дата утверждения закупки на площадке'
					filterState={filterState}
					mode='select'
					fieldName='project.publicationDate'
					setValue={refreshFilterValue}
					items={publicationDates.map((date: string) => formatDate(date, true)!) || []}
				/>
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='project.yearBegin.min'
					fieldNameEnd='project.yearEndOffer.max'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
				<FilterItem
					name='Планируемая дата размещения извещения'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='plan.plannedDate.min'
					fieldNameEnd='plan.plannedDate.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
				{/* <FilterItem
					name='Срок исполнения договора'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart={`${purchasesType === 'plan' ? 'plan.endDate.min' : 'notice.summingUpDate.min'}`}
					fieldNameEnd={`${purchasesType === 'plan' ? 'plan.endDate.max' : 'notice.summingUpDate.max'}`}
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/> */}
				<FilterItem
					name='Дата размещения тек. редакции извещения'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='notice.publicationDate.min'
					fieldNameEnd='notice.publicationDate.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
				<FilterItem
					name='Дата окончания подачи заявок'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='notice.submissionCloseDate.min'
					fieldNameEnd='notice.submissionCloseDate.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Период'>
				<FilterItem
					isMultiple
					placeholder='Публикация в открытых источниках'
					filterState={filterState}
					mode='select'
					fieldName='publicationDate'
					setValue={refreshFilterValue}
					items={publicationDates.map((date: string) => formatDate(date, !currentFilter)!) || []}
				/>
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='yearBegin.min'
					fieldNameEnd='yearEndOffer.max'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases']} name='Начальная максимальная цена, млн руб. с НДС'>
				<FilterItem
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart='plan.maximumPrice.min'
					fieldNameEnd='plan.maximumPrice.max'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Оценка полной стоимости, млн руб. с НДС'>
				<FilterItem
					name='По УНЦ'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationUncOfferForecast.min' : 'valuationUncOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationUncOfferForecast.max' : 'valuationUncOfferForecast.max'}
					setValue={refreshFilterValue}
					isDisable={
						currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast'])
							: Boolean(filterState['valuationUncOfferForecast'])
					}
					withCheckbox={{
						checked: currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast']) || false
							: Boolean(filterState['valuationUncOfferForecast']) || false,
						setChecked: (value: boolean) =>
							currentFilter
								? refreshFilterValue('project.valuationUncOfferForecast', value)
								: refreshFilterValue('valuationUncOfferForecast', value),
						label: 'Без расчёта по УНЦ',
						checkboxChange: () =>
							currentFilter
								? refreshFilterValue(
										'project.valuationUncOfferForecast',
										filterState['project.valuationUncOfferForecast']
								  )
								: refreshFilterValue('valuationUncOfferForecast', filterState['valuationUncOfferForecast']),
					}}
				/>
				<br />
				<br />
				<FilterItem
					name='В прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationOfferForecast.min' : 'valuationOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationOfferForecast.max' : 'valuationOfferForecast.max'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Остаток финансирования в прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.balanceOffer.min' : 'balanceOffer.min'}
					fieldNameEnd={currentFilter ? 'project.balanceOffer.max' : 'balanceOffer.max'}
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Полная сметная стоимость согласно ПСД (с НДС)'>

				{!filterState['estimatedOfferYM'] && (
				<FilterItem
					name='Стоимость'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.estimatedOfferCurrent.min' : 'estimatedOfferCurrent.min'}
					fieldNameEnd={currentFilter ? 'project.estimatedOfferCurrent.max' : 'estimatedOfferCurrent.max'}
					setValue={refreshFilterValue}
					isDisable={
						currentFilter ? Boolean(filterState['project.estimatedOfferYM']) : Boolean(filterState['estimatedOfferYM'])
					}
				/>
				)}

				{!filterState['estimatedOfferYM'] && (
				<FilterItem
					name='Дата составления'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
					fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
					setValue={refreshFilterValue}
					dateOpenTo='year'
					dateViews={['year', 'month']}
					//isDisable={true}

					// withCheckbox={{
					// 	checked: currentFilter
					// 		? Boolean(filterState['project.estimatedOfferYM']) || false
					// 		: Boolean(filterState['estimatedOfferYM']) || false,
					// 	setChecked: (value: boolean) =>
					// 		currentFilter
					// 			? refreshFilterValue('project.estimatedOfferYM', value)
					// 			: refreshFilterValue('estimatedOfferYM', value),
					// 	label: 'Без ПСД',
					// 	checkboxChange: () =>
					// 		currentFilter
					// 			? refreshFilterValue('project.estimatedOfferYM', filterState['project.estimatedOfferYM'])
					// 			: refreshFilterValue('estimatedOfferYM', filterState['estimatedOfferYM'])
					// }}
				/>
				)}

				<FilterItem
					name='Без ПСД'
					filterState={filterState}
					mode='checkbox'
					fieldName='estimatedOfferYM'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['common-projects']} name='Технические решения по УНЦ'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.uncThId' : 'uncCell.uncThId'}
					setValue={refreshFilterValue}
					items={uncThs.map(unc => unc.name).filter(name => name) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Параметры'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.uncTextParameterId' : 'uncCell.uncTextParameterId'}
					setValue={refreshFilterValue}
					items={uncThDescriptions.filter(elem => elem.title).map(param => param.title) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Напряжение, кВ'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.voltageValue' : 'uncCell.voltageValue'}
					setValue={refreshFilterValue}
					items={uncInfo?.voltageValues.filter((elem: string) => elem) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Таблица'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.uncMainId' : 'uncCell.uncMainId'}
					setValue={refreshFilterValue}
					items={uncMains?.map(elem => elem.code) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Строка'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.number' : 'uncCell.number'}
					setValue={refreshFilterValue}
					items={uncMainRows() || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Столбец'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'project.uncCell.subNumber' : 'uncCell.subNumber'}
					setValue={refreshFilterValue}
					items={uncMainColumns}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					name='Количество'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.unc.count.min' : 'unc.count.min'}
					fieldNameEnd={currentFilter ? 'project.unc.count.max' : 'unc.count.max'}
					setValue={refreshFilterValue}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				{policesRGCO && (
					<FilterItem
						isMultiple
						placeholder='Запрос ИД'
						filterState={filterState}
						mode='select'
						fieldName={currentFilter ? 'project.rgStatusId' : 'rgStatusId'}
						setValue={refreshFilterValue}
						items={rgStatuses.map(elem => elem.title)}
						isDisable={Boolean(filterState['project.noUncs']) || Boolean(filterState['noUncs'])}
					/>
				)}
				{/* <FilterItem
					name='Без тех. решения'
					filterState={filterState}
					mode='checkbox'
					fieldName={currentFilter ? 'project.noUncs' : 'noUncs'}
					setValue={refreshFilterValue}
				/> */}
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Строительные ресурсы'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='select'
					fieldName={'uncTechnicalSpecification.name'}
					setValue={refreshFilterValue}
					items={uncTechnicalSpecifications?.name ? Object.values(uncTechnicalSpecifications?.name) : []}
				/>
				{filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name'].length < 2 && (
					<FilterItem
						isMultiple
						placeholder='Параметры'
						filterState={filterState}
						mode='select'
						fieldName={'uncTechnicalSpecification.paramName'}
						setValue={refreshFilterValue}
						items={uncTechnicalSpecifications?.paramName ? Object.values(uncTechnicalSpecifications?.paramName) : []}
					/>
				)}
				{filterState['uncTechnicalSpecification.paramName'] && filterState['uncTechnicalSpecification.name'] && (
					<FilterItem
						isMultiple
						placeholder='Значение'
						filterState={filterState}
						mode='select'
						fieldName={'uncTechnicalSpecification.param'}
						setValue={refreshFilterValue}
						items={uncTechnicalSpecifications?.param ? Object.values(uncTechnicalSpecifications?.param) : []}
					/>
				)}
				{filterState['uncTechnicalSpecification.paramName'] &&
					filterState['uncTechnicalSpecification.name'] &&
					filterState['uncTechnicalSpecification.param'] && (
						<FilterItem
							name='Количество'
							filterState={filterState}
							mode='range-numbers'
							fieldNameStart='unc.count.min'
							fieldNameEnd='unc.count.max'
							setValue={refreshFilterValue}
						/>
					)}
			</FilterItemGroup>
		</FilterWrapper>
	)
}
export default Filter
